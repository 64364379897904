 
 
import { GISserverTypes, UserRequestTypes, layerTypesEnum, ArcGIS_ServiceType } from '../GeoEnums';
 import { GeometryProperties, AdvancedQueryCapabilities, Index, SourceSpatialReference, DrawingInfo, GeometryField, OwnershipBasedAccessControlForFeatures, LayerFields, Extent, Field } from '../interface/arcgis.data.mode.interfacel';
import { SearchResultLayer } from '../shared/Model/GeoData.interface';
 
export class ArcGis_featureLayer {
    currentVersion?: number;
  
    name: string;
    type: string;
    fields: Field[];
    displayField?: string;
    description?: string;
    copyrightText?: string;
    defaultVisibility?: boolean;
  
    isDataVersioned?: boolean;
    supportsAppend?: boolean; 
    capabilities?: string;
    hasGeometryProperties?: boolean;
    geometryProperties?: GeometryProperties;
    advancedQueryCapabilities?: AdvancedQueryCapabilities;
    useStandardizedQueries?: boolean;
    geometryType: string;
    minScale?: number;
    maxScale?: number;
    extent: Extent;
    
    allowGeometryUpdates?: boolean;
    hasAttachments?: boolean;
    htmlPopupType?: string;
    hasMetadata?: boolean;
    hasM?: boolean;
    hasZ?: boolean;
    
    objectIdField?: string; 
    indexes?: Index[];
    
    supportedQueryFormats?: string;
    hasStaticData?: boolean;
    maxRecordCount?: number;
    standardMaxRecordCount?: number;
  
    definitionQuery?: string;
 
       
        sourceSpatialReference: SourceSpatialReference;
    
        parentLayer?: any;
        subLayers: any[];
    
        drawingInfo?: DrawingInfo;
      
        typeIdField?: any;
        subtypeFieldName?: any;
        subtypeField?: any;
        defaultSubtypeCode?: any;
        
        geometryField?: GeometryField;
     
        subtypes: any[];
        relationships?: any[];
        canModifyLayer?: boolean;
        canScaleSymbols?: boolean;
        hasLabels?: boolean;
    
        supportsStatistics?: boolean;
        supportsAdvancedQueries?: boolean;
      
        ownershipBasedAccessControlForFeatures?: OwnershipBasedAccessControlForFeatures;
     
        supportsDatumTransformation?: boolean;
        supportsCoordinatesQuantization?: boolean;

}


export class LoadDataOptions {
    public name: string;
    public Token: string;
    public requestType: UserRequestTypes;
    
    public url: string;
    public defaultValue:string;
    public ServerType:GISserverTypes;
    public source: string;
    public file: string;
    public data: string;
    public host: string;
    public port: string;
    public DB: string;
    public username: string;
    public password: string;
    public schema: string;
    public DBtype: string;
    constructor(options :LoadDataParamsOptions) {
        this.name  = options.name ?options.name : "";
      //  this.Token  = options.Token ?options.Token : "";
        this.requestType  = options.requestType ?options.requestType : UserRequestTypes.get_UserContents;
        this.url  = options.url ?options.url : "";
        this.source  = options.source ?options.source : "";
         this.file  = options.file ?options.file : "";
        this.data  = options.data ?options.data : "";
        this.host  = options.host ?options.host : "";
        this.port  = options.port ?options.port : "";
        this.DB  = options.DB ?options.DB : "";
        this.username  = options.username ?options.username : "";
        this.password  = options.password ?options.password : "";
        this.schema  = options.schema ?options.schema : "";
        this.DBtype  = options.DBtype ?options.DBtype : "";
        this.defaultValue  = options.defaultValue ?options.defaultValue : "";
        this.ServerType  = options.ServerType ?options.ServerType : GISserverTypes.NONE
    }

}
export interface LoadDataParamsOptions{
    defaultValue?:string;
      name?: string;
        ServerType:GISserverTypes;
    source?: string; 
    requestType?: UserRequestTypes;
    url?: string;
    file?: string;
    data?: string;
    host?: string;
    port?: string;
    DB?: string;
    username?: string;
    password?: string;
    schema?: string;
    DBtype?: string;
    Token?: string;
}
export class SearchResultHistory {
 
   public name: string;
   public dataconnection: LoadDataOptions;
   public SearchResult: SearchResultLayer[];
}
 