<div class="sidenav-hold">
  <div class="icon-menu mb-1" *ngIf="hasIconTypeMenuItem">
    <!-- Icon menu separator -->
    <div class="mb-1 nav-item-sep">
      <mat-divider [ngStyle]="{margin: '0 -24px'}"></mat-divider>
      <span class="text-muted icon-menu-title">{{iconTypeMenuTitle}}</span>
    </div>
    <!-- Icon menu items -->
    <div class="icon-menu-item" *ngFor="let item of menuItems">
      <button
        *ngIf="!item.disabled && item.type === 'icon'"
        mat-flat-button
        [matTooltip]="item.tooltip"
        routerLink="/{{item.state}}"
        routerLinkActive="mat-bg-primary"
      >
        <mat-icon>{{item.icon}}</mat-icon>
      </button>
    </div>
  </div>

  <ul appDropdown class="sidenav">
    <li *ngFor="let item of menuItems" appDropdownLink routerLinkActive="open">
      <div class="nav-item-sep" *ngIf="item.type === 'separator'">
        <mat-divider></mat-divider>
        <span class="text-muted">{{item.name | translate}}</span>
      </div>
      <div
        *ngIf="!item.disabled && item.type !== 'separator' && item.type !== 'icon'"
        class="lvl1"
      >
        <a
          routerLink="/{{item.state}}"
          appDropdownToggle
          matRipple
          *ngIf="item.type === 'link'"
        >
          <mat-icon>{{item.icon}}</mat-icon>
          <span class="item-name lvl1">{{item.name | translate}}</span>
          <span fxFlex></span>
          <span
            class="menuitem-badge mat-bg-{{ badge.color }}"
            [ngStyle]="{background: badge.color}"
            *ngFor="let badge of item.badges"
            >{{ badge.value }}</span
          >
        </a>
        <a
          [href]="item.state"
          appDropdownToggle
          matRipple
          *ngIf="item.type === 'extLink'"
          target="_blank"
        >
          <mat-icon>{{item.icon}}</mat-icon>
          <span class="item-name lvl1">{{item.name | translate}}</span>
          <span fxFlex></span>
          <span
            class="menuitem-badge mat-bg-{{ badge.color }}"
            [ngStyle]="{background: badge.color}"
            *ngFor="let badge of item.badges"
            >{{ badge.value }}</span
          >
        </a>

        <!-- DropDown -->
        <a *ngIf="item.type === 'dropDown'" appDropdownToggle matRipple>
          <mat-icon>{{item.icon}}</mat-icon>
          <span class="item-name lvl1">{{item.name | translate}}</span>
          <span fxFlex></span>
          <span
            class="menuitem-badge mat-bg-{{ badge.color }}"
            [ngStyle]="{background: badge.color}"
            *ngFor="let badge of item.badges"
            >{{ badge.value }}</span
          >
          <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
        </a>
        <!-- LEVEL 2 -->
        <ul class="submenu lvl2" appDropdown *ngIf="item.type === 'dropDown'">
          <li
            *ngFor="let itemLvL2 of item.sub"
            appDropdownLink
            routerLinkActive="open"
          >
            <a
              routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}"
              appDropdownToggle
              *ngIf="itemLvL2.type !== 'dropDown'"
              matRipple
            >
              <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
              <span fxFlex></span>
            </a>

            <a *ngIf="itemLvL2.type === 'dropDown'" appDropdownToggle matRipple>
              <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
              <span fxFlex></span>
              <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
            </a>

            <!-- LEVEL 3 -->
            <ul
              class="submenu lvl3"
              appDropdown
              *ngIf="itemLvL2.type === 'dropDown'"
            >
              <li
                *ngFor="let itemLvL3 of itemLvL2.sub"
                appDropdownLink
                routerLinkActive="open"
              >
                <a
                  routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                  appDropdownToggle
                  matRipple
                >
                  <span class="item-name lvl3"
                    >{{itemLvL3.name | translate}}</span
                  >
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </li>

    <mat-divider></mat-divider>
    <ul *ngIf="layout.layoutConf.isMobile && jwtAuth.isAuthenticated">

    
    <li appDropdownLink routerLinkActive="open"   *ngIf="  enableChat  ">
      <a      (click)="showChat()"  appDropdownToggle matRipple>
        <mat-icon>chat</mat-icon>
        <span>Chat</span>
      </a>
    </li>

      <li appDropdownLink routerLinkActive="open">
        <a [routerLink]="['/profile/overview']" appDropdownToggle matRipple>
          <mat-icon>account_box</mat-icon>
          <span>Profile</span>
        </a>
      </li>
      <li appDropdownLink routerLinkActive="open">
        <a [routerLink]="['/profile/settings']" appDropdownToggle matRipple>
          <mat-icon>settings</mat-icon>
          <span>Account Settings</span>
        </a>
      </li>

      <li appDropdownLink routerLinkActive="open">
        <a (click)="signout()" appDropdownToggle matRipple>
          <mat-icon>exit_to_app</mat-icon>
          <span>Sign out</span>
        </a>
      </li>
    </ul>
  </ul>

  
   <div *ngIf="layout.layoutConf.isMobile && !jwtAuth.isAuthenticated"
  
style="position: absolute;
bottom: 82px;
display: flex;
background-color: white;
z-index: 1000000;" class="row full-width icon-menu-item"
>
<a [routerLink]="['/sessions/signin']" class="row full-width icon-menu-item" style=" width: 100%; margin: 10px; margin-left: 20px;    display: flex;"  >
  <mat-icon style="color:  #333333!important;">exit_to_app</mat-icon>
  <span style="color:  #333333!important;">Sign in</span>
</a>
</div>
</div>
