<mat-toolbar class="topbar">
  <!-- Sidenav toggle button -->
  <button *ngIf="layoutConf.sidebarStyle !== 'compact'" mat-icon-button id="sidenavToggle" (click)="toggleSidenav()"
    matTooltip="Toggle Hide/Open">
    <mat-icon>menu</mat-icon>
  </button>

  <!-- Search form -->
  <div fxFlex fxHide.lt-sm="true" class="search-bar">
    <form class="top-search-form">
      <mat-icon role="img">search</mat-icon>
      <input autofocus="true" placeholder="Search" type="text" />
    </form>
  </div>

  <span fxFlex></span>
  <!-- Language Switcher -->
  <button mat-button [matMenuTriggerFor]="menu">
    <span class="flag-icon {{currentLang.flag}} mr-05"></span>
    <span>{{currentLang.name}}</span>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let lang of availableLangs" (click)="setLang(lang)">
      <span class="flag-icon mr-05 {{lang.flag}}"></span>
      <span>{{lang.name}}</span>
    </button>
  </mat-menu>



  <!-- Open "views/search-view/result-page.component" to understand how to subscribe to input field value -->

  <!-- Notification toggle button -->
  <button *ngIf="notificationService.notifCount>0" mat-icon-button matTooltip="Notifications" (click)="toggleNotific()"
    [style.overflow]="'visible'" class="topbar-button-right">
    <mat-icon>notifications</mat-icon>
    <span
      class="notification-number mat-bg-warn">{{notificationService?(notificationService.notifCount == 0? '':notificationService.notifCount  ):''}}</span>
  </button>
  <!-- Top left user menu -->
  <button mat-icon-button [matMenuTriggerFor]="accountMenu" class="topbar-button-right img-button">
    <img src="{{apiURL +'/'+  jwtAuth?.user?.PictureUrl}}" alt="" />
  </button>

  <mat-menu #accountMenu="matMenu">
    <button mat-menu-item [routerLink]="['/profile/overview']">
      <mat-icon>account_box</mat-icon>
      <span>Profile</span>
    </button>
    <!-- /// theme switcher  -->
    <button [style.background]="mdaHubThemes.baseColor" mat-icon-button id="schemeToggle" [style.overflow]="'visible'"
      matTooltip="Color Schemes" class="topbar-button-right">
      <mat-icon>format_color_fill</mat-icon>
      <span>Theme</span>

    </button>

    <mat-grid-list class="theme-list" cols="5" rowHeight="25px">
      <mat-grid-tile *ngFor="let theme of mdaHubThemes" (click)="changeTheme(theme)">
        <div mat-menu-item [title]="theme.name" style="width: 25px; height:25px;">
          <div [style.background]="theme.baseColor" class="egret-swatch"></div>
          <mat-icon class="active-icon" *ngIf="theme.isActive">check</mat-icon>
        </div>
      </mat-grid-tile>
    </mat-grid-list>


    <!-- End Switcher -->
    <button mat-menu-item [routerLink]="['/profile/settings']">
      <mat-icon>settings</mat-icon>
      <span>Account Settings</span>
    </button>
    <button mat-menu-item>
      <mat-icon>notifications_off</mat-icon>
      <span>Disable alerts</span>
    </button>
    <button mat-menu-item (click)="jwtAuth.signout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>{{ "SIGNOUT" | translate }}</span>
    </button>
  </mat-menu>
</mat-toolbar>