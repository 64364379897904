import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: "root",
})
export class LoggingService {

    constructor(
        private http: HttpClient,
    ) {

    }

    logerror(err: any) {
return;
        this.http.post(environment.apiURL + "/log/log", JSON.stringify(err))
            .subscribe(res => {



            }, err => {

            });
    }


}
