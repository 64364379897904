import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import {IMenuItem, DefaultMenu_notLogin  } from  '../models/menu.model'

import { JwtAuthService } from "app/shared/services/auth/jwt-auth.service";

@Injectable()
export class NavigationService {
  constructor(
    public jwtAuth: JwtAuthService
  ) {
 

  }

  iconMenu: any = this.jwtAuth.isAuthenticated? this.jwtAuth.ls.getItem("MdaHub_APP_USER").MenuItems :DefaultMenu_notLogin;
    
  separatorMenu: IMenuItem[] ;

  plainMenu: IMenuItem[] ;

   
  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle: string = "Frequently Accessed";
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();
  

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    switch (menuType) {
      case "separator-menu":
        this.menuItems.next(this.separatorMenu);
        break;
      case "icon-menu":
        this.menuItems.next(this.iconMenu);
        break;
      default:
        this.menuItems.next(this.plainMenu);
    }
  }
}
