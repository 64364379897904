import { MatDialogModule } from '@angular/material/dialog';
import { Colordailogcomponent } from '../../../shared/components/colordailog/color-dailog-component';
import { MatInputModule } from '@angular/material/input';
import { DrawWidgetComponent } from './draw-widget/draw-widget.component';
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { MatListModule } from '@angular/material/list';
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { BaseWidgetModule } from 'app/GeoHub/base-widget/base-widget.module';
import { SharedComponentsModule } from 'app/shared/components/shared-components.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SharedPipesModule } from 'app/shared/pipes/shared-pipes.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
   imports: [

      SharedPipesModule,
      TranslateModule,
      BaseWidgetModule,
      MatDialogModule,
      FormsModule,
      MatToolbarModule,
      MatListModule,
      MatTooltipModule,
      CommonModule,
      MatSelectModule,
      MatButtonToggleModule,
      MatDividerModule,
      MatIconModule,
      MatButtonModule,
      MatGridListModule,
      PerfectScrollbarModule,
      FlexLayoutModule,
      MatInputModule, SharedComponentsModule
   ],
   declarations: [DrawWidgetComponent],
   exports: [DrawWidgetComponent],
   providers: []
})
export class DrawWidgetModule { }
