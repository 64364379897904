import { AppLayer } from './../../GIS-Shared-Lib/interface/Layers-Maps.interface';
import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { Style, Stroke, Fill, RegularShape, Circle, Text as olText } from "ol/style";
import CircleStyle from 'ol/style/Circle';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { WebApiResult } from 'app/shared/models/apiresult';
import { map, catchError } from 'rxjs/operators';
import { AppConfirmService } from 'app/shared/services/app-confirm/app-confirm.service';

@Injectable({
  providedIn: 'root'
})
export class LayerStyleService {

  public LayerStyleObserver = new Subject<AppLayer>();
  public LayerStyleObserver$ = this.LayerStyleObserver.asObservable();
  constructor(
    private http: HttpClient,
    private confirmService: AppConfirmService,

  ) { }

  getestylePolygon(color, officeNumb) {


    return (new Style({
      stroke: new Stroke({
        color: color,
        width: 1

      }),
      fill: new Fill({
        color: color
      }),

      text: new olText({
        font: '14px Calibri,sans-serif',
        fill: new Fill({ color: "white" }),
        text: officeNumb
      })
    }))
  }

  getestylePoint(color) {
    return (new Style({

      image: new Circle({
        fill: new Fill({
          color: color
        }),
        stroke: new Stroke({
          color: color,
          width: 2
        }),
        radius: 5
      })
    }))
  }

  getestyleheatMap(fillColor, strokeColor, radius) {
    return (new Style({

      image: new Circle({
        fill: new Fill({
          color: fillColor
        }),
        stroke: new Stroke({
          color: strokeColor,
          width: 2
        }),
        radius: radius
      })
    }))
  }

  setStyle(result, oldRegularShape?) {
    var styles: Style = new Style({});


    if (result.featureType.toLowerCase() == 'point') {
      var styleType;
      /*
       if (oldRegularShape) {
         styleType = oldRegularShape;
         styleType.getFill().setColor(result.FILL);
         styleType.getStroke().setColor(result.OUTLINE);
         // styleType.stroke_.setWidth(result.SymbolSize)
         styleType.setScale(5);
       }
       */
      let stroke = new Stroke({ color: result.OUTLINE, width: 2 });
      let fill = new Fill({ color: result.FILL });
      switch (result.Name) {
        case 'square':
          styleType = new RegularShape({
            fill: fill,
            stroke: stroke,
            points: 4,
            radius: result.SymbolSize,
            angle: Math.PI / 4,
          })

          break;

        case 'triangle':
          styleType = new RegularShape({
            fill: fill,
            stroke: stroke,
            points: 3,
            radius: result.SymbolSize,
            rotation: Math.PI / 4,
            angle: 0,
          })

          break;

        case 'star':
          styleType = new RegularShape({
            fill: fill,
            stroke: stroke,
            points: 5,
            radius: result.SymbolSize,
            radius2: 4,
            angle: 0,
          })

          break;

        case 'cross':
          styleType = new RegularShape({
            fill: fill,
            stroke: stroke,
            points: 4,
            radius: result.SymbolSize,
            radius2: 0,
            angle: 0,
          })

          break;
        case 'x':
          styleType = new RegularShape({
            fill: fill,
            stroke: stroke,
            points: 4,
            radius: result.SymbolSize,
            radius2: 0,
            angle: Math.PI / 4,
          })

          break;
        case 'stacked':
          styleType = new RegularShape({
            fill: fill,
            stroke: stroke,
            points: 4,
            radius: 5,
            angle: Math.PI / 4,
            displacement: [0, 10],
          })

          break;
        case 'classified':
          if (oldRegularShape) {
            styleType = new RegularShape({
              fill: fill,
              stroke: stroke,
              points: oldRegularShape.getPoints(),
              radius: oldRegularShape.getRadius(),
              angle: oldRegularShape.getAngle(),
              radius2: oldRegularShape.getRadius2(),
              rotation: oldRegularShape.getRotation(),
              displacement: oldRegularShape.getDisplacement(),
            })
          }
          else {
            styleType = new CircleStyle({
              radius: 5,
              fill: fill,
              stroke: stroke,

            })
            styleType.setScale(1)
          }


          break;
        default:

          break;
      }
      styles = new Style({ image: styleType });

    }
    else {
      styles = new Style({
        stroke: new Stroke({
          color: result.OUTLINE,
          width: result.SymbolSize
        }),
        fill: new Fill({
          color: result.FILL
        })
      })
    }

    /*
    var image = new CircleStyle({
      radius: 5,
      fill: null,
      stroke: stroke,
    });
    styles['Point'] = new Style({ image: image });
*/
    return styles
  }


  getAttribute(URL) {

    let promise = new Promise((resolve, reject) => {
      fetch(URL)
        .then(response => {

          return response.json();
        }).catch(error => {
        })
        .then(Featuresresponse => {

          resolve(Featuresresponse);
          return Featuresresponse;

        });
    });
    return promise;
  }

  getLayerSpec(workSpace: string, layerName: string, serverUrl: string) {
    
var url =environment.apiURL + "/api/GeoserverManager/GetFeatureType?workspaceName=" + workSpace + "&featureTypeName=" + layerName + "&serverUrl=" + serverUrl;
    //http://vgishub.com:5050/api/GeoserverManager/GetFeatureType?workspaceName=Vision&featureTypeName=DrawPoint&serverUrl=http%3A%2F%2Fvgishub.com%3A8080%2Fgeoserver
   
    return this.http.get(url).pipe(
      map((res: WebApiResult) => {
        return res;
      }),
      catchError((error) => {
        this.confirmService.showWarn("Error", "Unable to get Layer specification ");
        return throwError(error);
      })
    );

  }
}
