import { AppLayer } from 'app/GeoHub/GIS-Shared-Lib/interface/Layers-Maps.interface';
import Point from 'ol/geom/Point';

import { Injectable } from '@angular/core';
import { Overlay, Feature } from 'ol';

import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';


import Select from 'ol/interaction/Select';
import { singleClick } from 'ol/events/condition';

import { Geo_Columns_Unlisted } from 'app/GeoHub/GIS-Shared-Lib/const/Const.config';
 
import { Subject } from 'rxjs';
import ContextMenu from "ol-contextmenu"
import { FeatureinfoService } from 'app/GeoHub/GIS_Component/featureinfo/featureinfo.service';
import { PopupinfoResult } from 'app/GeoHub/GIS_Component/featureinfo/feature-result.interface';
import { MapInteractions } from 'app/GeoHub/GIS_Component/map-componants/v-map-component/event.model';


@Injectable({
  providedIn: 'root'
})
export class MapContextMenuService {

  public contextMenu: ContextMenu;
  ///// popup elements 
  public popuptitle;
  public popupcontent;
  public popupOverlay: Overlay;

  ////////
  public mapInteractions: MapInteractions = new MapInteractions({ source: "" });
  public mapInteractionsObserver = new Subject<MapInteractions>();
  public mapInteractionsObserver$ = this.mapInteractionsObserver.asObservable();
  constructor(
    private featureinfoService: FeatureinfoService

  ) {
 
 

  }

  MapContextMenuAddItem(text: string, classname?: string, icon?: string, callback?: any, sender?:any) {

    this.contextMenu.push({
      text: text,
      classname: classname,
      callback: callback,
      icon: icon
    })

  }


  createContextMenu(map: Map) {
    var self = this;
    try{
if(this.contextMenu)
map.removeControl(this.contextMenu);
    }
    catch(err){
      
    }
    this.contextMenu = new ContextMenu({
      width: 170,
      defaultItems: true, // defaultItems are (for now) Zoom In/Zoom Out
      items: [{
        text: 'Center map here',
        icon: "assets/mapicons/fullscreen.png",
        callback: function (obj) {
          map.getView().setCenter(obj.coordinate);

        },

      }, '-']
    });
    map.addControl(this.contextMenu);
  }



  showinfowindow(coordinate, title: string, content: (string | HTMLElement)) {
    this.popupOverlay.setPosition(coordinate);
    this.popuptitle.innerHTML = title;
    this.popupcontent.innerHTML = "";
    if (content instanceof HTMLElement)
      this.popupcontent.appendChild(content);
    else
      this.popupcontent.innerHTML = content;
  }


}