import { IncidentNotificationComponent } from '../../../BusinessWidgets/Incidents-Managment/IncidentNotification/incident-notification.component';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { environment } from "environments/environment";
import { NotificationService } from "app/shared/components/notifications/notification.service";
import Map from "ol/Map";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { JwtAuthService } from "app/shared/services/auth/jwt-auth.service";
import {
  NotificationsMessage,
  NotificationTypeEnum,
} from "./Notification.interface";
 
import { Utilites } from "app/GeoHub/GIS-Shared-Lib/shared/shared.functions";
import { VMapService } from "app/GeoHub/GIS_Component/map-componants/v-map-component/v-map.service";
 import { AppConfirmService } from "app/shared/services/app-confirm/app-confirm.service";
import { AppAcceptMessageService } from "app/shared/services/app-Accept-msg/App-Accept-Message.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { RoadClosureNotificationComponent } from '../../../BusinessWidgets/RoadClosure/road-closure-notification/road-closure-notification.component';
import { RoadClosureService } from 'app/BusinessWidgets/RoadClosure/RoadCluser.service';
import { IncidentService } from 'app/BusinessWidgets/Incidents-Managment/Incidents.service';

@Component({
  selector: "app-notifications",
  templateUrl: "./notification.Component.html",
  styleUrls:["./Notifications-style.scss"]
})
export class NotificationComponent implements OnInit, OnDestroy, AfterViewInit {
  GroupNotifications = [];
  @ViewChild("incidentnotification") incidentnotification: IncidentNotificationComponent;
  @ViewChild("roadclosurenotification") roadclosurenotification: RoadClosureNotificationComponent;
  @ViewChild("notificationAccordion")    notificationAccordion;

  Chatusers: Array<object> = [
    { Name: "Ahmed", Id: "0000" },
    { Name: "Mohamed", Id: "0000" },
    { Name: "Hiba", Id: "0000" },
  ];

  groups: Array<object> = [
    { Name: "Vision", Id: "0000" },
    { Name: "Egypt", Id: "0000" },
  ];
  SelectedUserto: any;
  SelectedGroupto: any;
  map: Map;
  sendenabled: boolean = true;
  username: string;
  public messageValue: string;
  constructor(
    private snack: MatSnackBar,
    public notificationService: NotificationService,
    private jwtAuth: JwtAuthService,
    private vMapService: VMapService,
    private acceptservice: AppAcceptMessageService,
    private confirmmsg: AppConfirmService

  ) { }
  ngAfterViewInit(): void {
    this.notificationService.notificationAccordion=this.notificationAccordion;
    }

  removeNotificationsMessages() {

    this.notificationService.Messages = [];
  }
  ngOnInit() {



    this.notificationService.Messages.length = 0;
    if (this.roadclosurenotification)
      this.roadclosurenotification.roadClousures.length = 0;


    if (this.jwtAuth.isLoggedIn()) {
      this.getNotifications();
      this.notificationService.newMessageObserver$.subscribe(res => {

        if (res)
          switch (res.NotificationType) {
            case NotificationTypeEnum.AddedIncident: {
              this.incidentnotification.refresh();
              break;
            } case NotificationTypeEnum.RoadClousre: {
              this.roadclosurenotification.refresh();
              break;
            }
          }

      })
    }

  }

  getNotifications() {

    this.notificationService.GetAllNotification().subscribe((result) => {
      if (result.IsSuccess) {
        this.GroupNotifications = new Utilites().groupBy(
          result.data,
          (notification: NotificationsMessage) =>
            notification.NnotificationTypeName
        );
      } else {
      }
    });
  }
  ngOnDestroy() { }
  zoomToAlert(item: NotificationsMessage) {
    if (
      item.Type == NotificationTypeEnum.FireAlarm ||
      item.Type == NotificationTypeEnum.RoadClousre ||
      item.Type == NotificationTypeEnum.AddedIncident
    ) {
      if (!this.vMapService.map)
        this.confirmmsg.showWarn(
          "Notification",
          "Open the map to zoom to location"
        ).subscribe(p => {
          this.refreshHeader();
        });
      else {
        const coords = [item.Lat, item.Long];
        var feature = new Feature();
        feature.setGeometry(new Point(coords));
        this.vMapService.map.getView().fit(feature.getGeometry().getExtent());
        this.vMapService.map.getView().setZoom(12);
      }
    } else {
      if (item.Type == NotificationTypeEnum.GroupInvite)
        this.openGroupAcceptInvite(item);
      else if (item.Type == NotificationTypeEnum.GroupRequest)
        this.openGroupAcceptrequest(item);
      else if (item.Type == NotificationTypeEnum.Announcement)
        this.confirmmsg.showmessage(item.Title, item.Body);
      else this.confirmmsg.showmessage(item.Title, item.Body);
    }


  }
  refreshHeader() {

    document.getElementById("appheadertopDiv").style.position = "absolute";
    setTimeout(function () {
      try {
        document.getElementById("appheadertopDiv").style.position = "relative";
      } catch (err) { }
    }, 5);
  }
  openGroupAcceptInvite(Notification) {
    var x = this.acceptservice.showAcceptReject(
      "Group invitation",
      "Click accept invitation or reject to cancel invitation "
    ).subscribe(RES => {
      this.refreshHeader();
      if (RES !== "cancel") {
        this.notificationService.GroupRequestsUpdate(Notification.Id, RES == "accept" ? "1" : "2").subscribe(res => {
          if (res.IsSuccess)
            this.snack.open("Accepted", "OK", { duration: 2000 });
          else
            this.snack.open("Unable to process your request ", "OK", { duration: 2000 });
        });
        this.getNotifications();
      }
    });
  }
  openGroupAcceptrequest(Notification) {
    var x = this.acceptservice
      .showAcceptReject(
        "Group subscription",
        "Click Ok to accept request or cancel to reject"
      )
      .subscribe((RES) => {
        this.refreshHeader();
        if (RES !== "cancel") {
          this.notificationService.GroupRequestsUpdate(Notification.Id, RES == "accept" ? "1" : "2").subscribe(res => {
            if (res.IsSuccess)
              this.snack.open("Accepted", "OK", { duration: 2000 });
            else
              this.snack.open("Unable to process your request ", "OK", { duration: 2000 });
          });
          this.getNotifications();
        }
      });
  }

  removeNotificationsGroup(group) {
    for (var i = 0; i < this.GroupNotifications.length; i++) {
      if (this.GroupNotifications[i] == group)
        this.GroupNotifications.splice(i, 1);
    }
    this.notificationService.notifCount = 0;
    this.GroupNotifications.forEach((element) => {
      this.notificationService.notifCount += element.Data.length;
    });
  }
}
