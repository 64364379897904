import { MeasureComponent } from './measure.component';
     import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { MatListModule } from '@angular/material/list';
  import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatToolbarModule } from '@angular/material/toolbar';
 import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
 import { BaseWidgetModule } from 'app/GeoHub/base-widget/base-widget.module';
 import { SharedPipesModule } from 'app/shared/pipes/shared-pipes.module';
 


@NgModule({
  declarations: [ MeasureComponent]
  ,exports: [MeasureComponent],
  imports: [
    SharedPipesModule,
    MatInputModule,
    FormsModule,
    MatToolbarModule,
    MatListModule,
    MatTooltipModule,
    CommonModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatIconModule,
    MatButtonToggleModule,
     MatGridListModule,
     BaseWidgetModule
    
   ]
})
export class MeasureWidgetModule { }
