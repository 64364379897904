import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-accept-msg',
  template: `<h1 matDialogTitle style=" margin: -14px; " class="mb-05">{{ data.title }}</h1>
  <hr style="    margin-top: -9px;  margin-left: -18px" color="accent">
    <div mat-dialog-content class="mb-1" [innerHTML]="data.message"> </div>
    <div mat-dialog-actions>
    <button 
    type="button" 
    mat-flat-button style="    line-height: 30px"
    color="primary" 
    (click)="dialogRef.close('accept')">Accept</button>
    &nbsp;
    <span fxFlex></span>

    <button 
    type="button"
    color="warn" style="    line-height: 30px"
    mat-flat-button 
    (click)="dialogRef.close('reject')"  >Reject</button>
    &nbsp;
    <span fxFlex></span>
    <button 
    type="button" style="    line-height: 30px"
    color="default"
    mat-flat-button 
    (click)="dialogRef.close('cancel')"  >Cancel</button>
    </div>`,
})
export class AppAcceptMessageComponent {
  constructor(
    public dialogRef: MatDialogRef<AppAcceptMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    
  ) {}
}