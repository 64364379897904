import { ArcGIS_ServiceType, GISserverTypes, layerTypesEnum, UserRequestTypes } from "../../GeoEnums";
import { LayerFields } from "../../interface/arcgis.data.mode.interfacel";
export interface SearchLogdata{
   Name: string;
   URL: string;
   ServerType?: number;
   LAYERS: string[];
   Thumbnail: string;
   ArcGISServerName: string;
   Details: string;
   EPSG: string;
   Owner: string;
   Extent?: number[];
   LayerType?: number;
   Description: string;
   CopyrightText: string;
   ArcgisServiceType?: number;
   UpdateDate?: string;
}
export interface InnerSubLayer
{
  MaxScaleDenominator: number,
  MinScaleDenominator:number,
  Name:string,
  Style?: any,
  Title:string, 
}
 
 export class LoadDataOptions {
    public name: string;
    public Token: string;
    public requestType: UserRequestTypes;
    
    public url: string;
    public defaultValue:string;
    public ServerType:GISserverTypes;
    public source: string;
    public file: string;
    public data: string;
    public host: string;
    public port: string;
    public DB: string;
    public username: string;
    public password: string;
 
    public schema: string;
    public DBtype: string;
    constructor(options :LoadDataParamsOptions) {
        this.name  = options.name ?options.name : "";
      this.Token  = options.Token ?options.Token : "";
        this.requestType  = options.requestType ?options.requestType : UserRequestTypes.get_UserContents;
        this.url  = options.url ?options.url : "";
        this.source  = options.source ?options.source : "";
         this.file  = options.file ?options.file : "";
        this.data  = options.data ?options.data : "";
        this.host  = options.host ?options.host : "";
        this.port  = options.port ?options.port : "";
        this.DB  = options.DB ?options.DB : "";
        this.username  = options.username ?options.username : "";
        this.password  = options.password ?options.password : "";  
         this.schema  = options.schema ?options.schema : "";
        this.DBtype  = options.DBtype ?options.DBtype : "";
        this.defaultValue  = options.defaultValue ?options.defaultValue : "";
        this.ServerType  = options.ServerType ?options.ServerType : GISserverTypes.NONE
    }

}
export interface LoadDataParamsOptions{
    defaultValue?:string;
      name?: string;
        ServerType:GISserverTypes;
    source?: string; 
    requestType?: UserRequestTypes;
    url?: string;
    file?: string;
    data?: string;
    host?: string;
    port?: string;
    DB?: string;
    username?: string;
    password?: string;
    Token?: string;

    schema?: string;
    DBtype?: string;
}
export class SearchResultHistory {
 
   public name: string;
   public dataconnection: LoadDataOptions;
   public SearchResult: SearchResultLayer[];
}
 export class SearchResultLayer {
    public Id: number;

    public Name: string;
    public Owner: string;
    public Url: string;
    public ServerType: GISserverTypes;
    public LAYERS: string[];
     public UpdateDate?: Date;
    public Thumbnail?: string;
     public EPSG: string;
     public isSelected?: boolean=false;
    public Extent : [number,number,number,number] ;
    public LayerType : layerTypesEnum ;
     public DisplayField?: string;
    public Description?: string;
    public CopyrightText?: string;
    public GeometryType?: string;
    public  FeatureDetails?:any;
    public  SubLayers?: any | InnerSubLayer[] ;
    public Token?: string;
   
    public   Details?: string;
  
    public Fields?: LayerFields[]=[];

    public ArcGISServerName?: string;

   
  
    public   ShareType?: number | null;

 
    public  ArcgisServiceType?:ArcGIS_ServiceType;  


 }
