import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ColorSketchModule } from 'ngx-color/sketch';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { IncidentNotificationComponent } from '../../BusinessWidgets/Incidents-Managment/IncidentNotification/incident-notification.component';
import { SharedDirectivesModule } from '../directives/shared-directives.module';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { SearchModule } from '../search/search.module';
import { AppAcceptMessageComponent } from '../services/app-Accept-msg/App-Accept-Message.component';
import { AppComfirmComponent } from '../services/app-confirm/app-confirm.component';
import { AppLoaderComponent } from '../services/app-loader/app-loader.component';
import { SharedMaterialModule } from '../shared-material.module';
import { BottomSheetShareComponent } from './bottom-sheet-share/bottom-sheet-share.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ButtonLoadingComponent } from './button-loading/button-loading.component';
import { Colordailogcomponent } from './colordailog/color-dailog-component';
// ONLY FOR DEMO
import { CustomizerComponent } from './customizer/customizer.component';
import { EgretSidebarComponent, EgretSidebarTogglerDirective } from './egret-sidebar/egret-sidebar.component';
import { EgretExampleViewerTemplateComponent } from './example-viewer-template/example-viewer-template.component';
import { EgretExampleViewerComponent } from './example-viewer/example-viewer.component';
import { FooterComponent } from './footer/footer.component';
// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import { HeaderSideComponent } from './header-side/header-side.component';
// ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT
import { HeaderTopComponent } from './header-top/header-top.component';
// ALWAYS REQUIRED 
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { ApplicationLayoutComponent } from './layouts/Application-Layout/Application-Layout.component';
import { GuestLayoutComponent } from './layouts/guest-layout/guest-layout.component';
import { MapLayoutComponent } from './layouts/Map-layout/map-layout.component';
import { TopbarFooterLayoutComponent } from './layouts/topbar-footer-layout/topbar-footer-layout.component';
import { TopbarLayoutComponent } from './layouts/topbar-layout/topbar-layout.component';
import { ChatToUserComponent } from './message-chat/chat-to-user/chat-to-user.component';
import { MessageChatComponent } from './message-chat/message-chat.component';
import { NotificationComponent } from './notifications/notification.component';
import { RoadClosureNotificationComponent } from '../../BusinessWidgets/RoadClosure/road-closure-notification/road-closure-notification.component';
import { SidebarSideComponent } from './sidebar-side/sidebar-side.component';
import { SidebarTopComponent } from './sidebar-top/sidebar-top.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { EmptyLayoutComponent } from './layouts/Empty-Layout/empty-layout.component';
import { EpsgSelectorComponent } from './epsg-selector/epsg-selector.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
 


 

const components = [
  HeaderTopComponent,
  SidebarTopComponent,
  SidenavComponent,
  NotificationComponent,
  IncidentNotificationComponent,
  RoadClosureNotificationComponent,
  MessageChatComponent,
  SidebarSideComponent,
  HeaderSideComponent,
  MapLayoutComponent,
  ApplicationLayoutComponent,
  EmptyLayoutComponent,
  TopbarLayoutComponent,
  TopbarFooterLayoutComponent,
   AdminLayoutComponent,
  GuestLayoutComponent,
  BreadcrumbComponent,
  AppComfirmComponent,
  AppAcceptMessageComponent,
  AppLoaderComponent,
  CustomizerComponent,
  ButtonLoadingComponent,
  EgretSidebarComponent,
  FooterComponent,
  EgretSidebarTogglerDirective,
  BottomSheetShareComponent,
  EgretExampleViewerComponent,
  EgretExampleViewerTemplateComponent,
  Colordailogcomponent,
  ChatToUserComponent,
  EpsgSelectorComponent
  
]

@NgModule({
  imports: [
 
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    SearchModule,
    SharedPipesModule,
    SharedDirectivesModule,
    SharedMaterialModule,
  MatSelectModule,
    MatIconModule,
    ColorSketchModule,
    MatInputModule

  ],
  declarations: components,
  entryComponents: [AppComfirmComponent, AppAcceptMessageComponent, AppLoaderComponent, BottomSheetShareComponent,MessageChatComponent,EpsgSelectorComponent],
  exports: components
})
export class SharedComponentsModule { }