<div class="mainchat " [style.height]="isMminimized ? '30px' : '400px'"
  style="
    position: fixed;
    right: 0;
        bottom: 0;
    display: none;
    z-index: 99999999;
  "
  [style.display]="isVisible ? 'block' : 'none'"
>
  <div  
    (click)="isMminimized = !isMminimized" 
    class="full-width mat-toolbar mat-accent"
    style="white-space: nowrap; cursor: pointer; height:39px"
  >
    <div  style="float: left;    box-shadow: 0 4px 8px rgba(0,0,0,.25);
    border-bottom: 0;width: 100%; display: block!important ;">
      <span style="float: left; padding-top: 5px">
        <mat-icon> supervisor_account </mat-icon>
      </span>

      <span>Friends</span>

      <span
      mat-flat-button style="float: right;"
      class="mat-primary headerbasewidgetfont"
      (click)="isVisible=false; isMminimized=true;"
    >
      <mat-icon color="primary" style="font-size: 22px;"
        >close</mat-icon
      >
    </span>
    </div>
  </div>

  <div
    fxLayout="column" 
   class="maindiv"
  >
    <div class="full-width"
    
    *ngIf="  messageChatService.Chathomeview"

    [style.height]="isMminimized ? '0px' : '360px'" [style.display]="isMminimized ? 'none': 'block'">
    <mat-spinner *ngIf="1> currentChats.length" diameter="50" color="accent" class="LoaddingSpinner"></mat-spinner>
      <ul class="ng-chat-users">
        <li *ngFor="let user of currentChats" class="litem" style="  cursor: pointer"
        (click)="selectTO(user)">
          <div class="ng-chat-friends-list-container">
            <!-- <button [matTooltip]="user.UserName" mat-mini-fab color="default" class="img_avatar">
              <span style="text-transform: uppercase">
                {{ user.UserName | slice: 0:2 }}
              </span>
            </button> -->
            <div class="txtshortbut"    height="30"
            width="30">    <span style="text-transform: uppercase">
              {{ user.UserName | slice: 0:2 }}
            </span></div>
<!--             
        <img
              alt=""
              class="img_avatar"
              height="30"
              width="30"
              src="https://66.media.tumblr.com/avatar_9dd9bb497b75_128.pnj"
            /> -->
            
            <strong title=" {{ user.FullName }}">                {{ user.FullName }}</strong
            ><span class="ng-chat-participant-status  " title="" st>  <mat-icon style="    font-size: 16px;"
              [style.color]="!user.onlinestatus ? '#f91c1e' : '#92a40'"
            
            >
              fiber_manual_record
            </mat-icon></span
            ><!---->
    
            <span
          class="chat-number mat-bg-accent" *ngIf="user.chats.length>0">{{user.chats.length}}</span>
          </div>
        </li>
      </ul>
      <!-- <table
        class="full-width headerbg"
        *ngIf="messageChatService.Chathomeview"
      >
        <tr class="row mat-row" *ngFor="let user of currentChats">
          <td
            class="mat-cell cdk-cell cdk-column-select mat-column-select ng-star-inserted chatitem"
            style="display: inline-block"
          >
            <div
              style="display: flex; cursor: pointer"
              (click)="selectTO(user)"
              [disabled]="!onlinestatus"
            >
              <button [matTooltip]="user.UserName" mat-mini-fab color="default">
                <span style="text-transform: uppercase">
                  {{ user.UserName | slice: 0:2 }}
                </span>
              </button>

              <div style="color: black; text-align: left; width: 100%">
                {{ user.FullName }}
                <mat-icon
                  [style.color]="!user.onlinestatus ? '#f91c1e' : '#92a40'"
                  style="float: right; color: #92a400; font-size: 14px"
                >
                  fiber_manual_record
                </mat-icon>
              </div>
            </div>
            <span
              *ngIf="user.chats && user.chats.length > 0"
              style="text-align: right; float: right"
            >
              {{
                user.chats[user.chats.length - 1].TimeStamp | date: "HH:mm "
              }}</span
            >

            <span
              *ngIf="user.chats && user.chats.length > 0"
              style="padding-left: 60px; font-size: 10px; color: grey"
            >
              {{
                user.chats[user.chats.length - 1].EncryptedMessage | slice: 0:30
              }}</span
            >
          </td>
        </tr>
      </table> -->
    </div>
    <div   [style.display]="isMminimized ? 'none': 'block'"
      *ngIf="CurrnetSelectedUser && !messageChatService.Chathomeview"
      class="full-width"
    >
      <app-chat-to-user 
        #chatctrl
        class="full-width"
        [(data)]="CurrnetSelectedUser"
      >
      </app-chat-to-user>
    </div>
  </div>
</div>
