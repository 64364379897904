export interface IUser {
  Id?: string;
  displayName?: string;
  role?: string;
  RoleId?: number;
  MenuItems: any[];
  username: string;
  Token: string;
  PictureUrl: string;
  Email: string;
  PhoneNumber: string;
  FirstName: string;
  LastName: string;
  Deactivated: boolean;
  CreationDate: Date;
  Organisation: string;
  Password: string;
  UserType: number;
  Address_Country: number;
  Address_Details: string;
  IsNewsLetter: boolean;
}


export const initialUserState: IUser = {
  // Id: null,
  displayName: null,
  role: null,
  RoleId: 1,
  MenuItems: [],
  username: null,
  Password: null,
  Email: null,
  PhoneNumber: null,
  Token: null,
  PictureUrl: null,
  FirstName: null,
  LastName: null,
  Deactivated: true,
  CreationDate: new Date(),
  Organisation: null,
  UserType: 5,
  Address_Country: 1,
  Address_Details: null,
  IsNewsLetter: true
}