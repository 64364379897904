
import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders
} from "@angular/common/http";
import { Observable } from "rxjs";
 import { environment } from "environments/environment";
import { JwtAuthService } from "../services/auth/jwt-auth.service";

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

  constructor(private jwtAuth: JwtAuthService) { }

  intercept(
    req: HttpRequest<any>, 
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    
    var token = this.jwtAuth.token || this.jwtAuth.getJwtToken();

    var changedReq;
 
      if (!environment.DomainAuth   && token && (req.url.toLowerCase().indexOf("/api/") > -1)) { //  req.url.toLowerCase().indexOf("vgishub") > -1 || req.url.toLowerCase().indexOf("mdahub") > -1  || req.url.toLowerCase().indexOf("vision") > -1  )

        changedReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
            'Access-Control-Allow-Origin': "*",
            'Content-Type': 'application/json',
            
          },
        });

      } else if( environment.DomainAuth   && req.url.toLowerCase().indexOf("/api/") > -1 ) {
        
        changedReq = req.clone({  
          withCredentials: true 
         
        });
          
        

     }else
     changedReq=req;


 
    return next.handle(changedReq);
  }
}
