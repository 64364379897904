import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WriteTransactionOptions } from "ol/format/WFS";
import GeoJSON from "ol/format/GeoJSON";
import { Observable, throwError, from, of } from "rxjs";
import Feature from "ol/Feature";
import { FeatureCollection } from "geojson";
import { catchError, map } from "rxjs/operators";
import { Subject } from 'rxjs';
import { Coordinate } from 'ol/coordinate';
import { Map } from "ol";
import { AppLayer } from "app/GeoHub/GIS-Shared-Lib/interface/Layers-Maps.interface";
 import { environment } from "environments/environment";
import { WebApiResult } from "app/shared/models/apiresult";
import { RoadClosureAttributes } from "./RoadClosure.interface";
 

@Injectable({
  providedIn: "root"
})
export class RoadClosureService {
  geojsonFormat = new GeoJSON();
  public roadClusercoordinate = new Subject<{ coordinate: Coordinate, eventName: string, map: Map }>();
  public roadClusercoordinate$ = this.roadClusercoordinate.asObservable();
  public roadclosurelayer: AppLayer = null;
  roadClosureAttributes: RoadClosureAttributes;
  public Closurecount: number=0;;

  constructor(private http: HttpClient) {
this.loadRoadClousureConfig();

   }

  AddroadCluserToMap(coordinate: Coordinate, map: Map) {
    this.roadClusercoordinate.next({ 'coordinate': coordinate, eventName: 'features', 'map': map });
  }
 
  getDataDomains() {
    var self = this;
  
     return this.http.get(environment.apiURL+ "/api/GeneralKNPC/getDataDomains").pipe(
       map((response: WebApiResult) => {
        
        
         return response.data;
       }),
       catchError((error) => {
         return throwError(error);
       })
     );
   }
  loadRoadClousureConfig() {
    var self=this;
    const jsonFile = "./assets/configurations/roadclosure.json";
    if (self.roadClosureAttributes)
    return of(self.roadClosureAttributes).pipe(
      map((data) => {
        return self.roadClosureAttributes;
      })
    );



    return this.http.get(jsonFile).pipe(
      map((response: RoadClosureAttributes) => {
        this.roadClosureAttributes=response;
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getRoadclousure(){
 

    var url = `${this.roadClosureAttributes.Setting.ServerURL}/wms?service=WFS&version=1.0.0&request=GetFeature&typeName=${this.roadClosureAttributes.Setting.ViewLayerName}` + "&SRSNAME=" + environment.mapDefaultProjection + "&CRS=" + environment.mapDefaultProjection + "&SRS=" + environment.mapDefaultProjection + "&outputFormat=application%2Fjson" + "&CQL_FILTER= " + this.roadClosureAttributes.IsDeleted_Field + " = '2'";

    let promise = new Promise((resolve, reject) => {

      if (url) {
        fetch(url)
          .then(response => {
            return response.json();
          }).catch(error => {
            console.log(error);

          })
          .then(Featuresresponse => {
            resolve(Featuresresponse);
            return Featuresresponse;
          });
      }




    }).catch(error => {

      console.log(error);
      throw error;
    });
    return promise;



  }
  getFeatureinfo(
    url: string,
    dataProjection: string,
    featureProjection: string
  ): Observable<Feature[]> {
    return this.http      .get(url, {        observe: "body",        responseType: "json"      })
      .pipe(
        map(response => {
          const featureCollection = <FeatureCollection<any, any>>response;
          let format = new GeoJSON();
          if (dataProjection !== featureProjection) {
            const pseudoGeoJSONFormat = <any>GeoJSON;
            format = new pseudoGeoJSONFormat({
              dataProjection: dataProjection,
              featureProjection: featureProjection
            });
          }
          return format.readFeatures(featureCollection);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }
}
