import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GisServerCategory, GISserverTypes, layerTypesEnum } from 'app/GeoHub/GIS-Shared-Lib/GeoEnums';
import { AppLayer, AppLayerGroup } from 'app/GeoHub/GIS-Shared-Lib/interface/Layers-Maps.interface';
import { WebMap } from 'app/GeoHub/GIS-Shared-Lib/interface/WebMap.interface';
import { MapContextMenuService } from 'app/GeoHub/GIS-Shared-Lib/services/MapContextMenu.service';
import { FeatureinfoService } from 'app/GeoHub/GIS_Component/featureinfo/featureinfo.service';
import { WebApiResult } from 'app/shared/models/apiresult';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { LocalStoreService } from 'app/shared/services/local-store.service';
import { environment } from 'environments/environment';
import { View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import { fromLonLat } from 'ol/proj';
//import { OSM } from 'ol/source';
//AS Modified to cover new types 
import { OSM, ImageArcGISRest, TileArcGISRest, XYZ } from 'ol/source';
import ImageLayer from 'ol/layer/Image';
import VectorSource from 'ol/source/Vector';

import { catchError, map } from 'rxjs/operators';
import { GisAppSharedService } from '../../../../GeoHub/GIS-Shared-Lib/services/gis-app-shared.service';
import { AppMap, WebLayer } from './../../../GIS-Shared-Lib/interface/WebMap.interface';
import { LayerManagmentService } from '../../../GIS-Shared-Lib/services/layer-managment.service';
import { BehaviorSubject, throwError } from 'rxjs';


export interface IVIMapService {
  target: string;
  sublayers: AppLayerGroup;
  view: View;
}
@Injectable({
  providedIn: 'root'
})
export class VMapService {

  public map: Map;
  public mapObserver: any = {};
  public mapObserver$;
  private appMapLayers: (AppLayer | AppLayerGroup)[];
  public appMapLayersObserver$: BehaviorSubject<((AppLayer | AppLayerGroup)[])>;
  private _appMap: AppMap = null;
  public newMap$ = new BehaviorSubject<(AppMap)>(this._appMap);
  public mapSaved: boolean = true;
  ///////// #####  shared GrphicLayer object and obsever 
  public GraphicLayer: AppLayer = null;



  public GraphicLayersource = new VectorSource({ wrapX: false });
  public webmap: WebMap;
  public appMap: AppMap;

  constructor(
    public mapContextMenu: MapContextMenuService,
    private http: HttpClient,
    private gisAppSharedService: GisAppSharedService,
    private ls: LocalStoreService,
    private router: Router,
    private featureinfoService: FeatureinfoService,
    public layerManagmentService: LayerManagmentService,
    private jwtAuth: JwtAuthService,
  ) {

    this.mapObserver$ = new BehaviorSubject<any>(this.mapObserver);
    this.appMapLayers = [];
    this.appMapLayersObserver$ = new BehaviorSubject<((AppLayer | AppLayerGroup)[])>(this.appMapLayers);
  }


  CreateNewMap() {
    this.webmap = null;
    this.map.getLayers().forEach(l => {
      this.map.removeLayer(l);

    });
    this.appMapLayersObserver$.next([]);
    this.newMap$.next(this.getDefaultmap());
  }
  createLayerfromUserContent(UserContent) {

    var details: WebLayer = UserContent.ContentMapLayer[0];
    var userLayer = {
      Id: details.Id,
      Name: details.Name.split("_")[0],
      Description: details.Description,
      Visible: true,
      Source: details.Source + "/wms",
      ServerType: GISserverTypes.WMS,
      LayerType: layerTypesEnum.WFS,
      LAYERS: [details.LAYERS],
      Opacity: 100,
      IsWidgetLayer: false
    };
    /**
         var details: WebMap = UserContent.ContentMapLayer[0];
    var userLayer = {
      Id: details.Id,
      Name: details.Name.split("_")[0],
      Description: details.Description,
      Visible: true,
      Source: details.ServerURL + "/wms",
      ServerType: GISserverTypes.WMS,
      LayerType: layerTypesEnum.WFS,
      LAYERS: [details.LayerURL],
      Opacity: 100,
      IsWidgetLayer: false
    };
     */
    var applayer = this.layerManagmentService.get_LayerBy_Ol_Type(details);

    return applayer;

  }

  createSharedLayer() {

    this.GraphicLayersource = new VectorSource({ wrapX: false });

    this.GraphicLayer = new AppLayer({
      Id: "AppGrlayer",
      name: "Graphic Layer",
      IsWidgetLayer: false,
      LayerType: layerTypesEnum.GraphicLayer,
      ServerType: GISserverTypes.GraphicLayer,
      serverCategory: GisServerCategory.GraphicLayer,
      LAYERS: null,
      Opacity: 100,
      url: null,
      Description: "All runtime vector data presented using this layer - draw search result buffer etc it is ahared layer allover the app ",
      layer: new VectorLayer({
        source: this.GraphicLayersource
      })
    }
    );
  }
  /**
   **Create Local storage for web maps 
    update the local storage and keep it updated fro future use to minimize requests 
   *  the Key of the LS vhub_recent_webmaps
   *
   * @param {WebMap} webmap
   * @memberof VMapService
   */
  AddAPP_localStorage_vhub_recent_webmaps(webmap: WebMap) {
    if (!this.ls.getItem("vhub_recent_webmaps") || !this.ls.getItem("vhub_recent_webmaps")[this.gisAppSharedService.getUser().username]) {
      var vhub_recent_webmaps = JSON.parse('{"' + this.gisAppSharedService.getUser().username + '":[],"vgishub":[]}');
      vhub_recent_webmaps[this.gisAppSharedService.getUser().username].push(webmap);
      this.ls.setItem("vhub_recent_webmaps", vhub_recent_webmaps);
    }
    else {
      var vhub_recent_webmaps = this.ls.getItem("vhub_recent_webmaps");
      var exist = false;
      vhub_recent_webmaps[this.gisAppSharedService.getUser().username].forEach(lsmap => {
        if (webmap.Id == lsmap.Id) {
          lsmap = webmap;
          exist = true;
        }
      });
      if (!exist)
        vhub_recent_webmaps[this.gisAppSharedService.getUser().username].push(webmap);
      this.ls.setItem("vhub_recent_webmaps", vhub_recent_webmaps);
    }
  }
  /**
   * Getting WebMap selcted by the user 
   * update the locala storage and keep it updated fro future use to minimize requests 
   * 
   * @returns
   * @memberof VMapService
   */
  public getMap(mapid: string) {


    //// Will Use the LS when finsihing the vmapservice 
    // if (this.ls.getItem("vhub_recent_webmaps") && this.ls.getItem("vhub_recent_webmaps")[this.gisAppSharedService.getUser().username]) {
    //   var vhub_recent_webmaps = this.ls.getItem("vhub_recent_webmaps");
    //   vhub_recent_webmaps[this.gisAppSharedService.getUser().username].forEach(lsmap => {
    //     if (mapid == lsmap.mapid) {
    //       this.webmap = lsmap;
    //       if (this.webmap.MapExtent == [0, 0, 0, 0])
    //         this.webmap.MapExtent = null;

    //       return of(this.webmap).pipe(

    //         map((data) => {
    //           return this.createMap(this.webmap.MapService); // send Layers[] : userMaps only
    //         }));

    //     }
    //   });
    // }


    if (!mapid) {

      mapid = "0";
    }

    this.appMapLayersObserver$.next([]);
    return this.http.get(`${environment.apiURL}/api/MapApp/UserMapById?Id=${mapid} `)
      .pipe(
        map((res: WebApiResult) => {
          this.webmap = res.data;
          if (res.IsSuccess) {
            if (res.data.Id == '00000000-0000-0000-0000-000000000000')
              res.data.Id = undefined;

            this.AddAPP_localStorage_vhub_recent_webmaps(res.data);
            this.ls.getItem("App_Layers_List")

            this.mapSaved = true;
            return this.createMap(this.webmap);

          }
          else {

            return this.getDefaultmap();
          }


        }),
        catchError(error => {
          this.gisAppSharedService.showWarn("Web Map Loadding ", "The requested web map not exist!!, Default web map loaded ");
          this.getDefaultmap();
          return throwError(error);
        })
      );
  }

  getDefaultmap() {
    var bm = new AppLayer({
      Id: "APPbasemap_default",
      name: "Open Street Map  OSM",
      IsBaseMap: true, LayerType: layerTypesEnum.OSM,
      Opacity: 100,
      ServerType: GISserverTypes.OSM,
      serverCategory: this.gisAppSharedService.Get_layer_Server_Category(GISserverTypes.OSM),
      url: null,
      LAYERS: null,
      Description: "OSM",
      layer: new TileLayer({
        source: new OSM()
      })
    }
    );
    var appMapLayers = [bm];
    this.createSharedLayer();
    appMapLayers.push(this.GraphicLayer);
    var x = environment;
    var userigeoInfo = null;

    try {
      userigeoInfo = this.jwtAuth.getUserGeoinfo();
    }
    catch { }
    var center = (userigeoInfo ? fromLonLat([userigeoInfo.longitude, userigeoInfo.latitude], environment.mapDefaultProjection)
      : fromLonLat([48, 28], environment.mapDefaultProjection));


    this.webmap = { Name: "New Map", Center: center };

    this.appMap = { webmap: this.webmap, appLayers: appMapLayers };

    this.appMapLayersObserver$.next(appMapLayers);
    return this.appMap;
  }

  createMap(mapobject: WebMap) {
    this.appMapLayersObserver$.next([]);
    var temparray: (AppLayer)[] = [];
    mapobject.ContentMapLayer.forEach(layer => {
      if (layer.IsBaseMap) {

        var Basemap = this.layerManagmentService.get_LayerBy_Ol_Type(layer);

        temparray.push(Basemap);

      }
    });

    mapobject.ContentMapLayer.forEach((l: any) => {
      if (!l.IsBaseMap) {

        var ll = this.layerManagmentService.get_LayerBy_Ol_Type(l);

        if (ll)
          temparray.push(ll);
      }
    });

    this.createSharedLayer();
    temparray.push(this.GraphicLayer);
    this.appMapLayersObserver$.next(temparray);

    this.appMap = { webmap: this.webmap, appLayers: temparray };

    return this.appMap;
  }
  addWebLayer(layer: WebLayer, index?: number) {
    var applayer = this.layerManagmentService.get_LayerBy_Ol_Type(layer);

    if (applayer) {


      this.addAppLayer(applayer, index);

      return applayer;

    }
    else
      return null;

  }

  addAppLayer(applayer: AppLayer, index?: number) {
    index = index ? index : this.appMapLayersObserver$.value.length - 1;


    //AS cover Case of ESRI service layer
    // window.alert("sssssss " + applayer.LayerType  ) ;
    if (applayer.LayerType == layerTypesEnum.ImageLayer_ImageArcGISRest) {

      applayer.layer = new ImageLayer({
        source: new ImageArcGISRest({
          ratio: 1,
          crossOrigin: "anonymous",
          params: {
            transparent: true,
            format: 'image/png'
          },
          url: applayer.url,
        })
      });


    }
    if (applayer.LayerType == layerTypesEnum.TileLayer_TileArcGISRest) {

      applayer.layer = new TileLayer({
        visible: true,
        source: new TileArcGISRest({
          url: applayer.url,
          params: {
            transparent: true,
            format: 'png8',
            dbi: 70
          },
          crossOrigin: "anonymous",
        })
      });
    }


    /////////////////////////////////////////////////
    applayer.layer.setZIndex(index);
    applayer.setZIndex(index);

    this.GraphicLayer.layer.setZIndex(this.appMapLayersObserver$.value.length);
    this.map.addLayer(applayer.layer);
    this.appMapLayersObserver$.value.splice(index, 0, applayer);
    this.appMapLayers = this.appMapLayersObserver$.value;
    this.appMapLayersObserver$.next(this.appMapLayers);
    this.GraphicLayer.layer.setZIndex(this.appMapLayers.length + 2);
    if (applayer.layer instanceof VectorLayer)
      this.featureinfoService.Add_MapLayerActionSingleClick(this.map, applayer);


    this.mapSaved = false;
    this.appMap.appLayers.push(applayer);
    return applayer;


  }

  UpdateApplayers() {
    this.appMapLayersObserver$.next(this.getMapLayers());
  }
  /**
   *Send geoJSON With layer name 
   *GEOJSON Should be  formated by new GeoJSON() or esriGSON()
   * @param {*} geojson
   * @memberof VMapService
   */
  AddGeoJsonVectorLayer(geojson, layerName) {
    var GraphicLayer = new VectorLayer({
      visible: true,
      source: new VectorSource({
        features: geojson
      })

    });



    var applayernew = new AppLayer({
      Id: "newgeojson" + layerName,
      IsBaseMap: false,
      visible: true, LayerType: layerTypesEnum.VectorLayer_GeoJSON,
      LAYERS: null,
      serverCategory: GisServerCategory.GeoJSON,
      url: null,
      Opacity: 1,
      ServerType: GISserverTypes.GraphicLayer,
      name: layerName,
      Description: layerName,
      layer: GraphicLayer
    }
    );

    this.addAppLayer(applayernew);
    (applayernew.layer as VectorLayer).setStyle(this.gisAppSharedService.GetDefaultStyle());

    return applayernew;
  }
  RearangeLayerindex() {
    var layersArranged;
    layersArranged = this.getMapLayers().sort(function (a, b) { return a.getZIndex() - b.getZIndex() });
    this.appMapLayersObserver$.next(layersArranged);
  }
  getMapLayers(): AppLayer[] {
    var lst: AppLayer[] = [];
    var x = this.appMapLayersObserver$.value;
    if (x)
      x.forEach(laygr => {
        if (laygr)
          if (laygr instanceof AppLayerGroup) {
            (laygr as AppLayerGroup).layers.forEach(lay => {
              lst.push(lay as AppLayer);
            });
          }
          else
            lst.push(laygr as AppLayer);
      });
    return lst;
  }
  getMapGroupsLayers(): AppLayerGroup[] {
    var lst: AppLayerGroup[] = [];
    var x = this.appMapLayersObserver$.value;
    x.forEach(laygr => {
      if (laygr instanceof AppLayerGroup) {
        lst.push(new AppLayerGroup({
          Id: laygr.Id,
          name: laygr.name,
          visible: laygr.visible,
          Description: laygr.Description,
          layers: []
        }))
      }
    });
    return lst;
  }
  removeLayerfromMap(vmapservice: VMapService, layer: AppLayer) {
    try {
      vmapservice.map.removeLayer(layer.layer);
    } catch (errrrr) {
      console.log(errrrr);
    }
    try {
      for (var index = 0; index < vmapservice.appMapLayersObserver$.value.length; index++) {


        if (vmapservice.appMapLayersObserver$.value[index].Id == layer.Id) {
          vmapservice.appMapLayersObserver$.value.splice(index, 1);
          vmapservice.appMapLayers = vmapservice.appMapLayersObserver$.value;
          vmapservice.appMapLayersObserver$.next(vmapservice.appMapLayers);
          return;
        }
      }
    } catch (errrrr) {
      console.log(errrrr);
    }
  }



}