import Map from 'ol/Map';
import { Layer } from './../../GIS_Component/search-employee/layerconfigService';
import { WFS } from "ol/format";
import GeoJSON from 'ol/format/GeoJSON';
import {
  equalTo as equalToFilter,
  like as likeFilter,
  and as andFilter
} from 'ol/format/filter';
import { tile as tileStrategy } from 'ol/loadingstrategy';
import { createXYZ } from 'ol/tilegrid';
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import { transformExtent } from "ol/proj.js";
import { Injectable } from '@angular/core';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { Observable, throwError } from 'rxjs';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { Router } from '@angular/router';
import { Style, Stroke, Fill, Icon } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { HttpClient } from '@angular/common/http';
import { EPSGList } from "app/GeoHub/GIS-Shared-Lib/const/Const.config";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ArcGisSymbol, DrawingInfo, DefaultSymbol } from '../interface/arcgis.data.mode.interfacel';
import { AppConfirmService } from 'app/shared/services/app-confirm/app-confirm.service';
import { GISserverTypes, layerTypesEnum, ArcGIS_ServiceType, GisServerCategory, SearchOperatorsTypesEnum } from "../GeoEnums";
import { LoadDataOptions } from "../Model/GeoData.model.class";
import { DrawStyleOptions } from "app/GeoHub/GIS_Component/Draw/draw.interface";
import { AppLayer } from "../interface/Layers-Maps.interface";
import { map, catchError } from "rxjs/operators";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { WebApiResult } from "app/shared/models/apiresult";
import { environment } from "environments/environment";
import EsriJSON from "ol/format/EsriJSON";
import VectorSource from "ol/source/Vector";
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import { IUser } from "app/shared/models/user.model";
 import { Point } from "ol/geom";
import { getVectorContext } from "ol/render";
import { easeOut, easeIn } from "ol/easing";
import { unByKey } from "ol/Observable";
import GeometryType from 'ol/geom/GeometryType';
import { SearchParams } from 'app/BusinessWidgets/Facility-Managment/Facility-Managment-Report.interface';
import { WriteTransactionOptions } from 'ol/format/WFS';
import { SearchResultLayer } from '../shared/Model/GeoData.interface';
import { WebLayer } from '../interface/WebMap.interface';
import { SearchLayerItem } from 'app/GeoHub/GIS_Component/GeoSearch/searchWidget.interface';
@Injectable({
  providedIn: 'root'
})
export class GisAppSharedService {
  constructor(
    private ngxXml2jsonService: NgxXml2jsonService,
    private http: HttpClient,
    public jwtAuth: JwtAuthService,
    private snack: MatSnackBar,
    private router: Router,
    private confirmService: AppConfirmService,

  ) {


    var eppsgs: any[] = [];


    EPSGList.forEach(epsg => {
      if (epsg.proj) {
        var e = [];
        e.push(epsg.Value);
        e.push(epsg.proj);
        eppsgs.push(e);
      }
    });
    proj4.defs(
      eppsgs
    );
    register(proj4);

  }
  transactWFS(mode: "insert" | "update" | "delete", feature: Feature, wfsSetting, serverurl) {
    


    let promise = new Promise((resolve, reject) => {
      var formatWFS = new WFS({
        featureNS: wfsSetting.featureNS,
        featureType: wfsSetting.featureType,
        schemaLocation: wfsSetting.schemaLocation
      });
      var node;


      var srsName = 'EPSG:32639';
      var formatGML: WriteTransactionOptions = {
        featureNS: wfsSetting.featureNS,
        featureType: wfsSetting.featureType,
        gmlOptions: {
          "srsName": srsName
        },
        featurePrefix: "",
        nativeElements: null,
        version: "1.1.0",
        "srsName": srsName
      };


      switch (mode) {
        case "insert":
          node = formatWFS.writeTransaction([feature], null, null, formatGML);
          break;
        case "update":
          node = formatWFS.writeTransaction(null, [feature], null, formatGML);
          break;
        case "delete":
          node = formatWFS.writeTransaction(null, null, [feature], formatGML);
          break;
      }

      var str = new XMLSerializer().serializeToString(node);
      var data = str.replace(
        "feature:" + wfsSetting.featureType,
        wfsSetting.featureNS +
        ":" +
        wfsSetting.featureType
      );
      
      var uril =
        fetch(serverurl, {

          method: 'POST',
          headers: {
            "Content-Type": "text/xml"
          },

          body: data

        })
          .then(response => {
            return response.text();
          }).catch(error => {
            console.log(error);

          })
          .then((Featuresresponse: string) => {

            resolve(this.processXML(Featuresresponse));

          });


    }).catch(error => {

      console.log(error);
      throw error;
    });
    return promise;



  }

  public CreateReport(searchParams: SearchParams) {

    var URL = "";
    let CQLFILTERS: string = "";

    URL = `${searchParams.serverWfsURL}?service=WFS&version=1.0.0&request=GetFeature&typeName=${searchParams.LayerName}&maxFeatures=5000&outputFormat=application/json` + "&SRSNAME=" + environment.mapDefaultProjection + "&CRS=" + environment.mapDefaultProjection + "&SRS=" + environment.mapDefaultProjection;

    if (searchParams.Fields && searchParams.Fields.length > 0) {
      CQLFILTERS = ""
      var count = 0;
      searchParams.Fields.forEach(param => {
        count++;
      var mappedparm = this.getValueCondition(param.Value, param.Condition);

        CQLFILTERS = CQLFILTERS + (param.DataType == "string" ? "strToLowerCase(" + param.Field + ")" : param.Field) + param.Condition + " " + param.Value + " ";
        if (count < searchParams.Fields.length)
          CQLFILTERS = CQLFILTERS + " " + searchParams.Grouping;
      });
      CQLFILTERS = "&CQL_FILTER=" + CQLFILTERS;

    }

    URL = encodeURI(URL + CQLFILTERS);
    
    return this.CreatePromiseRequest(URL);
  }

  Style_from_String(string_style: string) {

    return null;
  }
  Get_layer_Server_Category(ServerType: GISserverTypes): GisServerCategory {
    var wms = [
      GISserverTypes.geoserver,
      GISserverTypes.qgis,
      GISserverTypes.WFS,
      GISserverTypes.WFS_realtime,
      GISserverTypes.carmentaserver,
      GISserverTypes.mapserver,
      GISserverTypes.WMS,
      GISserverTypes.WMTS
    ]

    var ARCGIS = [
      GISserverTypes.ImageArcGISRest,
      GISserverTypes.arcgis,
      GISserverTypes.arcgisFeatrueLayer
    ]
    if (wms.includes(ServerType))
      return GisServerCategory.WMS;
    else if (ARCGIS.includes(ServerType))
      return GisServerCategory.ArcGIS;

  }
  getDefaultmap() {
    var applayer = new AppLayer({
      Id: "APPbasemap_default",
      name: "OSM",
      Opacity: 100, LayerType: layerTypesEnum.OSM,
      ServerType: GISserverTypes.OSM,
      serverCategory: this.Get_layer_Server_Category(GISserverTypes.OSM),
      url: null,
      LAYERS: null,
      Description: "OSM",
      layer: new TileLayer({
        source: new OSM()
      })
    }
    );


    return applayer;
  }

  getDefaultMapCenter() {

    return this.jwtAuth.getDefaultMapCenter();
  }
  CreateDefaultBasemap() {
    var temparray = new AppLayer({
      Id: "APPbasemap_default",
      name: "OSM", LayerType: layerTypesEnum.OSM,
      Opacity: 100,
      ServerType: GISserverTypes.OSM,
      serverCategory: GisServerCategory.OSM,
      url: null,
      LAYERS: null,
      Description: "OSM",
      layer: new TileLayer({
        source: new OSM()
      })
    }
    );
    return temparray;
  }
  showSnakeMessage(message: string) {
    this.snack.open(message, 'OK', { duration: 6000 })
  }
  ShowMessage(title: string, message: string) {
    this.confirmService.showmessage(title, message);
  }

  showWarn(title: string, message: string) {
    this.confirmService.showWarn(title, message);
  }
  confirm_Message(title: string, message: string) {
    return this.confirmService.showconfirm(title, message);
  }
  // getToken() {
  //   return this.jwtAuth.ls.getItem("JWT_TOKEN");
  // }
  getUser(): IUser {
    return this.jwtAuth.ls.getItem("MdaHub_APP_USER");
  }
  getservertype(typeNumber: GISserverTypes): string {
    switch (typeNumber) {
      case GISserverTypes.OSM:
        return "OSM";
      case GISserverTypes.XYZ:
        return "XYZ";
      case GISserverTypes.GraphicLayer:
        return "GraphicLayer";
      case GISserverTypes.ImageArcGISRest:
        return "ImageArcGISRest";
      case GISserverTypes.NONE:
        return "NONE";
      case GISserverTypes.arcgis:
        return "arcgis";
      case GISserverTypes.csv:
        return "csv";
      case GISserverTypes.excel:
        return "excel";
      case GISserverTypes.geojson:
        return "geojson";
      case GISserverTypes.geopackage:
        return "geopackage";
      case GISserverTypes.geoserver:
        return "geoserver";
      case GISserverTypes.google:
        return "google";
      case GISserverTypes.mssql:
        return "mssql";
      case GISserverTypes.postgis:
        return "postgis";
      case GISserverTypes.sde:
        return "sde";
      case GISserverTypes.qgis:
        return "qgis";
      case GISserverTypes.shapefile:
        return "shapefile";
      case GISserverTypes.txt:
        return "txt";
      case GISserverTypes.carmentaserver:
        return "carmentaserver";
      case GISserverTypes.mapserver:
        return "mapserver";
    }
  }
  getMapDataSourceTypeString(typeNumber: GISserverTypes): string {
    switch (typeNumber) {
      case GISserverTypes.OSM:
        return "OSM";
      case GISserverTypes.XYZ:
        return "XYZ";
      case GISserverTypes.GraphicLayer:
        return "GraphicLayer";
      case GISserverTypes.ImageArcGISRest:
        return "ImageArcGISRest";
      case GISserverTypes.NONE:
        return "NONE";
      case GISserverTypes.arcgis:
        return "arcgis";
      case GISserverTypes.csv:
        return "csv";
      case GISserverTypes.excel:
        return "excel";
      case GISserverTypes.geojson:
        return "geojson";
      case GISserverTypes.geopackage:
        return "geopackage";
      case GISserverTypes.geoserver:
        return "geoserver";
      case GISserverTypes.google:
        return "google";
      case GISserverTypes.mssql:
        return "mssql";
      case GISserverTypes.postgis:
        return "postgis";
      case GISserverTypes.sde:
        return "sde";
      case GISserverTypes.qgis:
        return "qgis";
      case GISserverTypes.shapefile:
        return "shapefile";
      case GISserverTypes.txt:
        return "txt";
      case GISserverTypes.carmentaserver:
        return "carmentaserver";
      case GISserverTypes.mapserver:
        return "mapserver";
      case GISserverTypes.arcgisFeatrueLayer:
        return "arcgisFeatrueLayer";
    }
  }
  getdomainName(url) {
    var extractDomain;
    try {
      var urlPattern = /^(?:https?:\/\/)?(?:w{3}\.)?([a-z\d\.-]+)\.(?:[a-z\.]{2,10})(?:[/\w\.-]*)*/;
      var domainPattern = url.match(urlPattern);
      extractDomain = domainPattern[1];
    }
    catch {
      extractDomain = url;
    }
    return extractDomain;
  }
  getStyleFromEsriStyle(symbol: ArcGisSymbol): Style {
    var style: Style = new Style()
    if (symbol == undefined)
      return this.getOlstyle(new DrawStyleOptions({}));
    switch (symbol.type) {
      case "esriSLS": {
        return this.getOlstyle(new DrawStyleOptions({
          colorStroke: this.getColorfromArcGisColor(symbol.color),
          strokeWidth: symbol.width,
          colorFill: this.getColorfromArcGisColor(symbol.color),
          lineDash: symbol.style == "esriSLSSolid" ? null : [5, 5]
        }));
        break;
      }
      case "esriSFS": {
        return this.getOlstyle(new DrawStyleOptions({
          colorStroke: symbol.outline ? this.getColorfromArcGisColor(symbol.outline.color) : this.getColorfromArcGisColor(symbol.color),
          strokeWidth: symbol.outline ? symbol.outline.width : symbol.width,
          colorFill: this.getColorfromArcGisColor(symbol.color),
          lineDash: symbol.outline ? (symbol.outline.type == "esriSLSSolid" ? null : [5, 5]) : (symbol.style == "esriSLSSolid" ? null : [5, 5])
        }));
        break;
      } case "esriSMS": {
        return this.getOlstyle(new DrawStyleOptions({
          colorStroke: this.getColorfromArcGisColor(symbol.color),
          strokeWidth: symbol.width,
          colorFill: this.getColorfromArcGisColor(symbol.color),
          lineDash: symbol.style == "esriSLSSolid" ? null : [5, 5]
        }));
        break;
      } case "esriPMS": {

        return new Style({
          image: new Icon({
            anchor: [symbol.xoffset, symbol.yoffset],
            size: [symbol.width * 1.5, symbol.height * 1.5],

            src: symbol.imageData ? ('data:image/png;base64,' + symbol.imageData) : "./assets/mapicons/yello.png"

          })
        });
        break;
      }
      default: {
        this.getOlstyle(new DrawStyleOptions({}));
        break;
      }


    }
  }
  getColorfromArcGisColor(color: number[]) {
    return "rgba(" + color[0].toString() + "," +
      color[1].toString() + "," +
      color[2].toString() + "," +
      (color[3] / 255).toString() + ")"
  }
  GetDefaultStyle(): Style {
    var options = new DrawStyleOptions({});
    return new Style({
      stroke: new Stroke({
        color: options.colorStroke,
        width: options.strokeWidth,
        lineDash: options.lineDash
      }),
      fill: new Fill({
        color: options.colorFill
      }),
      image: new CircleStyle({
        fill: new Fill({
          color: options.colorFill
        }),
        stroke: new Stroke({
          color: options.colorStroke,
          width: options.strokeWidth
        }),
        radius: options.pointSize
      })
    });
  }
  getOlstyle(options: DrawStyleOptions): Style {
    return new Style({
      stroke: new Stroke({
        color: options.colorStroke,
        width: options.strokeWidth,
        lineDash: options.lineDash
      }),
      fill: new Fill({
        color: options.colorFill
      }),
      image: new CircleStyle({
        fill: new Fill({
          color: options.colorFill
        }),
        stroke: new Stroke({
          color: options.colorStroke,
          width: options.strokeWidth
        }),
        radius: options.pointSize
      })
    });
  }
  loadURL(url) {
    var self = this;
    return Observable.create(function (observer) {
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function (response) {
        if (this.readyState == 4 && this.status == 200) {
          observer.next(this.response);
          observer.complete();
        }
      };
      xhttp.onerror = function (error) {
        observer.next(error);
        observer.complete();
      };
      xhttp.open("GET", url, true);
      xhttp.send()
    });
  }
  Get_WMS_Server_Details(dataConnector: LoadDataOptions, url) {



    var self = this;
    return Observable.create(function (observer) {
      var xhttp = new XMLHttpRequest();
      xhttp.onloadend = function (response) {

        observer.next(self.process_WMS_Server_Response(this.response, dataConnector.url, dataConnector.ServerType));
        observer.complete();

      };
      xhttp.onreadystatechange = function (response) {
        if (this.readyState == 4 && this.status == 200) {

        }
        else if (this.readyState == 4 && this.status == 404) {
          self.confirmService.showmessage("Loading web maps", 'Wrong URL ');

          observer.next("Error Loading");
          observer.complete();

        }
      };
      xhttp.onerror = function (error) {
        observer.next(error);
        observer.complete();
      };
      xhttp.open("GET", url, true);
      xhttp.send()
    });
  }
  getUniqueID() {
    return '_' + Math.random().toString(36).substr(2, 9);
  };
  Color_colorTo_rgba(color: object) {
    return ("rgba(" +
      color["r"] +
      "," +
      color["g"] +
      "," +
      color["b"] +
      "," +
      color["a"] +
      ")")
  }
  Color_Hex_to_rgba_Object(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return { r: (c >> 16) & 255, g: (c >> 8) & 255, b: c & 255, a: 0.7 }
    }
    return { r: 255, g: 255, b: 255, a: 0.7 };
  }





  process_WMS_Server_Response(serverresult: string, url: string, ServerType: GISserverTypes): SearchResultLayer[] {
    var self = this;

    try {
      let LayersResults: SearchResultLayer[] = [];
      var doc = (this.processXML(serverresult) as any);
      var responseResult: any = doc.WMS_Capabilities.Capability.Layer.Layer;
      var copyrightText: string = "";
      try {
        copyrightText = doc.WMS_Capabilities.Capability.Layer.Abstract;
      }
      catch (ex) {

      }
      var res_Layer: SearchResultLayer = new SearchResultLayer();
      res_Layer.Url = url.split('?')[0];
      res_Layer.Owner = self.getdomainName(url);
      res_Layer.CopyrightText = copyrightText;

      // var QgisLayer;
      // if (doc.WMS_Capabilities["@attributes"]["xmlns:qgs"]) {


      //   res_Layer.Name = doc.WMS_Capabilities.Service.Title;

      //   res_Layer.ServerType = GISserverTypes.qgis;

      //   res_Layer.LAYERS = [];

      //   //  res_Layer.LAYERS = layersNames.length > 0 ? layersNames : [res.Name];
      //   res_Layer.Thumbnail = url.split('?')[0] + "?service=WMS&version=1.3.0&request=GetMap&format=image%2Fpng";

      //   res_Layer.Description = doc.WMS_Capabilities.Service.Title;

      //   res_Layer.Extent = self.getExtent_WMS_from_BoundingBox_to_3857(doc.WMS_Capabilities.Capability.Layer.BoundingBox);
      //   res_Layer.EPSG =environment.mapDefaultProjection;
      //   QgisLayer = res_Layer;

      //   try {
      //     res_Layer.Thumbnail = url.split('?')[0] + "?service=WMS&version=1.3.0&request=GetMap&layers=" + doc.WMS_Capabilities.Capability.Layer.Name + "&bbox=" + res_Layer.Extent[0] + "%2C" + res_Layer.Extent[1] + "%2C" + res_Layer.Extent[2] + "%2C" + res_Layer.Extent[3] + "&width=641&height=768&srs=" + res_Layer.EPSG + "&format=image%2Fpng";
      //   } catch (ex) {
      //     res_Layer.Thumbnail = "./assets/mapsimages/wmslayer.png";
      //   }
      // } else 
      //  {

      //   var res_Layer: SearchResultLayer = new SearchResultLayer();

      //   res_Layer.Name = doc.WMS_Capabilities.Service.Title;
      //   res_Layer.Owner = self.getdomainName(url);
      //   res_Layer.ServerType = GISserverTypes.geoserver;

      //   res_Layer.URL = url.split('?')[0];
      //   res_Layer.Description = doc.WMS_Capabilities.Service.Title;
      //   res_Layer.EPSG = environment.mapDefaultProjection;

      // }


      var layers;
      if (!responseResult.forEach && responseResult.Layer)
        layers = responseResult.Layer;
      else if (responseResult.forEach)
        layers = responseResult;
      else if (doc.WMS_Capabilities["@attributes"]["xmlns:qgs"]) {
        layers = [responseResult];
      }
      var sublayers = [];

      layers.forEach(res => {
        var Thumbnail;
        try {
          Thumbnail = url.split('?')[0] + "?service=WMS&version=1.3.0&request=GetMap&layers=" + res.Name + "&bbox=" + res.BoundingBox[0]["@attributes"]["minx"] + "%2C" + res.BoundingBox[0]["@attributes"]["miny"] + "%2C" + res.BoundingBox[0]["@attributes"]["maxx"] + "%2C" + res.BoundingBox[0]["@attributes"]["maxy"] + "&width=641&height=768&srs=" + res.BoundingBox[0]["@attributes"]["CRS"] + "&format=image%2Fpng";
        } catch (ex) {
          Thumbnail = "./assets/mapsimages/wmslayer.png";
        }
        try {
          let owner: string = self.getdomainName(url);
          //ContactOrganization ContactPerson
          var abstract: string = res.Title;
          if (res.Abstract instanceof String)
            abstract = res.Abstract;

          var res_Layer: SearchResultLayer = new SearchResultLayer();
          res_Layer.CopyrightText = copyrightText;
          res_Layer.Name = res.Title;
          res_Layer.Owner = owner;
          res_Layer.ServerType = doc.WMS_Capabilities["@attributes"]["xmlns:qgs"] ? GISserverTypes.qgis : GISserverTypes.geoserver;
          res_Layer.Thumbnail = Thumbnail;

          res.url = url;
          if (res_Layer.ServerType == GISserverTypes.qgis)
            res_Layer = this.process_QGIS_LAyer(res, res_Layer);
          else {
            res_Layer = this.process_Geoserver_Layer(res, res_Layer);
          }
          LayersResults.push(res_Layer);
          if (res_Layer.SubLayers && res_Layer.SubLayers.length > 1) {
            res_Layer.SubLayers.forEach(slin => {
              if (slin) {

                var seares: SearchResultLayer = new SearchResultLayer();
                seares = {

                  Id: null,
                  Name: slin.Title,
                  Owner: res_Layer.Owner,
                  Url: res_Layer.Url,
                  ServerType: res_Layer.ServerType,
                  LAYERS: [slin.Name],

                  Thumbnail: url.split('?')[0] + "?service=WMS&version=1.3.0&request=GetMap&layers=" + res.Name + "&bbox=" + res_Layer.Extent[0] + "%2C" + res_Layer.Extent[1] + "%2C" +
                    res_Layer.Extent[2] + "%2C" + res_Layer.Extent[3] + "&width=641&height=768&srs=" + res_Layer.EPSG + "&format=image%2Fpng",
                  EPSG: res_Layer.EPSG,

                  Extent: res_Layer.Extent,
                  LayerType: res_Layer.LayerType


                }

                sublayers.push(seares);
              }
            });


          }
        }
        catch (err) {
          console.log(err);
        }
      });
      sublayers.forEach(s => {
        LayersResults.push(s);
      });


      return LayersResults;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  process_QGIS_LAyer(QgisLayer, SearchResultLayer: SearchResultLayer): SearchResultLayer {
    var self = this;




    SearchResultLayer.Name = QgisLayer.Title;

    SearchResultLayer.ServerType = GISserverTypes.qgis;

    SearchResultLayer.Url = QgisLayer.url.split('?')[0];
    SearchResultLayer.SubLayers = [];

    SearchResultLayer.SubLayers = QgisLayer.Layer;

    SearchResultLayer.LAYERS = [];

    SearchResultLayer.LAYERS.push(QgisLayer.Name);



    if (QgisLayer.CRS && QgisLayer.CRS.length > 0) {
      SearchResultLayer.EPSG = QgisLayer.CRS[0];
      QgisLayer.CRS.forEach(crs => {
        if (environment.mapDefaultProjection.toLowerCase() == crs.toLowerCase())
          SearchResultLayer.EPSG = crs;
      });
    }


    var _Extent = self.getExtent_WMS_from_BoundingBox_to_3857(QgisLayer.BoundingBox);
    SearchResultLayer.Extent = _Extent.extent;
    SearchResultLayer.EPSG = _Extent.EPSG;

    return SearchResultLayer;

  }
  process_Geoserver_Layer(QgisLayer, SearchResultLayer: SearchResultLayer): SearchResultLayer {
    var self = this;


    var Thumbnail;

    let owner: string = self.getdomainName(QgisLayer.url);

    var abstract: string = QgisLayer.Title;
    if (QgisLayer.Abstract instanceof String)
      abstract = QgisLayer.Abstract;



    SearchResultLayer.ServerType = GISserverTypes.geoserver;

    SearchResultLayer.SubLayers = [];
    SearchResultLayer.LAYERS = [];
    SearchResultLayer.Url = QgisLayer.url.split('?')[0];
    SearchResultLayer.LAYERS = [QgisLayer.Name];
    if (QgisLayer.Layer)
      QgisLayer.Layer.forEach(l => {
        SearchResultLayer.SubLayers.push(l.Name);
      });

    SearchResultLayer.Description = abstract;
    SearchResultLayer.EPSG = QgisLayer.CRS;

    SearchResultLayer.CopyrightText = "Owner =   " + owner + " \r\n\r\n  " + abstract + " \r\n\r\n " + SearchResultLayer.CopyrightText;

    var _Extent = self.getExtent_WMS_from_BoundingBox_to_3857(QgisLayer.BoundingBox);
    SearchResultLayer.Extent = _Extent.extent;
    SearchResultLayer.EPSG = _Extent.EPSG;

    return SearchResultLayer;

  }
  getWMSLayerDetails(LayerName: string, serverURL: string) {


    var self = this;

    var requestURL = serverURL + "?service=WMS&request=DescribeLayer&version=1.1.1&layers=" + LayerName;
    return Observable.create(function (observer) {
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function (response) {
        if (this.readyState == 4 && this.status == 200) {

          var doc = (self.processXML(this.response) as any);

          var layers: { typeName?: String, owsType?: String, owsURL?: String, wfsURL?: String }[] = [];

          doc.WMS_DescribeLayerResponse.forEach(result => {
            if (result.LayerDescription) {
              if (result.LayerDescription.forEach) {
                result.LayerDescription.forEach(ld => {
                  if (ld["@attributes"]) {
                    var l = { Name: ld["@attributes"].name, Title: ld["@attributes"].name, owsType: ld["@attributes"].owsType, owsURL: ld["@attributes"].owsURL, wfsURL: ld["@attributes"].wfs };
                    layers.push(l);
                  }
                });
              }
              else {
                if (result.LayerDescription["@attributes"]) {
                  var l = {
                    Name: result.LayerDescription["@attributes"].name,
                    Title: result.LayerDescription["@attributes"].name,
                    owsType: result.LayerDescription["@attributes"].owsType,
                    owsURL: result.LayerDescription["@attributes"].owsURL, wfsURL: result.LayerDescription["@attributes"].wfs
                  };
                  layers.push(l);
                }
              }

            }
          });


          observer.next(layers);
          observer.complete();
        }
        else if (this.readyState == 4 && this.status == 404) {
          self.confirmService.showmessage("Loading web maps", 'Wrong URL ');

          observer.next("Error Loading");
          observer.complete();

        }
      };
      xhttp.onerror = function (error) {
        observer.next(error);
        observer.complete();
      };
      xhttp.open("GET", requestURL, true);
      xhttp.send()
    });

  }
  getArcGIS_layerfromURL(layerURL) {
    var self = this;
    return Observable.create(function (observer) {
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function (response) {
        if (this.readyState == 4 && this.status == 200) {
          var result = JSON.parse(this.response);
          var layerdetails: any = {};
          layerdetails.FeatureDetails = result;
          var cap: string[] = result.capabilities.split(',');
          if (result.singleFusedMapCache == true || result.tileInfo || cap.indexOf("Image") > -1) {
            layerdetails.ArcgisServiceType = ArcGIS_ServiceType.ImageLayer;
            layerdetails.LayerType = layerTypesEnum.ImageLayer_ImageArcGISRest;
          }
          else if (cap.indexOf("Map") > -1) {
            layerdetails.ArcgisServiceType = ArcGIS_ServiceType.ImageLayer;
            layerdetails.LayerType = layerTypesEnum.ImageLayer_ImageArcGISRest;
          }
          else if (result.fields) {
            layerdetails.LayerType = layerTypesEnum.VectorLayer_VectorSource;
            layerdetails.ArcgisServiceType = ArcGIS_ServiceType.FeatureLayer;
          }
          else if (cap.indexOf("Query") > -1) {
            ;
            console.log("Layer is query ");
          }
          layerdetails.owner = self.getdomainName(layerdetails.url);
          layerdetails.owner = layerdetails.owner.toString().indexOf("arcgis.com") ? "ArcGIS Online" : layerdetails.owner;
          layerdetails.queryable = true;
          layerdetails.ServerType = GISserverTypes.arcgis;
          layerdetails.queryable = true;
          layerdetails.copyrightText = result.copyrightText;
          if (result.fields) {
            if (result.extent && result.extent.spatialReference)
              layerdetails.EPSG = ["EPSG:" + result.extent.spatialReference.latestWkid.toString()];
            layerdetails.Description = "Feature  Services capabilities: " + result.capabilities + "  geometry Type: " + result.geometryType
            if (result.extent)
              layerdetails.extent = [result.extent.xmin, result.extent.ymin, result.extent.xmax, result.extent.ymax];
            if (result.extent && result.extent.spatialReference)
              layerdetails.EPSG = ["EPSG:" + result.extent.spatialReference.latestWkid.toString()];
            layerdetails.Thumbnail = "./assets/arcgisdefault.png";
            layerdetails.fields = [];
            result.fields.forEach(f => {
              layerdetails.fields.push(f);
            });
            layerdetails.displayField = result.displayField;
          }
          else {
            layerdetails.FeatureDetails = result;
            layerdetails.EPSG = ["EPSG:" + result.spatialReference.latestWkid.toString()];
            layerdetails.UpdateDate = result.created;
            layerdetails.ArcGISServerName = result.server;
            layerdetails.Description = "Map Documnet Service   Description:    " + result.description + "   service Description:   " + result.serviceDescription;
            layerdetails.extent = [result.initialExtent.xmin, result.initialExtent.ymin, result.initialExtent.xmax, result.initialExtent.ymax];
            if (result.initialExtent && result.initialExtent.spatialReference)
              layerdetails.EPSG = ["EPSG:" + result.initialExtent.spatialReference.latestWkid.toString()];
            layerdetails.Thumbnail = "./assets/mapsimages/arcgismapdocumnt.png";
            layerdetails.fields = [];
            result.layers.forEach(element => {
              layerdetails.fields.push({ name: element.name, alies: element.name })
            });
            layerdetails.subLayers = result.layers
          }
          observer.next(layerdetails);
          observer.complete();
        }
      };
      xhttp.onerror = function (error) {
        observer.next(error);
        observer.complete();
      };
      xhttp.open("GET", layerURL, true);
      xhttp.send()
    });
  }
  processXML(xml: string) {
    const parser = new DOMParser();
    const result = parser.parseFromString(xml, 'text/xml');
    const obj = this.ngxXml2jsonService.xmlToJson(result);
    return obj;
  }
  get_ArcGIS_URL_Details(url) {
    var self = this;
    return Observable.create(function (observer) {
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function (response) {
        if (this.readyState == 4 && this.status == 200) {
          observer.next(JSON.parse(this.response));
          observer.complete();
        }
      };
      xhttp.onerror = function (error) {
        observer.next(error);
        observer.complete();
      };
      xhttp.open("GET", url, true);
      xhttp.send()
    });
  }
  Get_WMS_LayerDetails(url: string, layerID: string, servertype?: GISserverTypes, LAYERS?: string[]) {
    url = url.split('?')[0] + "?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetCapabilities";
    var self = this;
    return Observable.create(function (observer) {
      var dataConnector: LoadDataOptions = new LoadDataOptions({
        ServerType: servertype,
        url: url.split('?')[0] + "?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetCapabilities",

      });

      self.Get_WMS_Server_Details(dataConnector, url).subscribe((response: SearchResultLayer[]) => {
        response.forEach((element) => {
          if (element.Name == layerID) {
            observer.next(element);
            observer.complete(element);
            return element;
          }
        });


      });
    });
  }

  /**
   *
   *
   * @param {string} url
   * @param {string} [layerID]  layer with NAME SPACE  workspace:alyername or qgis server layer in  LAYERS[0]
   * @param {GISserverTypes} [servertype]
   * @param {string[]} [LAYERS]
   * @returns
   * @memberof GisAppSharedService
   */
  Get_LayerDetails(url: string, layerID?: string, servertype?: GISserverTypes, LAYERS?: string[]) {
    // reterave Description of layer a server  and get you server Description in FeatureDetails
    if (servertype == GISserverTypes.arcgis) {
      return this.get_ArcGIS_URL_Details(url);
    }
    else {
      return this.Get_WMS_LayerDetails(url, layerID, servertype, LAYERS);
    }
  }
  getExtent_WMS_from_BoundingBox_to_3857(BoundingBox: any[], toEPSG?: string) {
    // by defulat return extent will be EPSG:3857 if you did not provide EPSG
    var result: any = {};

    if (BoundingBox.length > 0) {

      BoundingBox.forEach(bbox => {
        if (bbox["@attributes"].CRS && (bbox["@attributes"].CRS as string).toUpperCase().indexOf("EPSG") > -1) {
          result.EPSG = bbox["@attributes"].CRS;
          result.extent = [bbox["@attributes"].minx, bbox["@attributes"].miny, bbox["@attributes"].maxx, bbox["@attributes"].maxy];

          try {

            try {


              EPSGList.forEach(epsg => {
                if (epsg.Value == result.EPSG.split(",")[0]) {

                  proj4.defs(epsg.Value, epsg.proj);
                  register(proj4);

                }
              });

            } catch {

            }

            var newext: [number, number, number, number] = [parseFloat(parseFloat(result.extent[0].toString()).toFixed(6))
              , parseFloat(parseFloat(result.extent[1].toString()).toFixed(6))
              , parseFloat(parseFloat(result.extent[2].toString()).toFixed(6))
              , parseFloat(parseFloat(result.extent[3].toString()).toFixed(6))];

            result.extent = transformExtent(newext, result.EPSG.split(",")[0], environment.mapDefaultProjection);
            result.EPSG = environment.mapDefaultProjection;
            return result;
          } catch (err) {
            console.log("Unkown EPSG CODE :" + result.EPSG);
          }
        }
      });

    }
    if (!result.extent) {
      if (BoundingBox && BoundingBox.length > 0 && BoundingBox[BoundingBox.length - 1]["@attributes"])
        result.extent = [BoundingBox[BoundingBox.length - 1]["@attributes"]["minx"], BoundingBox[BoundingBox.length - 1]["@attributes"]["miny"], BoundingBox[BoundingBox.length - 1]["@attributes"]["maxx"], BoundingBox[BoundingBox.length - 1]["@attributes"]["maxy"]];
      result.EPSG = environment.mapDefaultProjection;
    }
    return result;
  }
  Get_Attribute_Table(appLayer: AppLayer, esripageID?: number): any {
    var self = this;
    var userre = {
      // Token: this.getToken(),
      url: ""
    }
    if (appLayer.serverCategory == GisServerCategory.ArcGIS) {
      if (!esripageID)
        esripageID = esripageID ? esripageID : 0;
      userre.url = appLayer.url + '/query/?f=geojson&returnGeometry=true&outFields=*&where=1=1' +
        "&resultOffset=" + (esripageID * 1000) + "&resultRecordCount=1000&inSR=" + environment.mapDefaultProjection.split(':')[1] + '&outFields=*' +
        '&outSR=' + environment.mapDefaultProjection.split(':')[1]
    }
    else if (appLayer.serverCategory == GisServerCategory.WMS) {
      userre.url = appLayer.url.split('?')[0] + "?service=WFS&version=1.3.0&request=GetFeature&typeName=" + appLayer.LAYERS.toString() + "&SRSNAME=" + environment.mapDefaultProjection + "&CRS=" + environment.mapDefaultProjection + "&SRS=" + environment.mapDefaultProjection + "&outputFormat=application%2Fjson";

    }
    else if (appLayer.serverCategory == GisServerCategory.GeoJSON) {
      return new Promise((resolve, reject) => {

        return resolve((appLayer.layer as VectorLayer).getSource().getFeatures());
      });    }
    else {
      return new Promise((resolve, reject) => {

        return resolve("Wrong Request");
      });
    }

    let promise = new Promise((resolve, reject) => {

      fetch(userre.url)
        .then(response => {
          return response.json();
        }).catch(error => {
          console.log(error);
          self.showWarn("Error Loading Data", "Unable to load Attribute Table");
        })
        .then(Featuresresponse => {
          var res;

          if (appLayer.serverCategory == GisServerCategory.WMS)
            res = new GeoJSON().readFeatures(Featuresresponse, {
              featureProjection: environment.mapDefaultProjection
            });
          else if (appLayer.serverCategory == GisServerCategory.ArcGIS)
          res = new GeoJSON().readFeatures(Featuresresponse, {
            featureProjection: environment.mapDefaultProjection
          });
          else {

            self.showWarn("Wrong Request", "Unkown layer type");
          }



          resolve(res);
          return res;

        });
    });
    return promise;

    // return this.http.get(userre.url)
    //   .pipe(
    //     map((res: WebApiResult) => {
    //       if (res.IsSuccess) {

    //         if (appLayer.ServerType == GISserverTypes.geoserver || appLayer.ServerType == GISserverTypes.qgis)
    //           return new GeoJSON().readFeatures(res.data, {
    //             featureProjection: environment.mapDefaultProjection
    //           });
    //         else if (appLayer.ServerType == GISserverTypes.arcgisFeatrueLayer || appLayer.ServerType == GISserverTypes.arcgis)
    //           return (new EsriJSON()).readFeatures(res.data, {
    //             featureProjection: environment.mapDefaultProjection
    //           });
    //         else if (res.data == "Token Expired") {
    //           this.snack.open(res.data, 'OK', { duration: 6000 })
    //           this.router.navigateByUrl("sessions/signin");
    //         }
    //       }
    //       else {
    //         return res;
    //       }
    //     }),
    //     catchError((error) => {
    //       return throwError(error);
    //     })
    //   );
  }
  createArcGISVectorSource(urlorg: string, applayer: AppLayer, drawinginfo: DrawingInfo) {
    var self = this;
    var esrijsonFormat = new EsriJSON();
    var reqyestpage: number = 0;

    var vectorSource = new VectorSource({
      loader: function (extent, resolution, projection) {
        var url = urlorg + '/query/?f=geojson&' +
          'returnGeometry=true&spatialRel=esriSpatialRelIntersects' +
          '&geometry=' + encodeURIComponent('{"xmin":' + extent[0] + ',"ymin":' +
            extent[1] + ',"xmax":' + extent[2] + ',"ymax":' + extent[3] +
            ',"spatialReference":{"wkid":' + environment.mapDefaultProjection.split(':')[1] + '}}') +
          '&geometryType=esriGeometryEnvelope&inSR=' + environment.mapDefaultProjection.split(':')[1] + '&outFields=*' +
          '&outSR=' + environment.mapDefaultProjection.split(':')[1];
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function (response) {
          if (this.readyState == 4 && this.status == 200) {
            // var features: Feature[] = esrijsonFormat.readFeatures(this.response, {
            //   featureProjection: projection
            // });


            let format = new GeoJSON();
            var features:Feature[] = format.readFeatures(this.response);
            try {
              if (features.length > 0)
                if (features[0].getId())
                  if (features[0].getGeometry != null) {
                    if (features.length > 0) {
                      features.forEach(feature => {
                        feature.setStyle(self.SetFeatureStyle(feature, drawinginfo));
                      });
                      vectorSource.addFeatures(features);
                      reqyestpage = reqyestpage + 1;
                      xhttp.open("GET", url + "&resultOffset=" + (reqyestpage * 1000) + "&resultRecordCount=50000", true);
                      xhttp.send();
                      console.log("Features  count page: " + reqyestpage + " :  " + vectorSource.getFeatures().length);
                    }
                  }
            }
            catch (err) {
              console.error(err)
            }
          }
        };
        xhttp.onerror = function (error) {
          self.snack.open('Loading data Error', 'OK', { duration: 2000 })
        };
        xhttp.open("GET", url + "&resultOffset=" + (reqyestpage * 1000) + "&resultRecordCount=50000", true);
        xhttp.send()
      },
      strategy: tileStrategy(createXYZ({ tileSize: 512 }))
    });
    applayer.layer.setSource(vectorSource);
  }
  createArcGISVectorLayer(userlayer: WebLayer) {
    var GraphicLayer = new VectorLayer({
      visible: true,
    });
    var applayer = new AppLayer({
      Id: "new" + userlayer.Name, LayerType: layerTypesEnum.GraphicLayer,
      visible: true,
      LAYERS: null,
      url: userlayer.Source.split("?")[0],
      Opacity: 1,
      serverCategory: GisServerCategory.ArcGIS,
      ServerType: GISserverTypes.arcgisFeatrueLayer,
      name: userlayer.Name,
      Description: userlayer.Name,
      layer: GraphicLayer
    }
    );
    this.get_ArcGIS_URL_Details(userlayer.Source + "?f=json").subscribe(
      (response: any) => {
        this.createArcGISVectorSource(userlayer.Source, applayer, response.drawingInfo);
      }, err => {
        this.confirmService.showmessage("Loading web maps", 'Error Loading data');
      });
    return applayer;
  }
  SetFeatureStyle(feature, drawingInfo: DrawingInfo) {
    let self = this;
    var style: Style;
    if (drawingInfo.renderer.type == "uniqueValue") {
      var field1 = drawingInfo.renderer.field1;
      var field2 = drawingInfo.renderer.field2;
      var uniqueValueInfos = drawingInfo.renderer.uniqueValueInfos;
      uniqueValueInfos.forEach(uv => {
        if ((feature.getProperties()[field1] as String) == (uv.value as String))
          style = self.getStyleFromEsriStyle(uv.symbol);
      });
    }

    else if (drawingInfo.renderer.type == "simple" && drawingInfo.renderer.symbol) {
      style = self.getStyleFromEsriStyle(drawingInfo.renderer.symbol);
    }
    if (style === undefined && drawingInfo.renderer.defaultSymbol)
      style = self.getStyleFromEsriStyle(drawingInfo.renderer.defaultSymbol);

    if (style === undefined && drawingInfo.renderer.defaultSymbol)
      style = self.getStyleFromEsriStyle(drawingInfo.renderer.defaultSymbol);
    if (style === undefined)
      style = self.getOlstyle(new DrawStyleOptions({}));
    feature.setStyle(style);
    return style;
  }


  Get_WFS_Request(appLayer: AppLayer) {


    // generate a GetFeature request
    var featureRequest = new WFS().writeGetFeature({
      srsName: environment.mapDefaultProjection,
      featureNS: appLayer.url,
      featurePrefix: appLayer.LAYERS[0],
      featureTypes: appLayer.LAYERS,
      outputFormat: 'application/json',
      // filter: andFilter(
      //   likeFilter('name', 'Mississippi*'),
      //   equalToFilter('waterway', 'riverbank')
      // )
    });

    return Observable.create(function (observer) {
      fetch(appLayer.url, {
        method: 'POST',
        body: new XMLSerializer().serializeToString(featureRequest)
      }).then(function (response) {
        return response.json();
      }).then(function (json) {
        return new GeoJSON().readFeatures(json);

      });
    }

    );
  }
  getValueCondition(value: string, cond: SearchOperatorsTypesEnum) {
    var normlizedValue = value.toString().toLowerCase().trim();
    switch (cond) {
      case SearchOperatorsTypesEnum.Contain:
        return { "value": "'%" + normlizedValue + "%'", condition: " ILIKE " }
      case SearchOperatorsTypesEnum.StartWith:
        return { "value": " '%" + normlizedValue + "' ", condition: " ILIKE " }
      case SearchOperatorsTypesEnum.EndWith:
        return { "value": " '" + normlizedValue + "%' ", condition: " ILIKE " }
      case SearchOperatorsTypesEnum.NotContain:
        return { "value": " '%" + normlizedValue + "%' ", condition: " NOT ILIKE " }
      default:
        return { "value": " '" + normlizedValue + "' ", condition: " " + cond.toUpperCase() + " " }
    }

  }

  createSearchURL(searchlayer: SearchLayerItem, conditiontext) {
    var url;
    var esripageID = 0;
    if (this.Get_layer_Server_Category(searchlayer.ServerType) === GisServerCategory.WMS) {

      url = `${searchlayer.Url}/wms?service=WFS&version=1.0.0&request=GetFeature&typeName=${searchlayer.LayerId}` + "&SRSNAME=" + environment.mapDefaultProjection + "&CRS=" + environment.mapDefaultProjection + "&SRS=" + environment.mapDefaultProjection + "&outputFormat=application/json";

      var CQL_FILTER: string = "";
      searchlayer.WhereField.forEach(f => {
        if (CQL_FILTER.length > 0)
          CQL_FILTER = CQL_FILTER + " OR  "
        CQL_FILTER = CQL_FILTER + f + "  LIKE " + "  '%" + conditiontext + "%'  "

      });

      if (CQL_FILTER.length > 0)
        url = url + "&CQL_FILTER= " + CQL_FILTER;
      else
        url = url + " ";

      var qgisfilter: string = "";
      searchlayer.WhereField.forEach(f => {
        if (qgisfilter.length > 0)
          qgisfilter = qgisfilter + "  OR   "
        qgisfilter = qgisfilter + searchlayer.LayerId + ':"' + f + '"' + " isLike " + "'%" + conditiontext + "%'  ";

      });


      if (qgisfilter.length > 0) {
        url = url + qgisfilter;
        qgisfilter = "&FILTER " + qgisfilter;
      }
      else
        url = url;

    }
    else if (this.Get_layer_Server_Category(searchlayer.ServerType) === GisServerCategory.ArcGIS) {

      url = searchlayer.Url + '/query/?f=json&returnGeometry=true&outFields=*' +
        "&resultOffset=" + (esripageID * 1000) + "&resultRecordCount=1000&inSR=" + environment.mapDefaultProjection.split(':')[1] + '&outFields=*' +
        '&outSR=' + environment.mapDefaultProjection.split(':')[1] + "&where="

      var where = "";
      searchlayer.WhereField.forEach(f => {
        if (where.length > 0)
          where = where + "  or  "
        where = where + f + "  Like " + "  '%" + conditiontext + "%'  "

      });
      if (where.length > 0)
        url = url + where;
      else
        url = url + " 1=1 ";
    }



    return url;


  }


  CreatePromiseRequest(url): any {

    let promise = new Promise((resolve, reject) => {

      var uril =
        fetch(url)
          .then(response => {
            return response.text();
          }).catch(error => {
            console.log(error);

          })
          .then(Featuresresponse => {

            resolve(Featuresresponse);

          });


    }).catch(error => {

      console.log(error);
      throw error;
    });
    return promise;
  }


  addHighlightGraphic(feature: Feature, GraphicLayer: VectorLayer, map: Map, ZoomTo?: boolean) {

    var featureHighlight = feature;;

    var geo;
    if (feature.getGeometry().getType() == GeometryType.POINT) {

      geo = (feature.getGeometry() as Point).getCoordinates();
    }
    else {

      geo = feature.getGeometry().getExtent();
    }

    featureHighlight.setStyle(this.getOlstyle(new DrawStyleOptions({ colorStroke: "rgba(16, 63, 85,0.8)", strokeWidth: 2, colorFill: "rgba(16, 63, 85,0.2)", pointSize: 7 })));
    GraphicLayer.getSource().addFeature(featureHighlight);
    this.flash(feature, GraphicLayer, map);
    if (ZoomTo)
      map.getView().fit(geo);
    if (map.getView().getZoom() > 24)
      map.getView().setZoom(24);
    return featureHighlight;
  }
  flash(feature, GraphicLayer, map, zoomTo?: boolean) {

    var geo;
    if (feature.getGeometry().getType() == GeometryType.POINT) {

      geo = (feature.getGeometry() as Point).getCoordinates();
    }
    else {

      geo = feature.getGeometry().getExtent();
    }
    if (zoomTo)
      map.getView().fit(geo);
    if (map.getView().getZoom() > 24)
      map.getView().setZoom(24);
    var self = this;
    var start = new Date().getTime();
    var listenerKey = GraphicLayer.on('postrender', animate);
    function animate(event) {
      var vectorContext = getVectorContext(event);
      var frameState = event.frameState;
      var flashGeom = feature.getGeometry().clone();
      var elapsed = frameState.time - start;
      var elapsedRatio = elapsed / 3000;
      // radius will be 5 at start and 30 at end.
      var radius = easeOut(elapsedRatio) * 25 + 5;
      var opacity = easeOut(1 - elapsedRatio);
      var opacityF = easeIn(0.7 - elapsedRatio);
      var style = new Style({
        stroke: new Stroke({
          color: 'rgba(16, 63, 85, ' + opacity + ')',
          width: 3.25 + opacity
        }),
        fill: new Fill({
          color: 'rgba(16, 63, 85, ' + opacityF + ')',
        }),
        image: new CircleStyle({
          radius: radius,
          stroke: new Stroke({
            color: 'rgba(16, 63, 85,' + opacity + ')',
            width: 0.25 + opacity
          })
        })
      });
      vectorContext.setStyle(style);
      vectorContext.drawGeometry(flashGeom);
      if (elapsed > 3000) {
        unByKey(listenerKey);
        return;
      }
      // tell OpenLayers to continue postrender animation
      map.render();
    }
  }

  FeaturesToArray(Features: any[]) {

    var data = [];
    var keys = [];



    Features.forEach(f => {
      var item = {};
      for (const key of Object.keys(f.getProperties())) {
        item[key] = f.getProperties()[key];
      }
      item["ObjectID"] = f.getId().split('.')[1];
      data.push(item);
    });
    return data;
  }
}
