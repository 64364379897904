import { environment } from './../../../../environments/environment';
import { Component, OnInit, ElementRef, SimpleChanges, ViewChild } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';

import { VMapService } from 'app/GeoHub/GIS_Component/map-componants/v-map-component/v-map.service';
 
import { FormControl } from '@angular/forms';
import { NotificationService } from '../notifications/notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { ChatUser, ChatMsg } from './message-chat.interface';
import { NotificationTypeEnum } from '../notifications/Notification.interface';
import { MessageChatService } from './message-chat.service';
import { ChatToUserComponent } from './chat-to-user/chat-to-user.component';
import { HubConnectionState } from '@microsoft/signalr';
import { AES } from 'crypto-ts';
 @Component({
  selector: 'app-message-chat',
  templateUrl: './message-chat.component.html',
  styleUrls: ['./message-chat.component.scss']
})
export class MessageChatComponent implements OnInit {
  currentChats: ChatUser[] = [];
  ChatUser: ChatUser[] = [];
  GroupNotifications = [];
  selected = new FormControl(0);
  public homeview: boolean = true;
  public isMminimized: boolean = false;
  dataSource = new MatTableDataSource<any>();
  CurrnetSelectedUser: any;
  public index: number;
  @ViewChild("chatctrl") chatctrl: ChatToUserComponent;
  isVisible: boolean = false;
  checkinterval: NodeJS.Timeout;
  constructor(
    public messageChatService: MessageChatService,
    public notificationService: NotificationService,
    private snack: MatSnackBar,
    private jwtAuth: JwtAuthService,
    private elementRef: ElementRef,
    public vmapService: VMapService,
  ) { }
  ngOnInit(): void {
    try{

   
const sub= this.jwtAuth.user$.subscribe(res=>{
  if(this.jwtAuth.isLoggedIn())
  this.getOnlineUsers(this);
  

})
}catch{}
    this.notificationService.onlineusers.forEach(user => {
      user.chats = [];
      this.currentChats.push(user);

    });
    this.setupChatSubscriber();
    if (this.jwtAuth.isLoggedIn()) {
      this.getOnlineUsers(this);
      this.SetupUsersCheck();
    }

  }
  setupChatSubscriber() {
    this.notificationService.newMessageObserver$.subscribe(notification => {
      if (notification && (notification.NotificationType == NotificationTypeEnum.messageChattext || notification.NotificationType == NotificationTypeEnum.messageChatGeo)) {

   
        if (notification.Message.SenderUserId != this.jwtAuth.getUser().username) {
          this.snack.open(
            "Geo Message recived from   " + notification.Message.SenderUserId,
            "Dismiss",
            { horizontalPosition: "right", verticalPosition: "top" }
          );
        }

        this.index = this.currentChats.findIndex(element => element.UserName === notification.Message.SenderUserId);
        var _user: ChatUser = {};
        if (this.index == -1)
          this.notificationService.onlineusers.forEach(user => {
            if (notification.Message.SenderUserId == user.UserName) {
              user.onlinestatus = true;
              this.currentChats.push(user);
              _user = user;
            }

          });
        else {

          this.currentChats[this.index].onlinestatus = true;
          _user = this.currentChats[this.index];
        }

        this.index = this.currentChats.findIndex(element => element.UserName === notification.Message.SenderUserId);

        if (this.index > -1) {
          if (!this.currentChats[this.index].chats)
            this.currentChats[this.index].chats = [];
          this.currentChats[this.index].onlinestatus = true;
         
          var k=notification.Message.SenderUserId+ environment.encKey;
          

          var CryptoTS = require("crypto-ts");
          notification.Message.decrptedtextMessage = AES.decrypt(notification.Message.EncryptedMessage,k).toString(CryptoTS.enc.Utf8);
          notification.Message.DecrptedGeoMsg =notification.Message.GeoMsgEncrypted? JSON.parse( AES.decrypt(notification.Message.GeoMsgEncrypted,k).toString(CryptoTS.enc.Utf8)):null;

          this.currentChats[this.index].chats.push(notification.Message);



          this.currentChats[this.index].chats.forEach(msg => {
            msg.IsSeen=3;
          });

        }

        if (this.chatctrl && notification.Message.MapControl && this.chatctrl.mapControlchecked) {
          this.vmapService.map.getView().fit(notification.Message.DecrptedGeoMsg.extent);
        }


      } else if (notification && notification.NotificationType == NotificationTypeEnum.UserJoinOnline) {
        this.getOnlineUsers(this);
      } else if (notification && notification.NotificationType == NotificationTypeEnum.OnlineUsers) {

        this.notificationService.onlineusers.forEach(user => {
          user.chats = [];
          var exist = false;
          this.currentChats.forEach(element => {
            if (element.UserName == user.UserName)
              exist = true;

          });

          if (!exist)
            this.currentChats.push(user);

          this.currentChats.forEach(chatuser => {
            var exist = false;
            this.notificationService.onlineusers.forEach(element => {
              if (element.UserName == chatuser.UserName)
                exist = true;

            });
            if (exist)
              chatuser.onlinestatus = exist;
          });

        });

      }


    });

  }

  SetupUsersCheck() {
    var self = this;
    this.checkinterval = setInterval(function () {
      
      self.getOnlineUsers(self);
    }, 20000);
  }
  ShowhideWindow() {
    this.isVisible = !this.isVisible;
    this.getOnlineUsers(this);
   this.isMminimized=!this.isVisible;

  }
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
  }
  getOnlineUsers(self) {
    try{
    if (self.notificationService.hubConnection && self.notificationService.hubConnection.state == HubConnectionState.Connected)
      self.notificationService.hubConnection
        .send("getallonline", 23)
        .then((eeee) => {
        })
        .catch((err) => {
          self.snack.open("Error in getting online users ", "OK", { duration: 2000 });
          console.log(err);
        });
      }catch{}
  }
  widgetClosed() {

  }
  selectTO(user: ChatUser) {
    if (user !== null) {
      var index = this.currentChats.findIndex(element => element.UserName === user.UserName)
      if (index === -1) {

        user.chats = [];
        this.currentChats.push(user);
        this.CurrnetSelectedUser = user;

      }
      else {
        if (!user.chats)
          user.chats = [];
        this.CurrnetSelectedUser = user;
      }
      this.messageChatService.Chathomeview = false;
    }
    else {
      this.currentChats = []
      this.messageChatService.Chathomeview = true;
    }
  }

}
