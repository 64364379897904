<h1 matDialogTitle style=" margin: -14px; " class="mb-05">Data Source projection EPSG</h1>
<hr style="    margin-top: -9px;  margin-left: -18px" color="accent">
<div mat-dialog-content class="mb-1"  >
    <br>
<h3> Current Map projection/ <strong>{{currentmapprojection}}</strong></h3>
 
<br>

<mat-form-field  class="full-width" appearance="outline" *ngIf="showlayername">
    <mat-label>Layer name: </mat-label>
    <input matInput  [(ngModel)]="data.layername"  >


</mat-form-field>

<mat-form-field  class="full-width" appearance="outline">
        <mat-label>Select EPSG: </mat-label>
        <mat-select class="   " [(value)]="SelectedEPSG">

            <mat-option *ngFor="let epsg of EPSGList" [value]="epsg">{{epsg.Name}}: {{epsg.Value}}</mat-option>
        </mat-select>


   </mat-form-field>
</div>

<div mat-dialog-actions align="end">
    <button 
    type="button"
    color="primary"
    mat-flat-button 
    (click)="close()" >Confirm</button>
</div>