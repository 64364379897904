<mat-expansion-panel *ngIf="Incdents.length>0"   style="margin: 10px !important; padding: 5px !important;">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-icon mat-list-icon style="float: left; padding-right: 10px;"><img style="max-width: 24px; position: absolute; bottom: 15px;" src="{{incidenticon}}" /></mat-icon>
            <h3 style="float: left;">Active Incidents</h3>

            <span style="float: right; right: 20px; left: auto;" class="notification-number mat-bg-warn">{{ Incdents.length }}</span>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-nav-list class="notification-list" role="list" style="max-height: 500px !important; overflow-y: auto !important;">
        <div *ngFor="let item of Incdents" class="notific-item" style="margin: 10px;">
            <div >
                <span mat-line  class="m-05 full-width "  >
                  {{ item.properties.OpenDateTime | date: 'dd/MM/yyyy hh:mm'}}  
                  <span (click)="zoomToAlert(item)" style="  cursor: pointer; text-align: right; float: right;"  ><mat-icon color="warn" >zoom_in</mat-icon></span>
                </span>
   
                <p color="primary" style="margin: 2px;" mat-line class=" .m-033 full-width" ><strong>{{ item.properties.IncidentTypeName    +' :  '+  item.properties.SeverityName }} </strong>
                </p>
               
                
               
             <p  mat-line class=" .m-033 full-width " style="margin: 2px;" >{{  item.properties.Description    }}
             </p>  
               
     
        
            </div>
            <hr>
        </div>
    </mat-nav-list>
    <div class="text-center mt-1" *ngIf="Incdents.length">
        <small>
    <div class="full-width" style="cursor: pointer;" (click)="removeNotificationsGroup(group)">
      Clear All Incidents Notifications
    </div>
  </small>
    </div>
</mat-expansion-panel>