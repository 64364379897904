import { filter } from 'rxjs/operators';
import { style } from '@angular/animations';

import { tile as tileStrategy } from 'ol/loadingstrategy';
import { GisAppSharedService } from './gis-app-shared.service';
import KML from 'ol/format/KML';
import { Tile, Heatmap } from 'ol/layer';
import { Injectable } from '@angular/core';
import { OSM, TileWMS, XYZ, ImageArcGISRest, BingMaps, ImageWMS, TileArcGISRest, Stamen } from 'ol/source';
import { Feature, Graticule, Map } from 'ol';
import { bbox as bboxStrategy, all as allStrategy } from 'ol/loadingstrategy';
import { environment } from 'environments/environment';

import { AppLayer } from 'app/GeoHub/GIS-Shared-Lib/interface/Layers-Maps.interface';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';

import ImageLayer from 'ol/layer/Image';
import GeoJSON from 'ol/format/GeoJSON';
import VectorImageLayer from 'ol/layer/VectorImage';
import { Fill, Stroke, Style } from 'ol/style';
import Static from 'ol/source/ImageStatic';
import { GISserverTypes, layerTypesEnum } from 'app/GeoHub/GIS-Shared-Lib/GeoEnums';
import { BingmapsConfig } from 'app/GeoHub/GIS-Shared-Lib/const/Const.config';
import TileLayer from 'ol/layer/Tile';

import { DrawingInfo } from 'app/GeoHub/GIS-Shared-Lib/interface/arcgis.data.mode.interfacel';
import { EsriJSON } from 'ol/format';
import { createXYZ } from 'ol/tilegrid';

import { WebLayer } from 'app/GeoHub/GIS-Shared-Lib/interface/WebMap.interface';
import { hereMaps } from '../../GIS_Component/base-maps/here-maps.interface';
import { DrawStyleOptions } from '../../GIS_Component/Draw/draw.interface';
import { LayerStyleService } from '../../GIS_Component/layer-style/layer-style.service';

@Injectable({
  providedIn: 'root'
})
export class LayerManagmentService {
  layerindex: number;

  constructor(private gisAppSharedService: GisAppSharedService,
    private layerStyleService: LayerStyleService,

  ) { }

  createArcGISVectorLayer(userlayer: WebLayer, Zindex: number): AppLayer {


    var GraphicLayer = new VectorLayer({
      visible: true,

      source: new VectorSource()
    });
    var templayer = new AppLayer({
      Id: userlayer.Id + userlayer.Name,
      visible: true,
      LAYERS: null,
      LayerType: userlayer.LayerType,
      IsWidgetLayer: userlayer.IsWidgetLayer,
      IsGroup: userlayer.IsGroup,
      Style: userlayer.Style,
      IsBaseMap: userlayer.IsBaseMap,
      serverCategory: this.gisAppSharedService.Get_layer_Server_Category(GISserverTypes.arcgisFeatrueLayer),
      url: userlayer.Source.split("?")[0],
      Opacity: 1,
      ServerType: GISserverTypes.arcgisFeatrueLayer,
      name: userlayer.Name,
      Description: userlayer.Name,
      layer: GraphicLayer
    }
    );


    this.InitArcGISVectorLayer(templayer, null)
    return templayer;

  }
  InitArcGISVectorLayer(applayer: AppLayer, map: Map) {
    var token = "";
    if (applayer.Token)
      token = "&token=" + applayer.Token;



    this.gisAppSharedService.get_ArcGIS_URL_Details(applayer.url + "?f=json" + token).subscribe(
      (response: any) => {
        this.createArcGISVectorSource(map, applayer, response.drawingInfo);
      }, err => {
        console.log("Error adding new layer " + JSON.stringify(applayer));
        console.log(err);

      });

  }

  createArcGISVectorSource(map: Map, applayer: AppLayer, drawinginfo: DrawingInfo) {
    var self = this;
    var esrijsonFormat = new EsriJSON();
    var reqyestpage: number = 0;


    var token = "";
    if (applayer.Token)
      token = "&token=" + applayer.Token;



    var vectorSource = new VectorSource({
      loader: function (extent, resolution, projection) {
        var url = applayer.url + '/query/?f=geojson&' +
          'returnGeometry=true&spatialRel=esriSpatialRelIntersects' +
          '&geometry=' + encodeURIComponent('{"xmin":' + extent[0] + ',"ymin":' +
            extent[1] + ',"xmax":' + extent[2] + ',"ymax":' + extent[3] +
            ',"spatialReference":{"wkid":' + environment.mapDefaultProjection.split(':')[1] + '}}') +
          '&geometryType=esriGeometryEnvelope&inSR=' + environment.mapDefaultProjection.split(':')[1] + '&outFields=*' +
          '&outSR=' + environment.mapDefaultProjection.split(':')[1] + token;
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function (response) {
          if (this.readyState == 4 && this.status == 200) {
            let format = new GeoJSON();
            var features = format.readFeatures(this.response);


            try {

              if (features.length > 0)
                if (features[0].getId())
                  if (features[0].getGeometry != null) {
                    if (features.length > 0) {
                      features.forEach(feature => {
                        if (!applayer.Style)
                          feature.setStyle(self.gisAppSharedService.SetFeatureStyle(feature, drawinginfo));
                        else {

                          var x = JSON.parse(applayer.Style);
                          if (x.layerStyle) {
                            if (x.layerStyleType == "classified") {
                              var currentElementName = "classified"
                              var val = feature.getProperties()['objectid'];
                              for (const element of x.layerStyle.Classified) {
                                var styleData = {
                                  Name: currentElementName,
                                  featureType: feature.getGeometry().getType(),
                                  OUTLINE: currentElementName == "classified" && feature.getGeometry().getType().toLowerCase().includes('line') ? element.color : '#000000',
                                  FILL: element.color,
                                  SliderColor: '',
                                  SymbolSize: 1
                                }
                                if (val >= element.from && val <= element.to) {
                                  if (styleData.Name == "classified" && feature.getStyle() && feature.getStyle()['getImage']()) {
                                    feature.setStyle(self.layerStyleService.setStyle(styleData, feature.getStyle()['getImage']()))

                                  }
                                  else {
                                    feature.setStyle(self.layerStyleService.setStyle(styleData))
                                  }
                                  break;
                                }
                              }
                            }
                            else {
                              var layerstyle = self.layerStyleService.setStyle(x.layerStyle);
                              //////
                              feature.setStyle(layerstyle);
                            }
                          }
                          else
                            feature.setStyle(self.gisAppSharedService.SetFeatureStyle(feature, drawinginfo));

                        }
                      });
                      vectorSource.addFeatures(features);
                      if (map) map.removeLayer(applayer.layer);
                      applayer.layer.setOpacity(applayer.Opacity);
                      applayer.layer.setSource(vectorSource);

                      if (map) map.addLayer(applayer.layer);
                      reqyestpage = reqyestpage + 1;
                      xhttp.open("GET", url + "&resultOffset=" + (reqyestpage * 1000) + "&resultRecordCount=50000", true);
                      xhttp.send();
                      console.log("Features  count page: " + reqyestpage + " :  " + vectorSource.getFeatures().length);
                    }
                  }
            }
            catch (err) {
              console.error(err)
            }
          }
        };
        xhttp.onerror = function (error) {

        };
        xhttp.open("GET", url + "&resultOffset=" + (reqyestpage * 1000) + "&resultRecordCount=50000", true);
        xhttp.send()
      },
      strategy: tileStrategy(createXYZ({ tileSize: 512 }))
    });



    applayer.layer.setSource(vectorSource);
    if (map) map.removeLayer(applayer.layer);
    if (map) map.addLayer(applayer.layer);

  }


  get_LayerBy_Ol_Type(l: WebLayer) {

    var applayer: AppLayer;
    var SLD_Style: any;

    var layerstyle;
    layerstyle = this.gisAppSharedService.GetDefaultStyle();
    const layerStyle_DB = l.Style?JSON.parse(l.Style):null;

    if (l.Style) {

      if (layerStyle_DB.layerStyle) {
        if (layerStyle_DB.layerStyleType == "Location (Single symbol)") {
          if (l.LayerType == layerTypesEnum.TileLayer_TileWMS) {
            /**
            * M.S  if  layer WMS , build SLD style string 
            */
            SLD_Style = `<?xml version="1.0" encoding="UTF-8"?><sld:StyledLayerDescriptor xmlns="http://www.opengis.net/sld" xmlns:sld="http://www.opengis.net/sld" xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" version="1.0.0">
            <sld:NamedLayer>
              <sld:Name>${l.LAYERS[0]}</sld:Name>
              <sld:UserStyle>
                <sld:Name>${l.LAYERS[0]}</sld:Name>
                <sld:Title>azure polygon</sld:Title>
                <sld:FeatureTypeStyle>
                  <sld:Name>name</sld:Name>
                  <sld:Rule>
                    <sld:Title>line</sld:Title>
                    <sld:PolygonSymbolizer>
                      <sld:Fill>
                        <sld:CssParameter name="fill">${layerStyle_DB.layerStyle.FILL}</sld:CssParameter>
                      </sld:Fill>
                      <sld:Stroke>
                        <sld:CssParameter name="stroke">${layerStyle_DB.layerStyle.OUTLINE}</sld:CssParameter>
                        <sld:CssParameter name="stroke-width">${layerStyle_DB.layerStyle.SymbolSize}</sld:CssParameter>
                      </sld:Stroke>
                    </sld:PolygonSymbolizer>
                  </sld:Rule>
                </sld:FeatureTypeStyle>
              </sld:UserStyle>
            </sld:NamedLayer>
          </sld:StyledLayerDescriptor>
          `
          }
          else {

            layerstyle = this.layerStyleService.setStyle(JSON.parse(l.Style).layerStyle);
          }
        }

      }
    }


    switch (l.LayerType) {
      case layerTypesEnum.OSM:
        {
          applayer = new AppLayer({
            Id: l.Id,
            visible: l.Visible, IsBaseMap: l.IsBaseMap, IsGroup: l.IsGroup,
            IsWidgetLayer: l.IsWidgetLayer, LayerType: layerTypesEnum.OSM,
            url: l.Source,
            Opacity: l.Opacity,
            ServerType: l.ServerType,

            serverCategory: this.gisAppSharedService.Get_layer_Server_Category(l.ServerType),
            name: l.Name,
            Description: l.Name,
            layer: new TileLayer({
              visible: l.Visible,
              source: new OSM()
            })
          }
          );
          break;
        }
      case layerTypesEnum.Stamen_toner:
        {
          applayer = new AppLayer({
            Id: l.Id,
            visible: l.Visible, IsBaseMap: l.IsBaseMap, IsGroup: l.IsGroup,
            IsWidgetLayer: l.IsWidgetLayer, LayerType: layerTypesEnum.OSM,
            url: l.Source,
            Opacity: l.Opacity,
            ServerType: l.ServerType,
            serverCategory: this.gisAppSharedService.Get_layer_Server_Category(l.ServerType),
            name: l.Name,
            Description: l.Name,
            layer: new TileLayer({
              visible: l.Visible,
              source: new Stamen({
                layer: 'toner',
              }),
            })
          }
          );
          break;
        }
      case layerTypesEnum.Stamen_Terrain:
        {
          applayer = new AppLayer({
            Id: l.Id,
            visible: l.Visible, IsBaseMap: l.IsBaseMap, IsGroup: l.IsGroup,

            IsWidgetLayer: l.IsWidgetLayer, LayerType: layerTypesEnum.OSM,

            url: l.Source,
            Opacity: l.Opacity,
            ServerType: l.ServerType,
            serverCategory: this.gisAppSharedService.Get_layer_Server_Category(l.ServerType),
            name: l.Name,
            Description: l.Name,
            layer: new TileLayer({
              visible: l.Visible,
              source: new Stamen({
                layer: 'terrain',
              }),
            })
          }
          );
          break;
        }
      case layerTypesEnum.Stamen_Watercolor:
        {
          applayer = new AppLayer({
            Id: l.Id,
            visible: l.Visible, IsBaseMap: l.IsBaseMap, IsGroup: l.IsGroup,
            IsWidgetLayer: l.IsWidgetLayer, LayerType: layerTypesEnum.OSM,
            url: l.Source,
            Opacity: l.Opacity,
            ServerType: l.ServerType,
            serverCategory: this.gisAppSharedService.Get_layer_Server_Category(l.ServerType),
            name: l.Name,
            Description: l.Name,
            layer: new TileLayer({
              visible: l.Visible,
              source: new Stamen({
                layer: 'watercolor',
              }),
            })
          }
          );
          break;
        }

      case layerTypesEnum.Stamen_mars:
        {
          applayer = new AppLayer({
            Id: l.Id,
            visible: l.Visible, IsBaseMap: l.IsBaseMap, IsGroup: l.IsGroup,

            IsWidgetLayer: l.IsWidgetLayer, LayerType: layerTypesEnum.OSM,

            url: l.Source,
            Opacity: l.Opacity,
            ServerType: l.ServerType,
            serverCategory: this.gisAppSharedService.Get_layer_Server_Category(l.ServerType),
            name: l.Name,
            Description: l.Name,
            layer: new TileLayer({
              visible: l.Visible,

              source: new Stamen({
                layer: 'mars',
              }),
            })
          }
          );
          break;
        }


      case layerTypesEnum.Stamen_treescabscrime:
        {
          applayer = new AppLayer({
            Id: l.Id,
            visible: l.Visible, IsBaseMap: l.IsBaseMap, IsGroup: l.IsGroup,

            IsWidgetLayer: l.IsWidgetLayer, LayerType: layerTypesEnum.OSM,

            url: l.Source,
            Opacity: l.Opacity,
            ServerType: l.ServerType,
            serverCategory: this.gisAppSharedService.Get_layer_Server_Category(l.ServerType),
            name: l.Name,
            Description: l.Name,
            layer: new TileLayer({
              visible: l.Visible,

              source: new Stamen({
                layer: 'trees-cabs-crime',
              }),
            })
          }
          );
          break;
        }

      case layerTypesEnum.Here:
        {
          applayer = new AppLayer({
            Id: l.Id,
            visible: l.Visible, IsWidgetLayer: l.IsWidgetLayer, LayerType: l.LayerType,
            IsBaseMap: l.IsBaseMap, IsGroup: l.IsGroup,

            Opacity: l.Opacity,
            ServerType: l.ServerType,
            serverCategory: this.gisAppSharedService.Get_layer_Server_Category(l.ServerType),
            name: l.Name,
            Description: 'Map Tiles &copy; ' + new Date().getFullYear() + ' ' +
              '<a href="http://developer.here.com">HERE</a>',
            layer: new TileLayer({
              opacity: l.Opacity,
              visible: l.Visible,
              preload: Infinity,
              source: new XYZ({
                url: l.Source.replace("HereAppID", environment.HEREappId).replace("HEREappKEY", environment.HEREappKEY),
                attributions: 'Map Tiles &copy; ' + new Date().getFullYear() + ' ' +
                  '<a href="http://developer.here.com">HERE</a>'
              })
            })
          }
          );

          break;
        }
      case layerTypesEnum.TileLayer_TileWMS:
        {
          /**
           * M.S  if  layer WMS , it'll take SLD style if found
           */
          l.VisibleLAYERS = l.VisibleLAYERS ? l.VisibleLAYERS : l.LAYERS,

            applayer = new AppLayer({
              Id: l.Id,
              ServerType: l.ServerType, serverCategory: this.gisAppSharedService.Get_layer_Server_Category(l.ServerType),
              visible: l.Visible, IsBaseMap: l.IsBaseMap, IsGroup: l.IsGroup, IsWidgetLayer: l.IsWidgetLayer, LayerType: l.LayerType,
              Opacity: l.Opacity,
              name: l.Name,
              LAYERS: l.LAYERS ? l.LAYERS : null,
              VisibleLAYERS: l.VisibleLAYERS ? l.VisibleLAYERS : l.LAYERS,
              url: l.Source,
              Description: l.Description,
              Style: l.Style,
              layer: new TileLayer({
                visible: l.Visible,
                opacity: l.Opacity,

                source: new TileWMS({
                  url: l.Source,
                  serverType: this.gisAppSharedService.getservertype(l.ServerType),
                  transition: 0,
                  crossOrigin: 'anonymous',
                  params: {
                    crossOrigin: 'anonymous',
                    FORMAT: "image/png",
                    serverType: this.gisAppSharedService.getservertype(l.ServerType),
                    LAYERS: l.VisibleLAYERS ? l.VisibleLAYERS : null,
                    legend: l.Source,
                    queryable: true,
                    TILED: true,
                    SLD_BODY: SLD_Style,

                  }
                })
              })
            }
            );

          break;
        }
      case layerTypesEnum.ImageLayer_ImageWMS:
        {
          applayer = new AppLayer({
            Id: l.Id,
            ServerType: l.ServerType, serverCategory: this.gisAppSharedService.Get_layer_Server_Category(l.ServerType),
            visible: l.Visible, IsBaseMap: l.IsBaseMap, IsGroup: l.IsGroup, IsWidgetLayer: l.IsWidgetLayer, LayerType: l.LayerType,
            Opacity: l.Opacity,
            name: l.Name,
            LAYERS: l.LAYERS ? l.LAYERS : null,
            VisibleLAYERS: l.VisibleLAYERS ? l.VisibleLAYERS : l.LAYERS,
            url: l.Source,
            Description: l.Description,
            layer: new ImageLayer({
              visible: l.Visible,
              opacity: l.Opacity,

              source: new ImageWMS({
                crossOrigin: 'anonymous',
                url: l.Source,
                ratio: 1,
                serverType: this.gisAppSharedService.getservertype(l.ServerType),
                params: {
                  LAYERS: l.VisibleLAYERS ? l.VisibleLAYERS : null,
                  crossOrigin: 'anonymous',
                  FORMAT: "image/png"
                }
              })
            })
          }
          );
          break;
        }
      case layerTypesEnum.arcgisFeatrueLayer:
        {
          applayer = this.createArcGISVectorLayer(l, l.Index);
          applayer.IsWidgetLayer = l.IsWidgetLayer;

          break;
        }


      case layerTypesEnum.WFS_realtime:
        {
          var source = new VectorSource({
            format: new GeoJSON(),

            url: function (extent) {
              return l.Source + '?service=WFS&' +
                'version=1.3.0&request=GetFeature&typename=' + l.LAYERS[0] + '&' +
                'outputFormat=application/json&srsname=' + environment.mapDefaultProjection;
            },
            strategy: allStrategy
          });
          var wfsLayer = new VectorLayer({
            visible: l.Visible,
            source: source,
            opacity: l.Opacity,
            style: layerstyle

          });

          applayer = new AppLayer({
            Id: l.Id,
            ServerType: l.ServerType, serverCategory: this.gisAppSharedService.Get_layer_Server_Category(l.ServerType),
            visible: l.Visible, IsWidgetLayer: l.IsWidgetLayer, LayerType: l.LayerType,
            IsBaseMap: false,
            IsGroup: l.IsGroup,
            Opacity: l.Opacity,
            Style: l.Style,
            name: l.Name,
            LAYERS: l.LAYERS.join ? l.LAYERS : [l.Name],
            url: l.Source,
            Description: l.Description,
            layer: wfsLayer
          }
          );
          wfsLayer.setStyle(layerstyle);

          setInterval(function () {
            if (applayer.layer)
              ((applayer.layer as VectorLayer).getSource() as VectorSource).refresh();


          }, l.updateinterval ? l.updateinterval : environment.updateinterval);

          break;
        }

      case layerTypesEnum.WFS:
        {
          var wfsLayer = new VectorLayer({
            visible: l.Visible,
            opacity: l.Opacity,
            source: new VectorSource(
              {
                format: new GeoJSON(),
                url: function (extent) {
                  return l.Source + '?service=WFS&' +
                    'version=1.3.0&request=GetFeature&typename=' + l.LAYERS[0] + '&' +
                    'outputFormat=application/json&srsname=' + environment.mapDefaultProjection;
                },
                strategy: allStrategy,


              }),
          });



          applayer = new AppLayer({
            Id: l.Id,
            ServerType: l.ServerType, serverCategory: this.gisAppSharedService.Get_layer_Server_Category(l.ServerType),
            visible: l.Visible, IsWidgetLayer: l.IsWidgetLayer, LayerType: l.LayerType,
            IsBaseMap: false,
            IsGroup: l.IsGroup,
            Opacity: l.Opacity,
            Style: l.Style,
            name: l.Name,
            LAYERS: l.LAYERS.join ? l.LAYERS : [l.Name],
            url: l.Source,
            Description: l.Description,
            layer: wfsLayer
          });

          wfsLayer.setStyle(layerstyle);



          break;
        }
      case layerTypesEnum.VectorLayer_VectorSource:
        {
          applayer = new AppLayer({
            Id: l.Id,
            ServerType: l.ServerType, serverCategory: this.gisAppSharedService.Get_layer_Server_Category(l.ServerType),
            visible: l.Visible, IsBaseMap: l.IsBaseMap, IsGroup: l.IsGroup,
            Opacity: l.Opacity, IsWidgetLayer: l.IsWidgetLayer, LayerType: l.LayerType,
            name: l.Name,
            LAYERS: l.LAYERS ? l.LAYERS : null,
            VisibleLAYERS: l.VisibleLAYERS ? l.VisibleLAYERS : l.LAYERS,
            url: l.Source,
            Style: l.Style,
            Description: l.Description,
            layer: new VectorLayer({
              visible: l.Visible,
              opacity: l.Opacity,
              source: new VectorSource({
                url: l.Source
              })
            })
          }
          );
          break;
        }
      case layerTypesEnum.VectorLayer_GeoJSON:
        {


          applayer = new AppLayer({
            Id: l.Id, IsWidgetLayer: l.IsWidgetLayer, LayerType: l.LayerType,
            ServerType: l.ServerType, serverCategory: this.gisAppSharedService.Get_layer_Server_Category(l.ServerType),
            visible: l.Visible, IsBaseMap: l.IsBaseMap, IsGroup: l.IsGroup,
            Opacity: l.Opacity,
            name: l.Name,
            LAYERS: l.LAYERS ? l.LAYERS : null,
            VisibleLAYERS: l.VisibleLAYERS ? l.VisibleLAYERS : l.LAYERS,
            url: l.Source,
            Style: l.Style,
            Description: l.Description,
            layer: new VectorLayer({
              visible: l.Visible,
              opacity: l.Opacity,
              source: new VectorSource({
                url: l.Source,
                format: l.format == "GeoJSON" ? new GeoJSON() : null
              }
              ),
              style: layerstyle
            })
          }
          );
          break;
        }
      case layerTypesEnum.VectorImageLayer_VectorSource:
        {
          applayer = new AppLayer({
            Id: l.Id, IsWidgetLayer: l.IsWidgetLayer, LayerType: l.LayerType,
            ServerType: l.ServerType, serverCategory: this.gisAppSharedService.Get_layer_Server_Category(l.ServerType),
            visible: l.Visible, IsBaseMap: l.IsBaseMap, IsGroup: l.IsGroup,
            Opacity: l.Opacity,
            name: l.Name,
            Style: l.Style,
            LAYERS: l.LAYERS ? l.LAYERS : null,
            VisibleLAYERS: l.VisibleLAYERS ? l.VisibleLAYERS : l.LAYERS,
            url: l.Source,
            Description: l.Description,
            layer: new VectorImageLayer({
              visible: l.Visible,
              opacity: l.Opacity,
              source: new VectorSource({
                url: l.Source,
                format: l.format == "GeoJSON" ? new GeoJSON() : null
              }),
              style: layerstyle
            })
          }
          );
          break;
        }
      case layerTypesEnum.Heatmap_VectorSource:
        {
          applayer = new AppLayer({
            Id: l.Id, IsWidgetLayer: l.IsWidgetLayer, LayerType: l.LayerType,
            ServerType: l.ServerType, serverCategory: this.gisAppSharedService.Get_layer_Server_Category(l.ServerType),
            visible: l.Visible, IsBaseMap: l.IsBaseMap, IsGroup: l.IsGroup,
            Opacity: l.Opacity,
            name: l.Name,
            LAYERS: l.LAYERS ? l.LAYERS : null,
            VisibleLAYERS: l.VisibleLAYERS ? l.VisibleLAYERS : l.LAYERS,
            url: l.Source,
            Description: l.Description,
            layer: new Heatmap({
              opacity: l.Opacity,
              source: new VectorSource({
                url: l.Source,
                format: l.format == "KML" ? new KML({ extractStyles: false }) : new GeoJSON()
              }),
              // blur: l.heatmapConfig.blur_value,
              // radius: l.heatmapConfig.radius_value,
              // weight: l.heatmapConfig.magnitude
            })
          }
          );
          break;
        }
      case layerTypesEnum.Graticule_Graticule:
        {
          applayer = new AppLayer({
            Id: l.Id, IsWidgetLayer: l.IsWidgetLayer, LayerType: l.LayerType,
            ServerType: l.ServerType, serverCategory: this.gisAppSharedService.Get_layer_Server_Category(l.ServerType),
            visible: l.Visible, IsBaseMap: l.IsBaseMap, IsGroup: l.IsGroup,
            Opacity: l.Opacity,
            name: l.Name,
            LAYERS: l.LAYERS ? l.LAYERS : null,
            VisibleLAYERS: l.VisibleLAYERS ? l.VisibleLAYERS : l.LAYERS,
            url: l.Source,
            Description: l.Description,
            layer: new Graticule({
              opacity: l.Opacity,
              visible: l.Visible,

              strokeStyle: new Stroke({
                // color: l.Graticule.Strokecolor,
                // width: l.Graticule.width,
                // lineDash: l.Graticule.lineDash
              }),
              // showLabels: l.Graticule.showLabels,
              // wrapX: l.Graticule.wrapX
            })
          }
          );
          break;
        }
      case layerTypesEnum.ImageLayer_Static:
        {
          applayer = new AppLayer({
            Id: l.Id, IsWidgetLayer: l.IsWidgetLayer, LayerType: l.LayerType,
            ServerType: l.ServerType, serverCategory: this.gisAppSharedService.Get_layer_Server_Category(l.ServerType),
            visible: l.Visible, IsBaseMap: l.IsBaseMap, IsGroup: l.IsGroup,
            Opacity: l.Opacity,
            name: l.Name,
            LAYERS: l.LAYERS ? l.LAYERS : null,
            VisibleLAYERS: l.VisibleLAYERS ? l.VisibleLAYERS : l.LAYERS,
            url: l.Source,
            Description: l.Description,
            layer: new ImageLayer({
              visible: l.Visible,
              opacity: l.Opacity,
              source: new Static({
                url: l.Source,
                crossOrigin: 'anonymous',
                projection: l.EPSG,
                imageExtent: l.Extent
              })
            })
          }
          );
          break;
        }
      case layerTypesEnum.TileLayer_TileArcGISRest:
        {
          applayer = new AppLayer({
            Id: l.Id, IsWidgetLayer: l.IsWidgetLayer, LayerType: l.LayerType,
            ServerType: l.ServerType, serverCategory: this.gisAppSharedService.Get_layer_Server_Category(l.ServerType),
            visible: l.Visible, IsBaseMap: l.IsBaseMap, IsGroup: l.IsGroup,
            Opacity: l.Opacity,
            name: l.Name,
            Style: l.Style,
            LAYERS: l.LAYERS ? l.LAYERS : null,
            VisibleLAYERS: l.VisibleLAYERS ? l.VisibleLAYERS : l.LAYERS,
            url: l.Source,
            Description: l.Description,
            layer: new TileLayer({
              visible: l.Visible,
              opacity: l.Opacity,
              source: new TileArcGISRest({
                url: l.Source,
                params: {
                  transparent: true,
                  format: 'png8',
                  dbi: 70
                },
                crossOrigin: "anonymous",
              })
            })
          }
          );
          break;
        }
      case layerTypesEnum.ImageLayer_ImageArcGISRest:
        {
          applayer = new AppLayer({
            Id: l.Id, IsWidgetLayer: l.IsWidgetLayer, LayerType: l.LayerType,
            ServerType: l.ServerType, serverCategory: this.gisAppSharedService.Get_layer_Server_Category(l.ServerType),
            visible: l.Visible, IsBaseMap: l.IsBaseMap, IsGroup: l.IsGroup,
            Opacity: l.Opacity,
            name: l.Name,
            LAYERS: l.LAYERS ? l.LAYERS : null,
            VisibleLAYERS: l.VisibleLAYERS ? l.VisibleLAYERS : l.LAYERS,
            url: l.Source,
            Description: l.Description,
            layer: new ImageLayer({
              visible: l.Visible,
              opacity: l.Opacity,
              source: new ImageArcGISRest({
                ratio: 1,
                crossOrigin: "anonymous",
                params: {
                  transparent: true,
                  format: 'image/png'
                },
                url: l.Source
              })
            })
          }
          );
          break;
        }
      case layerTypesEnum.Bing:
        {
          applayer = new AppLayer({
            Id: l.Id, IsWidgetLayer: l.IsWidgetLayer, LayerType: l.LayerType,
            ServerType: l.ServerType, serverCategory: this.gisAppSharedService.Get_layer_Server_Category(l.ServerType),
            visible: l.Visible, IsBaseMap: l.IsBaseMap, IsGroup: l.IsGroup,
            Opacity: l.Opacity,
            name: l.Name,
            LAYERS: l.LAYERS ? l.LAYERS : null,
            VisibleLAYERS: l.VisibleLAYERS ? l.VisibleLAYERS : l.LAYERS,
            url: l.Source,
            Description: l.Description,
            layer: new TileLayer({
              visible: l.Visible,
              opacity: l.Opacity,
              source: new BingMaps({
                key: BingmapsConfig.Key,
                maxZoom: 25,
                imagerySet: l.LAYERS[0]
              })
            })
          }
          );
          break;
        } case layerTypesEnum.XYZ:
        {
          applayer = new AppLayer({
            Id: l.Id, IsWidgetLayer: l.IsWidgetLayer, LayerType: l.LayerType,
            ServerType: l.ServerType, serverCategory: this.gisAppSharedService.Get_layer_Server_Category(l.ServerType),
            visible: l.Visible, IsBaseMap: l.IsBaseMap, IsGroup: l.IsGroup,
            Opacity: l.Opacity,
            name: l.Name,
            LAYERS: l.LAYERS ? l.LAYERS : null,
            VisibleLAYERS: l.VisibleLAYERS ? l.VisibleLAYERS : l.LAYERS,
            url: l.Source,
            Description: l.Description,
            layer: new Tile({
              opacity: l.Opacity,
              visible: l.Visible,
              source: new XYZ({
                maxZoom: 25,
                urls: [
                  l.Source
                ],
                tilePixelRatio: 1
              })
            })
          }
          );
          break;
        }
      case layerTypesEnum.ImageArcGISRestXYZ:
        {
          applayer = new AppLayer({
            Id: l.Id, IsWidgetLayer: l.IsWidgetLayer, LayerType: l.LayerType,
            ServerType: l.ServerType, serverCategory: this.gisAppSharedService.Get_layer_Server_Category(l.ServerType),
            visible: l.Visible, IsBaseMap: l.IsBaseMap, IsGroup: l.IsGroup,
            Opacity: l.Opacity,
            name: l.Name,
            LAYERS: l.LAYERS ? l.LAYERS : null,
            VisibleLAYERS: l.VisibleLAYERS ? l.VisibleLAYERS : l.LAYERS,
            url: l.Source,
            Description: l.Description,
            layer: new TileLayer({
              opacity: l.Opacity,
              visible: l.Visible,
              source: new XYZ({
                url: l.Source
              })
            })
          }
          );
          break;
        }
      case layerTypesEnum.google:
        {
          applayer = new AppLayer({
            Id: l.Id,
            name: l.Name, IsWidgetLayer: l.IsWidgetLayer, LayerType: l.LayerType,
            Description: l.Description,
            url: l.Source,
            Opacity: l.Opacity,
            ServerType: l.ServerType, serverCategory: this.gisAppSharedService.Get_layer_Server_Category(l.ServerType),
            LAYERS: l.LAYERS ? l.LAYERS : null,
            VisibleLAYERS: l.VisibleLAYERS ? l.VisibleLAYERS : l.LAYERS,
            visible: l.Visible, IsBaseMap: l.IsBaseMap, IsGroup: l.IsGroup,
            layer: new TileLayer({
              opacity: l.Opacity,
              visible: l.Visible,
              source: new TileWMS({
                url: l.Source,

                serverType: 'google',
                transition: 0,
                params: {
                  LAYERS: l.VisibleLAYERS ? l.VisibleLAYERS : null,
                  legend: l.Source,
                  TILED: l.Tiled
                }
              })
            })
          }
          ); break;
        }
      default:
        {
          applayer = null;
          break;
        }
    }
    if (applayer) {

      const layerStyleService = this.layerStyleService
      if (layerStyle_DB && layerStyle_DB.layerStyle && layerStyle_DB.layerStyleType == "classified") {
        applayer.layer['setStyle'](
          function (feature) {
            return new Style({
              fill: new Fill({
                color: getColor(feature, layerStyle_DB)
              }),
              stroke: new Stroke({
                color: 'rgba(0,0,0,0.8)'
              })
            });
          }
        )


      }

      applayer.layer.setVisible(applayer.visible);
      applayer.layer.setOpacity(applayer.Opacity / 100);
      applayer.IsWidgetLayer = l.IsWidgetLayer;
    }

    return applayer;
  }



}
function getColor(feature: any, x: any) {


  var val = feature.getProperties()[x.attributeStyle];

  if (val) {
    for (const element of x.layerStyle.Classified) {

      if (val >= element.from && val <= element.to) {
        return element.color;

      }
    }

    throw new Error('Function not implemented.');
  }
}

