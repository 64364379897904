import { WebApiResult } from '../../shared/models/apiresult';
 import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WriteTransactionOptions } from "ol/format/WFS";
import { WFS, GeoJSON } from "ol/format";
import { Observable, throwError, from, BehaviorSubject } from "rxjs";
import Feature from "ol/Feature";
import { FeatureCollection } from "geojson";
import { catchError, map } from "rxjs/operators";
import { Subject } from 'rxjs';
import { Coordinate } from 'ol/coordinate';
import { Map } from "ol";
import { environment } from "environments/environment";
import { AppLayer } from "app/GeoHub/GIS-Shared-Lib/interface/Layers-Maps.interface";
import { IncidentAttributes } from "./Incident.Interface";
import { GisAppSharedService } from "app/GeoHub/GIS-Shared-Lib/services/gis-app-shared.service";


@Injectable({
  providedIn: "root"
})
export class IncidentService {
  geojsonFormat = new GeoJSON();
  public Incidentcoordinate = new Subject<{ coordinate: Coordinate, eventName: string, map: Map }>();
  public Incidentcoordinate$: Observable<{ coordinate: Coordinate, eventName: string, map: Map }>  = this.Incidentcoordinate.asObservable();
  public incidentlayer: AppLayer = null;

  settings: IncidentAttributes;
  public incidentcount:number=0;
  constructor(private http: HttpClient,
    private gisappsrvc: GisAppSharedService
  ) { }

  getDataDomains() {
   var self = this;


    return this.http.get(environment.apiURL+ "/api/GeneralKNPC/getDataDomains").pipe(
      map((response: WebApiResult) => {
       
       
        return response.data;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  load() {
    const jsonFile = "./assets/configurations/incident.json";
    var self = this;


    return this.http.get(jsonFile).pipe(
      map((response: IncidentAttributes) => {
        self.settings = <IncidentAttributes>response;
        return self.settings;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  GetIncidentConfig() {
    const jsonFile = "./assets/configurations/incident.json";
    var self = this;


    return this.http.get(jsonFile).pipe(
      map((response: IncidentAttributes) => {

        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }


  AddIncidentToMap(coordinate: Coordinate, map: Map) {
    this.Incidentcoordinate.next({ 'coordinate': coordinate, eventName: 'features', 'map': map });
  }



  transactWFS(mode: "insert" | "update" | "delete", feature: Feature, wfsSetting, serverurl) {
   

    let promise = new Promise((resolve, reject) => {
      var formatWFS = new WFS({
        featureNS: wfsSetting.featureNS,
        featureType: wfsSetting.featureType,
        schemaLocation: wfsSetting.schemaLocation
      });
      var node;
      var srsName = 'EPSG:32639';
      var formatGML: WriteTransactionOptions = {
        featureNS: wfsSetting.featureNS,
        featureType: wfsSetting.featureType,
        gmlOptions: {
          "srsName": srsName
        },
        featurePrefix: "",
        nativeElements: null,
        version: "1.1.0",
        "srsName": srsName
      };
  
  
      switch (mode) {
        case "insert":
          node = formatWFS.writeTransaction([feature], null, null, formatGML);
          break;
        case "update":
          node = formatWFS.writeTransaction(null, [feature], null, formatGML);
          break;
        case "delete":
          node = formatWFS.writeTransaction(null, null, [feature], formatGML);
          break;
      }
  
      var str = new XMLSerializer().serializeToString(node);
      var data = str.replace(
        "feature:" + wfsSetting.featureType,
        wfsSetting.featureNS +
        ":" +
        wfsSetting.featureType
      );
      var uril =
        fetch(serverurl, {

          method: 'POST',
          headers: {
            "Content-Type": "text/xml"
          },

          body: data

        })
          .then(response => {
            return response.text();
          }).catch(error => {
            console.log(error);

          })
          .then((Featuresresponse: string) => {

            resolve(this.gisappsrvc.processXML(Featuresresponse));

          });


    }).catch(error => {

      console.log(error);
      throw error;
    });
    return promise;


    
  }

  getFeatureinfo(
    url: string,
    dataProjection: string,
    featureProjection: string
  ): Observable<Feature[]> {
    return this.http
      .get(url, {
        observe: "body",
        responseType: "json"
      })
      .pipe(
        map(response => {
          const featureCollection = <FeatureCollection<any, any>>response;
          let format = new GeoJSON();
          if (dataProjection !== featureProjection) {
            const pseudoGeoJSONFormat = <any>GeoJSON;
            format = new pseudoGeoJSONFormat({
              dataProjection: dataProjection,
              featureProjection: featureProjection
            });
          }
          return format.readFeatures(featureCollection);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }


  getIncidents() {

    var url = `${this.settings.serverURL}/wms?service=WFS&version=1.0.0&request=GetFeature&typeName=${this.settings.Setting.ViewLayerName}` + "&SRSNAME=" + 'EPSG:32639' + "&CRS=" + 'EPSG:32639' + "&SRS=" + 'EPSG:32639' + "&outputFormat=application%2Fjson" + "&CQL_FILTER= " + this.settings.IsDeleted + " = '2'";

    let promise = new Promise((resolve, reject) => {

      if (url) {
        fetch(url)
          .then(response => {
            return response.json();
          }).catch(error => {
            console.log(error);

          })
          .then(Featuresresponse => {
            resolve(Featuresresponse);
            return Featuresresponse;
          });
      }




    }).catch(error => {

      console.log(error);
      throw error;
    });
    return promise;



  }


}
