export interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  RoleId?: number;
}
export interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  RoleId?: number;
  sub?: IChildItem[];
}

export interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}
 
export const DefaultMenu_notLogin = [{
  "name": "HOME",
  "type": "link",
  "tooltip": "Home",
  "icon": "home",
  "state": "home"
},
{
  "name": "MAP",
  "type": "link",
  "tooltip": "Map",
  "icon": "map",
  "state": "map"
},{
  "name": "PRICES",
  "type": "link",
  "tooltip": "Price",
  "icon": "receipt",
  "state": "price"
},
{
  "name": "ABOUT", 
  "type": "link",
  "tooltip": "About",
  "icon": "info",
  "state": "home/about"
}


]