<!-- <div class="text-center">
    <h5 class="m-0 pb-1" style="white-space: pre-wrap; line-height: 1.4;">{{ title }}</h5>
    <div mat-dialog-content>
        <div style=" display: inline-block;
        position: relative;">
        <mat-spinner [style.margin]="'auto'" color="accent" style="color: #0a2b46;">
      

        </mat-spinner>
     
    </div>
</div>
</div> -->
    <h5 class="m-0 pb-1" style="white-space: pre-wrap; line-height: 1.4;">{{ title }}</h5>

<div class="app-loader">
    <div class="spinner">
      <div
        class="double-bounce1 mat-bg-primary"
        style="background: #fcc02e;"
      ></div>

      <div
        class="double-bounce2 mat-bg-accent"
        style="background: #21cfc7;"
      ></div> 
      <img  class="imagelogo"   src="./assets/images/vball.png">
       <div  style="    width: 200px;
    background-color: white;
    text-align: center;
    padding: 15px  ;
    border-radius: 20px;
    margin-top: 50px;
    margin-left: -77px;">
        <h5 class=" " style="white-space: pre-wrap; line-height: 1.4;" id="apploadertitle99"> </h5>
      </div>
    </div>
  
  </div>


