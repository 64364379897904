import { environment } from './../../../../environments/environment';
import { Component, OnInit, Input } from '@angular/core';
import { DefaultMenu_notLogin } from 'app/shared/models/menu.model';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { LayoutService } from 'app/shared/services/layout.service';
import { NavigationService } from 'app/shared/services/navigation.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html'
})
export class SidenavComponent {
  @Input('items') public menuItems: any[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;
  enableChat: boolean;

  constructor(
    public layout: LayoutService,
    private navService: NavigationService,
 
 
    public jwtAuth: JwtAuthService,
  ) {}
  ngOnInit() {

    this.enableChat=environment.enableSignalR;
  }

  // Only for demo purpose
  addMenuItem() {
    this.menuItems.push({
      name: 'ITEM',
      type: 'dropDown',
      tooltip: 'Item',
      icon: 'done',
      state: 'material',
      sub: [
        {name: 'SUBITEM', state: 'cards'},
        {name: 'SUBITEM', state: 'buttons'}
      ]
    });
  }

  signout() {
    this.jwtAuth.signout();
    this.navService.menuItems.next(DefaultMenu_notLogin);
 
   
  }

}