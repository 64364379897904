

 
export class MapInteractions {
    public GraphiclayerSingleClickEnabled?: boolean;
    public source:string;
    public measureEnabled?: boolean;
    public measuring?: boolean;
    public DrawEnabled?: boolean;
    public pointerMoveEnabled?: boolean;
    public recreateMap?: boolean;
   
    constructor(options: MapInteractionsOptions) {
        this.source = options.source;

        this.GraphiclayerSingleClickEnabled = options.GraphiclayerSingleClickEnabled ? options.GraphiclayerSingleClickEnabled : false;
        this.measureEnabled = options.measureEnabled ? options.measureEnabled : false;
        this.DrawEnabled = options.DrawEnabled ? options.DrawEnabled : false;
        this.pointerMoveEnabled = options.pointerMoveEnabled ? options.pointerMoveEnabled : false;
        this.recreateMap=   options.recreateMap?  options.recreateMap:false;
        if(!this.DrawEnabled && !this.measureEnabled  )
        this.GraphiclayerSingleClickEnabled=true;
        else     if(this.DrawEnabled ||  this.measureEnabled  )
        this.GraphiclayerSingleClickEnabled=false;

    }
    public setdefult() {

        this.GraphiclayerSingleClickEnabled = false;
        this.measureEnabled = false;
        this.DrawEnabled = false;
        this.pointerMoveEnabled =true;
        this.GraphiclayerSingleClickEnabled=true;
        this.recreateMap=false;
    }
   public set(options: MapInteractionsOptions) {
    this.source = options.source;
            this.GraphiclayerSingleClickEnabled = options.GraphiclayerSingleClickEnabled ? options.GraphiclayerSingleClickEnabled : false;
        this.measureEnabled = options.measureEnabled ? options.measureEnabled : false;
        this.DrawEnabled = options.DrawEnabled ? options.DrawEnabled : false;
        this.pointerMoveEnabled = options.pointerMoveEnabled ? options.pointerMoveEnabled : false;
        this.recreateMap=   options.recreateMap?  options.recreateMap:false;

        if(!this.DrawEnabled && !this.measureEnabled  )
        this.GraphiclayerSingleClickEnabled=true;
        else     if(this.DrawEnabled || !this.measureEnabled  )
        this.GraphiclayerSingleClickEnabled=false;

    }  
}


export interface MapInteractionsOptions {
    GraphiclayerSingleClickEnabled?: boolean;
    measureEnabled?: boolean;
    DrawEnabled?: boolean;
    pointerMoveEnabled?: boolean;
    routeEnabled?:boolean;
    recreateMap?:boolean;
    source:string; //UniqueWidgetID
}

