import GeometryType from "ol/geom/GeometryType";

export class DrawStyleOptions {
    pointSize: number;
    colorStroke: string;
    strokeWidth: number;
    colorFill: string;
    text?: string;
    fontColor?: string;
    fontstrokeSize?: number;
    fontSize?: string;
    lineDash?: number[];
    init( ) {
      this.colorStroke =  "rgba(16, 63, 85, 0.8)";
      this.strokeWidth = 2;
      this.colorFill =  "rgba(16, 63, 85, 0.2)";
      this.fontColor =  "rgba(16, 63, 85, 1)";
      this.text =  "";
      this.fontstrokeSize =1;
      this.fontSize = "16px";
      this.lineDash = [0,0];
      this.pointSize = 5;
    }
    constructor(styleoptions?: IStylesOptions) {
      this.colorStroke =( styleoptions && styleoptions.colorStroke) ? styleoptions.colorStroke : "#124a65db";
      this.strokeWidth = ( styleoptions && styleoptions.strokeWidth) ? styleoptions.strokeWidth : 2;
      this.colorFill = ( styleoptions && styleoptions.colorFill )? styleoptions.colorFill : "#2d83addb";
      this.fontColor = ( styleoptions && styleoptions.fontColor) ? styleoptions.fontColor : "#206d92";
      this.text = ( styleoptions && styleoptions.text )? styleoptions.text : "";
      this.fontstrokeSize = ( styleoptions && styleoptions.fontstrokeSize )? styleoptions.fontstrokeSize : 1;
      this.fontSize = ( styleoptions && styleoptions.fontSize )? styleoptions.fontSize : "16px";
      this.lineDash = ( styleoptions && styleoptions.lineDash )? styleoptions.lineDash : [0,0];
      this.pointSize = ( styleoptions && styleoptions.pointSize) ? styleoptions.pointSize : 7;
    }
  
  
  }
  export interface DrawOption{
    type: string  ;
    geometryType: GeometryType  ;
     Name: string;
      icon:string;
    
  }
export interface DrawStyle{
    colorStrokeStr: string;
    colorFillStr: string;
    strokeWidth:number;

}
  export interface IStylesOptions {
    pointSize?: number  ;
    colorStroke?: string  ;
    strokeWidth?: number  ;
    colorFill?: string  ;
    text?: string;
    fontColor?: string ;
    fontstrokeSize?   ;
    fontSize?: string  ;
    lineDash?: number[]  ;
  }