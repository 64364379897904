import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Injectable } from '@angular/core';

import { AppAcceptMessageComponent } from './App-Accept-Message.component';

interface confirmData {
  title?: string,
  message?: string,
 
  color?:string
}

@Injectable()
export class AppAcceptMessageService {

  constructor(private dialog: MatDialog) { }

 


  public showAcceptReject(title: string , message: string ): Observable<string> {
    let dialogRef: MatDialogRef<AppAcceptMessageComponent>;
    dialogRef = this.dialog.open(AppAcceptMessageComponent, {
      width: '380px',
      disableClose: false,
      data: {title: title, message: message , color:"accent"}
    });
    return dialogRef.afterClosed();
  }


  
}