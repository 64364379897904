import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-confirm',
  template: `<h1 matDialogTitle style=" margin: -14px; " class="mb-05">{{ data.title }}</h1>
  <hr style="    margin-top: -9px;  margin-left: -18px" color="accent">
    <div mat-dialog-content class="mb-1" [innerHTML]="data.message"> </div>
    <div mat-dialog-actions>
    <button 
    type="button" 
    mat-flat-button
    color="{{data.color?data.color: 'primary'}}" 
    (click)="dialogRef.close(true)">OK</button>
    &nbsp;
    <span fxFlex></span>
    <button 
    type="button"
    color="default"
    mat-flat-button 
    (click)="dialogRef.close(false)" *ngIf="data.isconfirm">Cancel</button>
    </div>`,
})
export class AppComfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<AppComfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    
  ) {}
}