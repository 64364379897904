<div  >
<mat-accordion  #notificationAccordion class="no-paddong-no_margin" multi="true" expanded="true">
    

    <mat-expansion-panel style="margin: 10px !important; padding: 5px !important; box-shadow:none">
        <mat-expansion-panel-header>
            <mat-panel-title class="">
                <mat-icon mat-list-icon style="float: left; padding-right: 10px;"><img style="max-width: 24px; position: absolute; bottom: 15px;" src="assets/images/Notifications/chat.png" />
                </mat-icon>
                <h3 style="float: left;">Received Messages</h3>

                <span style="float: right; right: 20px; left: auto;" class="notification-number mat-bg-warn">{{ notificationService.Messages.length  }}</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list class="notification-list" role="list" style="max-height: 500px !important; overflow-y: auto !important;">
            <mat-list-item *ngFor="let item of notificationService.Messages" class="notific-item" role="listitem">
                <div class="mat-list-text">
                    <h3 mat-line>
                        <b>{{ item.User }}</b>
                    </h3>
                    <p mat-line>{{ item.Message }}</p>
                </div>
            </mat-list-item>
        </mat-nav-list>
        <div class="text-center mt-1" *ngIf="notificationService.Messages.length">
            <small>
        <div class="full-width" style="cursor: pointer;" (click)="removeNotificationsMessages()">
          Clear All Messages Notifications
        </div>
      </small>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel *ngFor="let group of GroupNotifications" style="margin: 10px !important; padding: 5px !important;">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon mat-list-icon style="float: left; padding-right: 10px;"><img style="max-width: 24px; position: absolute; bottom: 15px;" src="assets/images/Notifications/{{ group.Data[0].Type }}.png" /></mat-icon>
                <h3 style="float: left;">{{ group.Name }}</h3>

                <span style="float: right; right: 20px; left: auto;" class="notification-number mat-bg-warn">{{ group.Data.length }}</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list class="notification-list" role="list" style="max-height: 500px !important; overflow-y: auto !important;">
            <div *ngFor="let item of group.Data" class="notific-item" style="margin: 10px;">
                <div (click)="zoomToAlert(item)" style="cursor: pointer;">
                    <h3 mat-line>
                        <b class="m-05"> {{ item.Title }}</b>
                    </h3>

                    <span class=" .m-033" mat-line>{{ item.Body }},<p style="color: rgb(48, 134, 204);">click to respond</p>
          </span>



                </div>
                <hr>
            </div>
        </mat-nav-list>
        <div class="text-center mt-1" *ngIf="group.Data.length">
            <small>
        <div class="full-width" style="cursor: pointer;" (click)="removeNotificationsGroup(group)">
          Clear All {{ group.Name }} Notifications
        </div>
      </small>
        </div>
    </mat-expansion-panel>

 </mat-accordion></div>