<mat-expansion-panel *ngIf="roadClousures.length>0"  style="margin: 10px !important; padding: 5px !important;">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-icon mat-list-icon style="float: left; padding-right: 10px;"><img style="max-width: 24px; position: absolute; bottom: 15px;" src="{{Icon}}" /></mat-icon>
            <h3 style="float: left;">Active Road Clousures</h3>

            <span style="float: right; right: 20px; left: auto;" class="notification-number mat-bg-warn">{{ roadClousures.length }}</span>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-nav-list class="notification-list" role="list" style="max-height: 500px !important; overflow-y: auto !important;">
        <div *ngFor="let item of roadClousures" class="notific-item" style="margin: 10px;">
            <div >
                <span mat-line  class="m-05 full-width "  >
                  {{ item.properties[roadClosureAttributes.StartDate_Field] | date: 'dd/MM/yyyy hh:mm'}}  
                  <span (click)="zoomToAlert(item)" style="  cursor: pointer; text-align: right; float: right;"  ><mat-icon color="warn" >zoom_in</mat-icon></span>
                </span>
   
                <p color="primary" style="margin: 2px; "   class=" .m-033 full-width" ><strong>{{ item.properties[roadClosureAttributes.RoadClosureTypeName_Field]       }} </strong>
                
                </p>
                <span style="color: #27267a;">{{   item.properties[roadClosureAttributes.ClosureStatusName_Field]   }} </span>
                
               
             <p  mat-line class=" .m-033 full-width " style="margin: 2px;" >{{  item.properties[roadClosureAttributes.PurposeOfClosure_Field]  }}
             </p>  
               
     
        
            </div>
            <hr>
        </div>
    </mat-nav-list>
    <div class="text-center mt-1" *ngIf="roadClousures.length">
        <small>
    <div class="full-width" style="cursor: pointer;" (click)="removeNotificationsGroup(group)">
      Clear All Incidents Notifications
    </div>
  </small>
    </div>
</mat-expansion-panel>