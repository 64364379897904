import { Component, OnInit, ElementRef, Input, SimpleChanges, ViewChild, OnDestroy } from '@angular/core';
import { ChatUser, ChatMsg } from '../message-chat.interface';
import { MessageChatService } from '../message-chat.service';
import { GisAppSharedService } from 'app/GeoHub/GIS-Shared-Lib/services/gis-app-shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { VMapService } from 'app/GeoHub/GIS_Component/map-componants/v-map-component/v-map.service';
import { Utilites } from 'app/GeoHub/GIS-Shared-Lib/shared/shared.functions';
import { NotificationsMessage, NotificationTypeEnum } from '../../notifications/Notification.interface';
import { HubConnectionState } from '@microsoft/signalr';
import { NotificationService } from '../../notifications/notification.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { AES } from 'crypto-ts';
import { Feature } from 'ol';
import VectorSource from 'ol/source/Vector';
import { Guid } from "guid-typescript";
@Component({
  selector: 'app-chat-to-user',
  templateUrl: './chat-to-user.component.html',
  styleUrls: ['./chat-to-user.component.scss']
})
export class ChatToUserComponent implements OnInit, OnDestroy {
  @Input() data: ChatUser;
  @Input() public homeview: boolean = false;
  // sendenabled: boolean = true;
  messageValue: string = "";
  SelectedUserto: any;
  loginuser: string;
  geoMessagechecked = false;
  mapControlchecked = false;
  activeLink //= this.chatMenuButton[0];
  sendButton: boolean = true;
  @ViewChild("chatdiv") chatdiv;
  subscrip: Subscription;
  constructor(
    public messageChatService: MessageChatService,
    private gisAppSharedService: GisAppSharedService,
    public notificationService: NotificationService,
    private snack: MatSnackBar,
    private jwtAuth: JwtAuthService,
    private elementRef: ElementRef,
    public vmapService: VMapService,
    public router: Router,
  ) { }
  ngOnDestroy(): void {
    this.subscrip.unsubscribe();
  }
  ngOnInit(): void {
    this.subscrip = this.notificationService.newMessageObserver$.subscribe(Messsage => {
      if (Messsage && (Messsage.NotificationType == NotificationTypeEnum.messageChattext || Messsage.NotificationType == NotificationTypeEnum.messageChatGeo)) {
        this.chatdiv.nativeElement.scrollTop = this.chatdiv.nativeElement.scrollHeight + 100;
      }
    });
    this.loginuser = this.jwtAuth.getUser().username;
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log(this.data);
  }
  openExtent(msg) {
    this.vmapService.map.getView().fit(msg.DecrptedGeoMsg.extent);
  }
  AddGraphics(msg) {
   


    msg.DecrptedGeoMsg.features.forEach((feature ) => {
      var f:Feature= new Feature();
      f.setGeometry(feature.Geometry);
      f.setId("Chat_" +msg.Id );
      f.setProperties(feature.Properties);
      f.setStyle(feature.Style);
      (this.vmapService.GraphicLayer.layer.getSource() as VectorSource).addFeature(f);
    });


   
    // var features: Feature = new Feature();
    // features.setGeometry(fett.geometry);
    // features.setProperties(fett);
    
    // this.vmapService.GraphicLayersource.addFeatures(this.notificationService.dataMessages.DecrptedGeoMsg.features);
    ///////
    // var vectorlayer = new VectorLayer({
    //   source: new VectorSource({
    //     features: fett
    //   })
    // });
    // this.vmapService.map.addLayer(vectorlayer);
  }

  deletegraphics(msg){
  
   
  
      var source =    (this.vmapService.GraphicLayer.layer.getSource() as VectorSource);
    source.getFeatures().forEach(feature => {
        if (feature.getId().toString().includes("Chat_" +msg.Id))
          source.removeFeature(feature);
      });
   
  }
  sendMsg() {
    this.sendButton = false
    // this.sendenabled = false;
    if (
      this.notificationService.hubConnection.state ==
      HubConnectionState.Disconnected
    ) {
      this.notificationService.hubConnection.start();
      this.snack.open("reconecting to server", "OK", { duration: 2000 });
      return;
    }
    var encrptedGeomsg = null;
    var k = this.jwtAuth.getUser().username + environment.encKey;
    if (this.geoMessagechecked || this.mapControlchecked) {
      var features = [];
      
      if(this.geoMessagechecked )
      this.vmapService.GraphicLayersource.getFeatures().forEach((feature: Feature) => {
        features.push({
          Geometry: feature.getGeometry(),
          Properties: feature.getProperties(),
          Style: feature.getStyle()
        });

      });

      var geomsg = {
        features: this.geoMessagechecked ? (features.length > 0 ? features : null) : null,
        extent: this.mapControlchecked ? this.vmapService.map.getView().calculateExtent() : null,
      }
      encrptedGeomsg = AES.encrypt(JSON.stringify(geomsg), k).toString();
    }

    var encrptedmsg = AES.encrypt(this.messageValue, k).toString();


    const objData: ChatMsg = {
    Id: Guid.create().toString(),
      ReciverUserId: this.data.UserName,
      TimeStamp: new Date(),
      SenderUserId: this.jwtAuth.getUser().username,
      IsSeen: 1,
      EncryptedMessage: encrptedmsg,
      GeoMsgEncrypted: encrptedGeomsg,
      MapControl: this.mapControlchecked
    }
    this.notificationService.hubConnection
      .send("chatmsg", this.data.UserName, JSON.stringify(objData, null))
      .then((response) => {
        objData.IsSeen = 2;
        this.chatdiv.nativeElement.scrollTop = this.chatdiv.nativeElement.scrollHeight;
        this.sendButton = true
        this.messageValue = "";
      })
      .catch((err) => {
        this.sendButton = true
        objData.IsSeen = 1;
        this.snack.open("Error in sending message", "OK", { duration: 2000 });
        console.log(err);
      });
    objData.decrptedtextMessage = this.messageValue;
    objData.DecrptedGeoMsg = geomsg;
    this.data.chats.push(objData);
  }
}
