import { UserRequestTypes, GISserverTypes, layerTypesEnum, ChartTypes_enum, InsightTypes_enum } from "../GeoEnums";
import { LoadDataOptions } from "../Model/GeoData.model.class";
import { Charts_Types_Interface } from "../interface/Chart_config.inteface";
import { WebLayer } from "../interface/WebMap.interface";



export const ColorsArray = ["#fcc02e", "#3366E6", "#f44336", "#e91f63", "#f6be1a", '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
'#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
'#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
'#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
'#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
'#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
'#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
'#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
'#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
'#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF', 


 
    "#00ffff",
      "#f0ffff",
 "#f5f5dc",
    "#000000",
 "#0000ff",
   "#a52a2a",
  "#00ffff",
    "#00008b",
    "#008b8b",
   "#a9a9a9",
     "#006400",
    "#bdb76b",
   "#8b008b",
      "#556b2f",
  "#ff8c00",
  "#9932cc",
    "#8b0000",
  "#e9967a",
   "#9400d3",
   "#ff00ff",
 "#ffd700",
    "#008000",
    "#4b0082",
  "#f0e68c",
  "#add8e6",
   "#e0ffff",
   "#90ee90",
 "#d3d3d3",
  "#ffb6c1",
  "#ffffe0",
  "#00ff00",
     "#ff00ff",
     "#800000",
     "#000080",
     "#808000",
     "#ffa500",
     "#ffc0cb",
     "#800080",
     "#800080",
     "#ff0000",
     "#c0c0c0",
     "#ffffff",
     "#ffff00"
 
]


export const Charts_Types:Charts_Types_Interface[]= [{name: 'Bar Chart' , type:ChartTypes_enum.bar,img:"barchart.png"},
                            {name: 'Pie Chart' , type:ChartTypes_enum.pie,img:"piechart.png"},
                            {name: 'Line Chart' , type:ChartTypes_enum.line ,img:"wavechart.png"},
                            ];
 export const InsightTypes= [{name: 'Statistics' , type:InsightTypes_enum.Statistics,img:"StatisticsInsight.png"},
                            {name: 'Tabular' , type:InsightTypes_enum.Tabular,img:"Tabularinsight.png"},
                            {name: 'Geo Insight' , type:InsightTypes_enum.GeoInsight ,img:"GeoInsight.png"},
                            ];

export const AppBasemaps = [
    { Id: "base6", Name: "OSM", img: "./assets/basemap/osm.png", Source:"https://a.tile.openstreetmap.org/",LayerType: layerTypesEnum.OSM,  ServerType: GISserverTypes.OSM,  visible: false , CesiumType: "OpenStreetMapImageryProvider" },

  
      { Id: "VisionSat", Name: "Mda World Sat", img: "./assets/basemap/googleSat.png", ServerType: GISserverTypes.ImageArcGISRest,
    LayerType:layerTypesEnum.XYZ, Source:"http://vgishub.com/mapdata/sat/{z}/{x}/{y}.png", visible: false, CesiumType: "UrlTemplateImageryProvider" },
    { Id: "VisionMap", Name: "Mda World Map", img: "./assets/basemap/googleroads.png", ServerType: GISserverTypes.ImageArcGISRest,
    LayerType:layerTypesEnum.XYZ, Source:"http://vgishub.com/mapdata/map/{z}/{x}/{y}.png", visible: false, CesiumType: "UrlTemplateImageryProvider" }
  
  ,  { Id: "base2", Name: "Stamen Terrain ", img: "./assets/basemap/Stamen_Terrain.png",LayerType: layerTypesEnum.Stamen_Terrain, 
ServerType: GISserverTypes.XYZ, Source:"http://tile.stamen.com/terrain/{z}/{x}/{y}.jpg", visible: false , CesiumType: "UrlTemplateImageryProvider"},

{ Id: "base2", Name: "Stamen Water color ", img: "./assets/basemap/Stamen_Watercolor.png",LayerType: layerTypesEnum.Stamen_Watercolor, 
ServerType: GISserverTypes.XYZ, Source:"http://tile.stamen.com/watercolor/{z}/{x}/{y}.jpg", visible: false , CesiumType: "UrlTemplateImageryProvider"},
 

{ Id: "base2", Name: "Stamen toner", img: "./assets/basemap/Stamen_toner.png",LayerType: layerTypesEnum.Stamen_toner, 
ServerType: GISserverTypes.XYZ, Source:"http://tile.stamen.com/toner/{z}/{x}/{y}.png", visible: false , CesiumType: "UrlTemplateImageryProvider"},




    { Id: "base2", Name: "gl Roads ", img: "./assets/basemap/googleroads.png",LayerType: layerTypesEnum.XYZ, 
ServerType: GISserverTypes.XYZ, Source:"https://mt1.google.com/vt/lyrs=h&x={x}&y={y}&z={z}", visible: false , CesiumType: "UrlTemplateImageryProvider"},





    { Id: "base3", Name: "Google Hybrid", img: "./assets/basemap/googleSat.png",LayerType: layerTypesEnum.XYZ, 
ServerType: GISserverTypes.XYZ, Source:"https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}", visible: false , CesiumType: "UrlTemplateImageryProvider"},
    { Id: "base4", Name: "Google Sat.  ", img: "./assets/basemap/imagery.png",LayerType: layerTypesEnum.XYZ, 
ServerType: GISserverTypes.XYZ, Source:"http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}", visible: false , CesiumType: "UrlTemplateImageryProvider"},
    { Id: "base1", Name: "Google Maps ", img: "./assets/basemap/googleroads.png",LayerType: layerTypesEnum.XYZ, 
ServerType: GISserverTypes.XYZ, Source:"https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}", visible: false , CesiumType: "UrlTemplateImageryProvider"},
    { Id: "base5", Name: "Google Terrain", img: "./assets/basemap/BingBlack.png",LayerType: layerTypesEnum.XYZ, 
ServerType: GISserverTypes.XYZ, Source:"https://mt1.google.com/vt/lyrs=t&x={x}&y={y}&z={z}", visible: false , CesiumType: "UrlTemplateImageryProvider"},

    { Id: "Bing", Name: " Bing  Road ", img: "./assets/basemap/Bingroads.png",  ServerType: GISserverTypes.BingMaps,
LayerType:layerTypesEnum.Bing, Source:"https://dev.virtualearth.net", visible: false, LAYERS: ["RoadOnDemand"] , CesiumType: "BingMapsImageryProvider"},
    { Id: "Bing", Name: " Bing  Aerial", img: "./assets/basemap/bingareal.png", ServerType: GISserverTypes.BingMaps,
LayerType:layerTypesEnum.Bing, Source:"https://dev.virtualearth.net", visible: false, LAYERS: ["Aerial"] , CesiumType: "BingMapsImageryProvider"},
    { Id: "Bing", Name: " Bing Aerial Lbl", img: "./assets/basemap/bingareallabel.png", ServerType: GISserverTypes.BingMaps,
LayerType:layerTypesEnum.Bing, Source:"https://dev.virtualearth.net", visible: false, LAYERS: ["AerialWithLabelsOnDemand"] , CesiumType: "BingMapsImageryProvider"},
    { Id: "Bing", Name: " Bing Canvas Dark ", img: "./assets/basemap/BingBlack.png", ServerType: GISserverTypes.BingMaps,
LayerType:layerTypesEnum.Bing, Source:"https://dev.virtualearth.net", visible: false, LAYERS: ["CanvasDark"] , CesiumType: "BingMapsImageryProvider"},
    { Id: "Bing", Name: " Bing Ordnance", img: "./assets/basemap/bingcanv.png", ServerType: GISserverTypes.BingMaps,
LayerType:layerTypesEnum.Bing, Source:"https://dev.virtualearth.net", visible: false, LAYERS: ["OrdnanceSurvey"] , CesiumType: "BingMapsImageryProvider"},
    { Id: "base7", Name: "World Topo", img: "./assets/basemap/topogr.png", ServerType: GISserverTypes.ImageArcGISRest,
    LayerType:layerTypesEnum.XYZ, Source:"https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}", visible: false , CesiumType: "UrlTemplateImageryProvider"},

    { Id: "base7", Name: " Kuwait Map", img: "./assets/basemap/topogr.png", ServerType: GISserverTypes.ImageArcGISRest,
    LayerType:layerTypesEnum.XYZ, Source:"https://kuwaitportal.paci.gov.kw/arcgisportal/rest/services/Hosted/KuwaitBasemap/MapServer/tile/{z}/{y}/{x}", visible: false, CesiumType: "UrlTemplateImageryProvider" },
    
    { Id: "base7", Name: "PACI  English Kuwait Map", img: "./assets/basemap/topogr.png", ServerType: GISserverTypes.ImageArcGISRest,
    LayerType:layerTypesEnum.XYZ, Source:"https://kuwaitportal.paci.gov.kw/arcgisportal/rest/services/Hosted/PACIBasemap/MapServer/tile/{z}/{y}/{x}", visible: false , CesiumType: "UrlTemplateImageryProvider"},
    { Id: "base7", Name: "PACI  English Kuwait Map", img: "./assets/basemap/topogr.png", ServerType: GISserverTypes.ImageArcGISRest,
    LayerType:layerTypesEnum.XYZ, Source:"https://kuwaitportal.paci.gov.kw/arcgisportal/rest/services/Hosted/EnglishMap/MapServer/tile/{z}/{y}/{x}", visible: false , CesiumType: "UrlTemplateImageryProvider"},

    { Id: "base7", Name: "PACI  Arabic Kuwait Map", img: "./assets/basemap/topogr.png", ServerType: GISserverTypes.ImageArcGISRest,
    LayerType:layerTypesEnum.XYZ, Source:"https://services.arcgisonline.com/arcgis/rest/services/Elevation/World_Hillshade/MapServer/tile/{z}/{y}/{x}", visible: false , CesiumType: "UrlTemplateImageryProvider"},

    { Id: "base8", Name: "World Imagery", img: "./assets/basemap/imagery.png", ServerType: GISserverTypes.ImageArcGISRest,
    LayerType:layerTypesEnum.XYZ, Source:"https://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}", visible: false , CesiumType: "UrlTemplateImageryProvider"},
    { Id: "base9", Name: "NatGeo", img: "./assets/basemap/ngeo.png", ServerType: GISserverTypes.ImageArcGISRest,
    LayerType:layerTypesEnum.XYZ, Source:"https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}", visible: false , CesiumType: "UrlTemplateImageryProvider"},
    { Id: "base10", Name: "World Shaded Relief ", img: "./assets/basemap/shaded.png", ServerType: GISserverTypes.ImageArcGISRest,
    LayerType:layerTypesEnum.XYZ, Source:"https://server.arcgisonline.com/ArcGIS/rest/services/World_Shaded_Relief/MapServer/tile/{z}/{y}/{x}", visible: false, CesiumType: "UrlTemplateImageryProvider" },
    { Id: "base11", Name: "World Street", img: "./assets/basemap/streets.png", ServerType: GISserverTypes.ImageArcGISRest,
    LayerType:layerTypesEnum.XYZ, Source:"https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}", visible: false, CesiumType: "UrlTemplateImageryProvider" },
    { Id: "base12", Name: "World Terrain", img: "./assets/basemap/googterr.png", ServerType: GISserverTypes.ImageArcGISRest,
    LayerType:layerTypesEnum.XYZ, Source:"https://server.arcgisonline.com/ArcGIS/rest/services/World_Terrain_Base/MapServer/tile/{z}/{y}/{x}", visible: false , CesiumType: "UrlTemplateImageryProvider"},
    { Id: "base1", Name: "  World Ocean  ", img: "./assets/basemap/oce.png", ServerType: GISserverTypes.ImageArcGISRest,
    LayerType:layerTypesEnum.XYZ, Source:"https://server.arcgisonline.com/ArcGIS/rest/services/Ocean/World_Ocean_Base/MapServer/tile/{z}/{y}/{x}", visible: false , CesiumType: "UrlTemplateImageryProvider"},
    {
        Id: "Herebase1",  img: "./assets/basemap/here.png",     ServerType: GISserverTypes.Here, 
LayerType:layerTypesEnum.Here,   visible: false ,
       Name: 'Here normal ',
       Source  :'https://{1-4}.base.maps.ls.hereapi.com' +
       '/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png' +
       '?app_id=HereAppID&apiKey=HEREappKEY'
       , CesiumType: "UrlTemplateImageryProvider" },
     {
       Id: "Herebase2",  img: "./assets/basemap/here4.png", ServerType: GISserverTypes.Here, 
LayerType:layerTypesEnum.Here,   visible: false ,
       Name: 'Here normal transit' ,
       Source  :'https://{1-4}.base.maps.ls.hereapi.com' +
       '/maptile/2.1/maptile/newest/normal.day.transit/{z}/{x}/{y}/256/png' +
       '?app_id=HereAppID&apiKey=HEREappKEY'
       , CesiumType: "UrlTemplateImageryProvider" },
     {
    Id: "Herebase3",  img: "./assets/basemap/osm.png", ServerType: GISserverTypes.Here, 
LayerType:layerTypesEnum.Here,   visible: false ,
       Name: 'Here pedestrian ',
       Source  :'https://{1-4}.base.maps.ls.hereapi.com' +
       '/maptile/2.1/maptile/newest/pedestrian.day/{z}/{x}/{y}/256/png' +
       '?app_id=HereAppID&apiKey=HEREappKEY'
       , CesiumType: "UrlTemplateImageryProvider"},
     {
       Id: "Herebase4",  img: "./assets/basemap/here5.png", ServerType: GISserverTypes.Here, 
LayerType:layerTypesEnum.Here,   visible: false ,
       Name: 'Here terrain ',
       Source  :'https://{1-4}.aerial.maps.ls.hereapi.com' +
       '/maptile/2.1/maptile/newest/terrain.day/{z}/{x}/{y}/256/png' +
       '?app_id=HereAppID&apiKey=HEREappKEY'
       , CesiumType: "UrlTemplateImageryProvider"},
     {
       Id: "Herebase5",  img: "./assets/basemap/here3.png", ServerType: GISserverTypes.Here, 
LayerType:layerTypesEnum.Here,   visible: false ,
       Name: 'Here satellite ',
       Source  :'https://{1-4}.aerial.maps.ls.hereapi.com' +
       '/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/png' +
       '?app_id=HereAppID&apiKey=HEREappKEY'
       , CesiumType: "UrlTemplateImageryProvider"},
     {
       Id: "Herebase6",  img: "./assets/basemap/here6.png", ServerType: GISserverTypes.Here, 
LayerType:layerTypesEnum.Here,   visible: false ,
       Name: 'Here hybrid ',
       Source  :'https://{1-4}.aerial.maps.ls.hereapi.com' +
       '/maptile/2.1/maptile/newest/hybrid.day.transit/{z}/{x}/{y}/256/png' +
       '?app_id=HereAppID&apiKey=HEREappKEY'
       , CesiumType: "UrlTemplateImageryProvider"} ,
         //----------------------------------------------------
         //As Added Base Maps form offline resource
         { Id: "VisionSat", Name: "Mda World Sat", img: "./assets/basemap/googleSat.png", ServerType: GISserverTypes.ImageArcGISRest,
         LayerType:layerTypesEnum.XYZ, Source:"http://vgishub.com/mapdata/sat/{z}/{x}/{y}.png", visible: false, CesiumType: "UrlTemplateImageryProvider" },
         { Id: "VisionMap", Name: "Mda World Map", img: "./assets/basemap/googleroads.png", ServerType: GISserverTypes.ImageArcGISRest,
         LayerType:layerTypesEnum.XYZ, Source:"http://vgishub.com/mapdata/map/{z}/{x}/{y}.png", visible: false, CesiumType: "UrlTemplateImageryProvider" }
];

export const KNPCAppBasemaps = [
    { Id: "base6", Name: "OSM", img: "./assets/basemap/osm.png", Source: "https://a.tile.openstreetmap.org/", LayerType: layerTypesEnum.OSM, ServerType: GISserverTypes.OSM, visible: false, CesiumType: "OpenStreetMapImageryProvider" },
   
    {
        Id: "base3", Name: "Google Hybrid", img: "./assets/basemap/googleSat.png", LayerType: layerTypesEnum.XYZ,
        ServerType: GISserverTypes.XYZ, Source: "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}", visible: false, CesiumType: "UrlTemplateImageryProvider"
    },
    {
        Id: "base4", Name: "Google Sat.  ", img: "./assets/basemap/imagery.png", LayerType: layerTypesEnum.XYZ,
        ServerType: GISserverTypes.XYZ, Source: "http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}", visible: false, CesiumType: "UrlTemplateImageryProvider"
    },
    {
        Id: "base1", Name: "Google Maps ", img: "./assets/basemap/googleroads.png", LayerType: layerTypesEnum.XYZ,
        ServerType: GISserverTypes.XYZ, Source: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}", visible: false, CesiumType: "UrlTemplateImageryProvider"
    },
   

];
export const EPSGList = [
 

    {
        Value: "EPSG:3857",
        proj: "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext +no_defs",
        Name: "WGS 84 3857 / Pseudo-Mercator",
    },
    {
        Value: "EPSG:26713",
        proj: "+proj=utm +zone=13 +datum=NAD27 +units=m +no_defs",
        Name: "EPSG:26713",
    },
 
    {
        Value: "EPSG:4326",
        proj: "+proj=longlat +datum=WGS84 +no_defs",
        Name: "WGS 84  4326",
    },
    {
        Name: "KUDAMS / KTM 31901",
        Value: "EPSG:31901",
         proj: "+proj=tmerc +lat_0=0 +lon_0=48 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=-20.8,11.3,2.4,0,0,0,0 +units=m +no_defs"
    },
    { Name: "WGS 84 / UTM zone 39N", Value: "EPSG:32639", proj: "+proj=utm +zone=39 +datum=WGS84 +units=m +no_defs" },
    { Name: "WGS 84 / UTM zone 38N", Value: "EPSG:32638", proj: "+proj=utm +zone=38 +datum=WGS84 +units=m +no_defs" },
    { Name: "	WGS84 / Google Mercator ", Value: "EPSG:102113", proj: null },
    { Name: "	EPSG:26711", Value: "EPSG:26711", proj: "+proj=utm +zone=11 +datum=NAD27 +units=m +no_defs" },
    { Name: "Google Web Mercator 900913 ", Value: "EPSG:900913", proj: null },
    {
        Value: "32633",
        proj: "+proj=utm +zone=33 +datum=WGS84 +units=m +no_defs",
        Name: "WGS 84 / UTM zone 33N"
    }
    ,
    {
        Value: "EPSG:27700",
        proj: "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs",
        Name: "OSGB 1936  27700/ British National Grid",
    },
    {
        Value: "EPSG:2154",
        proj: "+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
        Name: "RGF93 2154/ Lambert-93",
    },
    {
        Value: "EPSG:5514",
        proj: "+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 +alpha=30.28813972222222 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=589,76,480,0,0,0,0 +units=m +no_defs",
        Name: "S-JTSK 5514/ Krovak East North"
    },
    {
        Value: "EPSG:21781",
        proj: "+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +towgs84=674.4,15.1,405.3,0,0,0,0 +units=m +no_defs",
        Name: "CH1903 21781/ LV03",
    },
    {
        Value: "EPSG:27200",
        proj: "+proj=nzmg +lat_0=-41 +lon_0=173 +x_0=2510000 +y_0=6023150 +ellps=intl +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +units=m +no_defs",
        Name: "NZGD49 27200/ New Zealand Map Grid",
    },
    {
        Value: "EPSG:31370",
        proj: "+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.869,52.2978,-103.724,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs",
        Name: "Belge 1972 / Belgian Lambert 72",
    },
    {
        Value: "EPSG:28992",
        proj: "+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 +units=m +no_defs",
        Name: "Amersfoort / RD New",
    },
    {
        Value: "EPSG:3067",
        proj: "+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
        Name: "ETRS89 / TM35FIN(E,N)",
    } ,   {
        Value: "EPSG:31287",
        proj: "+proj=lcc +lat_1=49 +lat_2=46 +lat_0=47.5 +lon_0=13.33333333333333 +x_0=400000 +y_0=400000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.4232 +units=m +no_defs",
        Name: "EPSG:31287",
    }
]
export const GeodataloadConfig = {
    datasource: [
        new LoadDataOptions({ name: "My Contents", requestType: UserRequestTypes.get_UserContents, source: "usercontents", ServerType: GISserverTypes.NONE, defaultValue: "" }),
        new LoadDataOptions({ name: "GeoHub Online", requestType: UserRequestTypes.get_geoHubonlineResource, source: "defultServerLayers", ServerType: GISserverTypes.NONE, defaultValue: "" }),
        new LoadDataOptions({ name: "A WMS OGC Web Service", requestType: UserRequestTypes.get_geoserver_Data, source: "url", ServerType: GISserverTypes.WMS, defaultValue: "https://ahocevar.com/geoserver/wms" }),
         new LoadDataOptions({ name: "A WMTS OGC Web Service", requestType: UserRequestTypes.get_geoserver_Data, source: "url", ServerType: GISserverTypes.WMTS, defaultValue: "https://ahocevar.com/geoserver/wms" }),
         new LoadDataOptions({ name: "A WFS OGC Web Service", requestType: UserRequestTypes.get_geoserver_Data, source: "url", ServerType: GISserverTypes.WFS, defaultValue: "https://ahocevar.com/geoserver/wms" }),
        new LoadDataOptions({ name: "ESRI-  ArcGIS Server ", requestType: UserRequestTypes.get_arcgisserverSite, source: "url", ServerType: GISserverTypes.arcgis, defaultValue: "https://services1.arcgis.com/TTvncVdUSWQb0dKy/ArcGIS/rest/services" }),

        new LoadDataOptions({ name: "Add Layer from File ", requestType: UserRequestTypes.File, source: "file", ServerType: GISserverTypes.geojson, defaultValue: "" }), 

        new LoadDataOptions({ name: "QGIS GeoPackage", requestType: UserRequestTypes.load_geopackage, source: "file", ServerType: GISserverTypes.geopackage, defaultValue: "" }),
    
        new LoadDataOptions({ name: "connection PostGIS Database", requestType: UserRequestTypes.Connect_postgis, source: "con", ServerType: GISserverTypes.postgis, defaultValue: "" }),
        new LoadDataOptions({ name: "connection SDE Database", requestType: UserRequestTypes.Connect_sde, source: "con", ServerType: GISserverTypes.sde, defaultValue: "" }),
        new LoadDataOptions({ name: "connection MS-SQL Server Database", requestType: UserRequestTypes.Connect_mssql, source: "con", ServerType: GISserverTypes.mssql, defaultValue: "" }),
        new LoadDataOptions({ name: "Full ArcGIS Server", requestType: UserRequestTypes.LoadArcGisServer, source: "url", ServerType: GISserverTypes.arcgis, defaultValue: "https://services1.arcgis.com/TTvncVdUSWQb0dKy/ArcGIS/rest/services" },
        )
    ],
    //  new LoadDataOptions({ name: "ArcGIS map service", requestType:  UserRequestTypes.get_arcgismapservice , source: "url" , ServerType:GISserverTypes.arcgis  , defaultValue: "https://services1.arcgis.com/TTvncVdUSWQb0dKy/ArcGIS/rest/services/89Mall_Basement_WFL1/FeatureServer" }),
    //  new LoadDataOptions({ name: "ArcGIS Feature service", requestType:  UserRequestTypes.get_arcgisfeatureservice , source: "url" , ServerType:GISserverTypes.arcgis  , defaultValue: "https://services1.arcgis.com/TTvncVdUSWQb0dKy/ArcGIS/rest/services/89Mall_Basement_WFL1/FeatureServer/0" }),
    defultServerLayers: [
        { name: "Kuwait Geoserver1", url: "http://gis1.geoserver.visionkuwait.com/geoserver/wms" },
        { name: "Kuwait Geoserver2", url: "http://vgishub.com/geoserver/wms" },
        { name: "Public ArcGIS", url: "https://services1.arcgis.com/TTvncVdUSWQb0dKy/ArcGIS/rest/services" },
        { name: "  QGIS Srver", url: "http://vgishub.com/qgisserver/paaf_farms/wms" }
    ]
};
export const OLLayerTypes = [
    {
        Name: "WMS Tile Layer", "Type": "TileLayer", source: "TileWMS", Details: "WMS Tile Layer  "
        , LayerType: layerTypesEnum.TileLayer_TileWMS
    },
    { Name: "Single Tile WMS Image", "Type": "ImageLayer", source: "ImageWMS", Details: "Single Tile WMS Image image layer source ", LayerType: layerTypesEnum.ImageLayer_ImageWMS },
    { Name: "WFS Vector Layer", "Type": "VectorLayer", source: "VectorSource", Details: "Vector Layer with Vector Source ", LayerType: layerTypesEnum.WFS },
    { Name: "WFS realtime Vector Layer", "Type": "VectorLayer", source: "VectorSource", Details: "Vector Layer with Vector Source ", LayerType: layerTypesEnum.WFS_realtime },
   
    { Name: "Vector Layer", "Type": "VectorLayer", source: "VectorSource", Details: "Vector Layer with Vector Source ", LayerType: layerTypesEnum.VectorLayer_VectorSource },
    { Name: "Vector Image Layer Vector", "Type": "VectorImageLayer", source: "VectorSource", Details: " Vector Image Layer Vector Source", LayerType: layerTypesEnum.VectorImageLayer_VectorSource },
    { Name: "Heat map Layer", "Type": "Heatmap", source: "VectorSource", Details: "Heat map Layer Vector datab Source/ layer querable ", LayerType: layerTypesEnum.Heatmap_VectorSource },
    { Name: "Graticule", "Type": "Graticule", source: "Graticule", Details: " Graticule layer ", LayerType: layerTypesEnum.Graticule_Graticule },
    { Name: "Static Image ", "Type": "ImageLayer", source: "Static", Details: "Add Static Image within Extent", LayerType: layerTypesEnum.ImageLayer_Static },
    { Name: "Tiled ArcGIS MapServer", "Type": "TileLayer", source: "TileArcGISRest", Details: "", LayerType: layerTypesEnum.TileLayer_TileArcGISRest },
    { Name: "Tile Image ArcGIS MapServer", "Type": "ImageLayer", source: "ImageArcGISRest", Details: "Image ArcGIS MapServer", LayerType: layerTypesEnum.ImageLayer_ImageArcGISRest }
];
export const BingmapsConfig = {
    types: [
        'RoadOnDemand',
        'Aerial',
        'AerialWithLabelsOnDemand',
        'CanvasDark',
        'OrdnanceSurvey'
    ], Key: "AlpxjJiFpwsK49F2r5x2gBAMWWPPqqWhh0965GhwGgHh-1h0iYZeDLcw22S3t8ex"
};
export const  Geo_Columns_Unlisted = [
    "geometry",
    "fid",
    "the_geo",
    // "objectid",
    "geo",
    "geom",
    "ftid",
    "measure_id",
    "draw_id",
    "gid",
    "the_geom",
];

export const  CoordenatesColumns = [
    ["x","y"],
    ["Longitude","Latitude"],
    ["the_geom"],
    ["geometry"],
    ["long","lat"]
   
    
   
];


