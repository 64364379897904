import { Color } from 'ol/color';
 
import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'color-dailog-component',
    templateUrl: 'color-dailog-component.html',
  })
  export class Colordailogcomponent {
  
    constructor(
      public dialogRef: MatDialogRef<Colordailogcomponent>,
      @Inject(MAT_DIALOG_DATA) 
      public data: {color:Color|string, title:string  }) {}
  
    onNoClick(): void {
    
      this.dialogRef.close();
    }
    handleChangeCompleteFill(evet){

 
        this.data.color = evet.color;
    }
  }