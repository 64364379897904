import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseWidgetComponent } from './base-widget.component';
 import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
 import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ResizableModule } from 'angular-resizable-element';
import { SharedPipesModule } from 'app/shared/pipes/shared-pipes.module';
import { TranslateModule } from '@ngx-translate/core';
 

@NgModule({
  declarations: [ BaseWidgetComponent],
  exports:[BaseWidgetComponent],
  imports: [

    SharedPipesModule,
    MatIconModule,
    MatInputModule,
    CommonModule,
     MatButtonModule,
     ResizableModule,
    PerfectScrollbarModule,
    TranslateModule
   
  ]
})
export class BaseWidgetModule { }
