import { Observable, Subject, of, throwError, BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { map, catchError } from "rxjs/operators";
import { WebApiResult } from "app/shared/models/apiresult";
import { HubConnectionBuilder, HubConnection } from "@microsoft/signalr";
import { JwtAuthService } from "app/shared/services/auth/jwt-auth.service";
import { NotificationTypeEnum } from "./Notification.interface";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ChatMsg, ChatUser } from "../message-chat/message-chat.interface";
import { VMapService } from "app/GeoHub/GIS_Component/map-componants/v-map-component/v-map.service";
 


@Injectable({
  providedIn: "root",
})
export class NotificationService {
  public notifCount = 0;
  public Messages = [];
  public notificationAccordion;
  newMessage: { NotificationType: NotificationTypeEnum, Message: any } = null;
  newMessageObserver$ = new BehaviorSubject<{ NotificationType: NotificationTypeEnum, Message: any | ChatMsg }>(this.newMessage);
  public hubConnection: HubConnection;
  public dataMessages: ChatMsg;
  public onlineusers: ChatUser[] = [];
  constructor(
    private http: HttpClient,
    private snack: MatSnackBar,
    private jwtAuth: JwtAuthService,
    public vmapService: VMapService
 

  ) {
    if(environment.enableSignalR)
this.jwtAuth.user$.subscribe(res=>{
  if(this.jwtAuth.isAuthenticated)
this. setupSignalR();
})

  }

  setupSignalR() {
    if(!environment.enableSignalR)
    return;
    if ( !this.jwtAuth.isAuthenticated )
      return;

    var url = environment.apiURL + "/api/NotificationHub";


    if (environment.DomainAuth) {

      this.hubConnection = new HubConnectionBuilder()
        .withUrl(url, {}
        ).withAutomaticReconnect()
        .build();

    }
    else {
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(url, {
          accessTokenFactory: () => this.jwtAuth.getJwtToken()

        }).withAutomaticReconnect()
        .build();
    }

    var self = this;

    this.hubConnection
      .start()
      .then(() => {
        //   console.log("Connection started");
        self.liveStreeming();
      })
      .catch((err) => {
        //   console.log("Error while starting connection: " + err);
      });
  }

  public liveStreeming() {
    this.hubConnection.onreconnecting((message: any) => {
      this.snack.open("Reconnecting", "Dismiss", { duration: 2000 });
      // console.log(message);
    });
    this.hubConnection.onreconnected((message: any) => {
      this.snack.open("Connected", "Dismiss", { duration: 2000 });
      console.log(message);
    });
    this.hubConnection.onclose((message: any) => {
      // this.snack.open("Connection closed ", "Dismiss", { duration: 2000 });
      console.log(message);
    });

    this.hubConnection.on("BroadcastEvent", (message: any) => { });
    this.hubConnection.on(
      "geochatmsgs",
      (
        NotificationType: NotificationTypeEnum,
        param1: any,
        param2: any
      ) => {
        try {
          if (!this.notifCount)
            this.notifCount = 0;
        } catch { }
    
        var dataMessages = JSON.parse(param2);
        switch (NotificationType) {

          case NotificationTypeEnum.OnlineUsers: {
            this.onlineusers = JSON.parse(param2);
            this.newMessageObserver$.next({ NotificationType: NotificationType, Message: null });
            break;
          }

          case NotificationTypeEnum.useroffline: {
            break;
          }
          case NotificationTypeEnum.messageChatGeo: {
 
            this.newMessageObserver$.next(dataMessages);
            this.notifCount += 1;

            break;
          }
          case NotificationTypeEnum.messageChattext: {
            var nsg: ChatMsg = JSON.parse(param2);
            this.notifCount += 1;

            this.newMessageObserver$.next({ NotificationType: NotificationType, Message: dataMessages });


            //}
            break;
          }
          case NotificationTypeEnum.UserJoinOnline: {
            console.log(param2 + "  online");
            this.Messages.push({ User: param1, Message: "  Online  at   " + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) });
            this.newMessageObserver$.next({ NotificationType: NotificationType, Message: { param1: param1, param2: param2 } });


            break;
          }
          case NotificationTypeEnum.useroffline: {
            console.log(param2 + "  online");
            this.Messages.push({ User: param1, Message: "  offline  at   " + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) });
            this.newMessageObserver$.next({ NotificationType: NotificationType, Message: { param1: param1, param2: param2 } });


            break;
          }
          default: {

            break;
          }
        }
      }
    );


    this.hubConnection.on(
      "messageReceived",
      (
        NotificationType: NotificationTypeEnum,
        param1: string,
        param2: string
      ) => {
        try {
          if (!this.notifCount)
            this.notifCount = 0;
        } catch { }
        this.notifCount += 1;
        switch (NotificationType) {
          case NotificationTypeEnum.AddedIncident: {
            this.snack.open(param2, "Dismiss", {
              horizontalPosition: "right",
              verticalPosition: "top",
              duration: 4000,
            });


            this.Messages.push({ User: param1, Message: param2 });
            this.newMessageObserver$.next({ NotificationType: NotificationTypeEnum.Announcement, Message: param2 });
            this.newMessageObserver$.next({ NotificationType: NotificationTypeEnum.AddedIncident, Message: param2 });
            this.notifCount += 1;
            break;
          }
          case NotificationTypeEnum.UserJoinOnline: {
            console.log(param1 + "  online");
            this.Messages.push({ User: param1, Message: "  Online  at   " + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) });
            this.newMessageObserver$.next({ NotificationType: NotificationType, Message: { param1: param1, param2: param2 } });


            break;
          }
          case NotificationTypeEnum.useroffline: {
            console.log(param2 + "  online");
            this.Messages.push({ User: param1, Message: "  offline  at   " + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) });
            this.newMessageObserver$.next({ NotificationType: NotificationType, Message: { param1: param1, param2: param2 } });


            break;
          }
          case NotificationTypeEnum.Announcement: {
            if (param1 != this.jwtAuth.getUser().username) {
              this.snack.open(param2, "Dismiss", {
                horizontalPosition: "right",
                verticalPosition: "top",
                duration: 6000,
              });
              this.notifCount += 1;
              this.Messages.push({ User: param2, Message: param2 });
              this.newMessageObserver$.next({ NotificationType: NotificationTypeEnum.Announcement, Message: param2 });
            }
            break;
          }
          case NotificationTypeEnum.FireAlarm: {
            this.snack.open("New Fire Alarm", "Dismiss", {
              horizontalPosition: "right",
              verticalPosition: "top",
              duration: 4000,
            });
            this.notifCount += 1;
            break;
          }
          case NotificationTypeEnum.GroupInvite: {
            this.snack.open(
              "you have been invited to join group " +
              param2 +
              " by " +
              param1,
              "Dismiss",
              {
                horizontalPosition: "right",
                verticalPosition: "top",
                duration: 4000,
              }
            );  
              this.notifCount += 1;
            break;
          }
          case NotificationTypeEnum.GroupRequest: {
            this.snack.open(
              "group join resquest sent by " +
              param1 +
              " to Join group " +
              param2,
              "Dismiss",
              {
                horizontalPosition: "right",
                verticalPosition: "top",
                duration: 4000,
              }
            );
            this.notifCount += 1;

            break;
          }
          case NotificationTypeEnum.RoadClousre: {
            this.snack.open("New Road Clousre added ", "Dismiss", {
              horizontalPosition: "right",
              verticalPosition: "top",
              duration: 4000,
            });
            this.notifCount += 1;

            this.Messages.push({ User: param1, Message: param2 });
            this.newMessageObserver$.next({ NotificationType: NotificationTypeEnum.Announcement, Message: param2 });
            this.newMessageObserver$.next({ NotificationType: NotificationTypeEnum.RoadClousre, Message: param2 });
            break;
          }
          default: {
            // this.snack.open(sender + " Saying:  " + message, "Dismiss", {
            //   horizontalPosition: "right",
            //   verticalPosition: "top",
            // });
            // this.Messages.push({ User: sender, Message: message });
            break;
          }
        }
      }
    );
  }

  public GetAllNotification() {

    try {
      if (!this.notifCount)
        this.notifCount = 0;
    } catch { }
 
    return this.http
      .get(`${environment.apiURL}/api/Notifications/getallNotification`)
      .pipe(
        map((res: WebApiResult) => {
          if (res.IsSuccess) {
            this.notifCount = res.data.length;
 
            return res;
          } else {
            return res;
          }
        }),
        catchError((error) => {
          return of(error);
        })
      );
  }


  public GroupRequestsUpdate(Id: string, accept: string) {
    return this.http
      .get(`${environment.apiURL}/api/Groups/AcceptRejectGroup?Id=${Id}&accept=${accept}`)
      .pipe(
        map((res: WebApiResult) => {

          return res;

        }),
        catchError((error) => {
          return of(error);
        })
      );
  }

  getPortalUsers() {

    return this.http.get(`${environment.apiURL}/api/User/getPortalUsers`)
      .pipe(

        map((res: WebApiResult) => {

          return res;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }


}
