import {  environment } from './environment';

import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { BehaviorSubject } from 'rxjs';
import { Portalconfiguration } from 'app/views/Admin/admin.interface';

@Injectable({
  providedIn: "root",
})

export class globalConfigService {


  appConfigration: any = null;
  appConfigration$ = (new BehaviorSubject<any>(this.appConfigration));

  constructor(private http: HttpClient) {

    this.load();
  }

  public load() {
    const jsonFile = "./assets/configurations/config.json";

    return this.http.get(jsonFile).pipe(
      map((response: any) => {
        environment.apiURL = response.apiURL;
       
      
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );

  }
}