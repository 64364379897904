<!-- <p>chat-to-user works!</p> -->
<div class="full-width">
  <div
    fxLayout="row"
    style="display: flex; background-color: #706d6d"
    class="full-width"
  >
    <button mat-icon-button (click)="messageChatService.Chathomeview = true">
      <mat-icon style="color: white">keyboard_arrow_left </mat-icon>
    </button>

    <button
      style="
        background-color: transparent;
        color: white;
        text-align: left;
        text-transform: capitalize;
      "
      mat-flat-button
      color="default"
      class="full-width"
    >
      {{ data.FullName }}
    </button>

    <button [matTooltip]="data.UserName" mat-mini-fab color="default">
      <span style="text-transform: uppercase">
        {{ data.UserName | slice: 0:2 }}
      </span>
    </button>
  </div>
  <div #chatdiv class="chatmassege">
    <div
      *ngFor="let item of data.chats"
      class="messageitem"
      [style.direction]="item.SenderUserId != loginuser ? 'rtl' : 'ltr'"
    >
      <div
        class="message-in full-width"
        fxLayout="row"
        *ngIf="
          item.SenderUserId != loginuser &&
          item.DecrptedGeoMsg != null &&
          (item.DecrptedGeoMsg.extent != null ||
            item.DecrptedGeoMsg.features != null)
        "
      >
        <div class="full-width">
          <button
            *ngIf="
              item.DecrptedGeoMsg != null && item.DecrptedGeoMsg.extent != null
            "
            matTooltip="Get Extent"
            (click)="openExtent(item)"
            class="btnmsgIN"
            mat-mini-fab
            color="accent"
          >
            <mat-icon style="font-size: 16px">center_focus_weak </mat-icon>
          </button>

          <button
            *ngIf="
              item.DecrptedGeoMsg != null &&
              item.DecrptedGeoMsg.features != null
            "
            matTooltip="View Features"
            (click)="AddGraphics(item)"
            class="btnmsgIN"
            mat-mini-fab
            color="primary"
          >
            <mat-icon style="font-size: 16px">filter_b_and_w</mat-icon>
          </button>
          <button
            *ngIf="
              item.DecrptedGeoMsg != null &&
              item.DecrptedGeoMsg.features != null
            "
            matTooltip="View Features"
            (click)="deletegraphics(item)"
            class="btnmsgIN"
            mat-mini-fab
            color="warn"
          >
            <mat-icon style="font-size: 16px">delete</mat-icon>
          </button>

          <div class="full-width">
            <span style="font-size: 8px; float: left">
              {{ item.TimeStamp | date: "HH:mm" }}</span
            >
          </div>
        </div>
      </div>
      <div
        class="message-in full-width"
        fxLayout="row"
        *ngIf="item.SenderUserId != loginuser && item.decrptedtextMessage != ''"
      >
        <div class="full-width">
          <div class="full-width">
            <p class="mat-list-text" style="margin-left: 3px">
              {{ item.decrptedtextMessage }}
            </p>
            <span style="font-size: 8px; float: left">
              {{ item.TimeStamp | date: "HH:mm" }}</span
            >
          </div>
        </div>
      </div>

      <div
        class="message-out full-width"
        fxLayout="row"
        *ngIf="
          item.SenderUserId == loginuser &&
          item.DecrptedGeoMsg != null &&
          (item.DecrptedGeoMsg.extent != null ||
            item.DecrptedGeoMsg.features != null)
        "
      >
        <div class="full-width">
          <button
            *ngIf="
              item.DecrptedGeoMsg != null && item.DecrptedGeoMsg.extent != null
            "
            matTooltip="Get Extent"
            (click)="openExtent(item)"
            class="btnmsgOUT"
            mat-mini-fab
            color="accent"
          >
            <mat-icon style="font-size: 16px">center_focus_weak </mat-icon>
          </button>

          <button
            *ngIf="
              item.DecrptedGeoMsg != null &&
              item.DecrptedGeoMsg.features != null
            "
            matTooltip="View Features"
            (click)="AddGraphics(item)"
            class="btnmsgOUT"
            mat-mini-fab
            color="primary"
          >
            <mat-icon style="font-size: 16px">filter_b_and_w</mat-icon>
          </button>
          <button
            *ngIf="
              item.DecrptedGeoMsg != null &&
              item.DecrptedGeoMsg.features != null
            "
            matTooltip="View Features"
            (click)="deletegraphics(item)"
            class="btnmsgOUT"
            mat-mini-fab
            color="warn"
          >
            <mat-icon style="font-size: 16px">delete</mat-icon>
          </button>
          <div class="full-width">
            <span style="font-size: 10px; float: right">
              {{ item.TimeStamp | date: "HH:mm" }}</span
            >

            <span
              class="MsgSeen"
              [style.color]="
                item.IsSeen == 1 || item.IsSeen == 2 ? 'grey' : 'blue'
              "
            >
              {{ item.IsSeen < 2 ? "&#10003;" : "&#10003;&#10003;" }}
            </span>
          </div>
        </div>
      </div>
      <div
        class="message-out full-width"
        fxLayout="row"
        *ngIf="item.SenderUserId == loginuser && item.decrptedtextMessage != ''"
      >
        <div class="full-width">
          <div class="full-width">
            <p class="mat-list-text" style="margin-left: 3px">
              {{ item.decrptedtextMessage }}
            </p>

            <span style="font-size: 10px; float: right">
              {{ item.TimeStamp | date: "HH:mm" }}</span
            >

            <span
              class="MsgSeen"
              [style.color]="
                item.IsSeen == 1 || item.IsSeen == 2 ? 'grey' : 'blue'
              "
            >
              {{ item.IsSeen < 2 ? "&#10003;" : "&#10003;&#10003;" }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    fxLayout="column"
    class="full-width"
    style="position: absolute; bottom: 0px"
  >
    <div fxLayout="row" style="display: flex" class="full-width">
      <mat-form-field appearance="outline" fxFlex="80%">
        <mat-label><strong>Type your Message</strong> </mat-label>
        <textarea
          placeholder="Type your Message"
          style="resize: none"
          [(ngModel)]="messageValue"
          matInput
          (keyup.enter)="sendMsg()"
        ></textarea>
      </mat-form-field>

      <div fxFlex="20%" style="display: block; margin-top: 16px">
        <button
          style="height: 75%"
          mat-flat-button
          color="accent"
          (click)="sendMsg()"
        >
          <!-- *ngIf="sendenabled" -->
          <mat-icon mat-list-icon>send </mat-icon>
        </button>
      </div>
    </div>
    <div
      class="divCenter"
      *ngIf="router.url.toLowerCase().indexOf('/map') > -1"
    >
      <mat-checkbox class="check-margin" [(ngModel)]="geoMessagechecked"
        >Send GeoMessage
      </mat-checkbox>
      <mat-checkbox class="check-margin" [(ngModel)]="mapControlchecked"
        >Map Control
      </mat-checkbox>
    </div>
  </div>
</div>
