import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EPSGList } from 'app/GeoHub/GIS-Shared-Lib/const/Const.config';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-epsg-selector',
  templateUrl: './epsg-selector.component.html',
  styleUrls: ['./epsg-selector.component.scss']
})
export class EpsgSelectorComponent implements OnInit {
  SelectedEPSG: any;
  currentmapprojection:string;
  public EPSGList: any[];
   showlayername: boolean;
  constructor(public dialogRef: MatDialogRef<EpsgSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.EPSGList = EPSGList;

  
    this.showlayername=this.data.layername?true:false
    this.currentmapprojection=environment.mapDefaultProjection
    this.EPSGList.forEach(element => {
      if (element.Value == environment.mapDefaultProjection) {
        this.SelectedEPSG = element;
        return;
      }
    });
  }
  close() {

    this.dialogRef.close( { EPSG: this.SelectedEPSG.Value ,layername: this.data.layername});
  }
}
