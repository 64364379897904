 


 
 
  <color-sketch style=" z-index: 10;" [color]="data.color"    
  (onChangeComplete)="handleChangeCompleteFill($event)"   ></color-sketch>
 
 
  <button mat-button  mat-flat-button  (click)="onNoClick()" color="default">Cancel</button>
  <button mat-button  mat-flat-button  color="primary" [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
 