<div  >
<button mat-icon-button   (click)="open()" *ngIf="!isOpen">
  <mat-icon>search</mat-icon>
</button>
 
 <div class="search-bar"[ngClass]="{ open: isOpen }" *ngIf="isOpen">

  <div class="top-search-form" >
   
    <input [formControl]="searchCtrl"  style="width: 85%;    height: 8px;" #search type="text" [placeholder]="placeholder" (keyup)="Search($event)" />
    <mat-icon (click)="close()"  class="search-close text-muted" >close</mat-icon>
  </div>

</div>  
 



</div>