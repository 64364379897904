<div class="app-admin-wrap" [dir]='layoutConf?.dir'  *ngIf="configloaded">
  <!-- Header for top navigation layout -->
  <!-- ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT -->
  <app-header-top  class="mat-bg-primary mat-color-primary" 
  [notificPanel]="notificationPanel"
    >
  </app-header-top>
  <!-- Main Container -->
  <mat-sidenav-container 
  [dir]='layoutConf.dir'
  class="app-admin-container app-side-nav-container mat-drawer-transition sidebar-{{layoutConf?.sidebarColor}} topbar-{{layoutConf?.topbarColor}} footer-{{layoutConf?.footerColor}}"
  [ngClass]="adminContainerClasses">
    <mat-sidenav-content>
 
    
    <!-- Top navigation layout (navigation for mobile screen) -->
    <!-- ONLY REQUIRED FOR **TOP** NAVIGATION MOBILE LAYOUT -->
    <app-sidebar-top *ngIf="  layoutConf.isMobile"></app-sidebar-top>

    <!-- App content -->
    <div class="main-content-wrap" id="main-content-wrap" [perfectScrollbar]="" [disabled]="layoutConf.topbarFixed || !layoutConf.perfectScrollbar">
      <!-- Header for side navigation layout -->
   

      <div class="rightside-content-hold containerheight" id="rightside-content-hold" [perfectScrollbar]="scrollConfig" [disabled]="!layoutConf.topbarFixed || !layoutConf.perfectScrollbar">
        <!-- View Loader -->
        <div class="view-loader" *ngIf="isModuleLoading" style="position:fixed; top: 50%; left: 50%;"
        fxLayout="column" fxLayoutAlign="center center">
          <div class="spinner">
            <div class="double-bounce1 mat-bg-accent"></div>
            <div class="double-bounce2 mat-bg-primary"></div>
          </div>
        </div>
        <!-- Breadcrumb -->
        <!-- Disable breadcrumb -->
        <!-- <app-breadcrumb *ngIf="" ></app-breadcrumb> -->
        <!-- View outlet -->
        <div class="container-dynamic" style=" ">
          <router-outlet></router-outlet>
        </div>
      </div>
     </div>
    <div class="sidebar-backdrop"
    [ngClass]="{'visible': layoutConf.sidebarStyle !== 'closed' && layoutConf.isMobile}"
    (click)="closeSidebar()"></div>
    </mat-sidenav-content>

    <!-- Notificaation bar -->
    <mat-sidenav #notificationPanel mode="over" class="" position="end">
      <div class="nofication-panel" fxLayout="column">
        <app-notifications></app-notifications>
        
      </div>

 
    </mat-sidenav>
 
  </mat-sidenav-container>
</div>