
export enum SearchOperatorsTypesEnum {

  Equal = "=",
  Bigger = ">",
  Less = "<",
  LessorEqual = "<=",
  BiggerorEqual = ">=",
  NotEqual = "<>",
  Contain = "Contain",
  StartWith = "Start With",
  EndWith = "End With",
  NotContain = "NotContain"
}

export const SearchOperatorsTypes = [{ name: "Equal", value: SearchOperatorsTypesEnum.Equal },
{ name: "Contain", value: SearchOperatorsTypesEnum.Contain },
{ name: "Not Equal", value: SearchOperatorsTypesEnum.NotEqual },
{ name: "Not Contain", value: SearchOperatorsTypesEnum.NotEqual },
{ name: "Start With", value: SearchOperatorsTypesEnum.StartWith },
{ name: "End With", value: SearchOperatorsTypesEnum.EndWith },
{ name: "Bigger than", value: SearchOperatorsTypesEnum.Bigger },
{ name: " Bigger than or equal ", value: SearchOperatorsTypesEnum.BiggerorEqual },
{ name: "Less than", value: SearchOperatorsTypesEnum.Less },
{ name: "Less than or Equal", value: SearchOperatorsTypesEnum.LessorEqual }];



export enum ChartTypes_enum {
  bar,
  pie,
  line


}

export enum Aggregation_Operations{
  Count='Count',
  Sum='Sum',
  Max='Max',
  min='min'

}
export enum ContentRequestTypes {

  web_MAP,
  web_APP,
  data

}




export enum InsightTypes_enum {
  Statistics,
  Tabular,
  GeoInsight

}

export enum ArcGIS_ServiceType {
  Folder,
  MapServer,
  FeatureServer,
  ImageLayer,
  FeatureLayer = 4,

}

export enum UserRequestTypes {

  NONE = 1000,
  get_UserContents = 17,
  get_geoHubonlineResource = 1,
  get_geoserver_Data = 2,
  get_geoserverlayer = 3,
  get_qgis_data = 4,
  get_arcgisserverSite = 5,
  get_arcgismapservice = 6,
  get_arcgisfeatureservice = 7,
  load_geopackage = 8,
  File = 9,
  load_csv = 10,
  load_excel = 11,
  load_geojson = 12,
  load_txt = 13,
  Connect_postgis = 14,
  Connect_sde = 15,
  Connect_mssql = 16,
  LoadArcGisServer = 17,
  get_WMS = 22,
  get_WMTS = 23,
  get_WFS = 24,
  get_ArcGIS = 24,
}
export enum layerTypesEnum {
    OSM = 1,
    Bing = 2,
    google = 3,
    TileLayer_TileWMS = 4,
    ImageLayer_ImageWMS = 5,
    VectorLayer_VectorSource = 6,
    VectorLayer_GeoJSON = 7,
    VectorImageLayer_VectorSource = 8,
    Heatmap_VectorSource = 9,
    Graticule_Graticule = 10,
    ImageLayer_Static = 11,
    TileLayer_TileArcGISRest = 12,
    ImageLayer_ImageArcGISRest = 13,
    ImageArcGISRestXYZ = 14,
    XYZ = 56,
    Here = 58,
    arcgisFeatrueLayer = 21,
    WFS_realtime = 59,
    WFS = 57,
    WMTS = 60,
    GraphicLayer = 100,
    MultiFeatureServer = 101,
    Stamen_Watercolor = 103,
    Stamen_Terrain = 104,
    Stamen_toner = 105,
    Stamen_mars=106,
    Stamen_treescabscrime=107
}
export enum esrilayerTypesEnum {
OGC,
WMS
}
export enum MapControlEvents {

  MapLoaded

}
export enum GisServerCategory {
  WMS,
  ArcGIS,
  Google,
  Bing,
  GraphicLayer,
  OSM,
  GeoJSON,
  Here,
}
export enum GISserverTypes {
  NONE = 0,
  OSM = 1,
  GraphicLayer = 2,
  google = 3,

  geoserver = 4,
  qgis = 5,
  ImageArcGISRest = 6,
  arcgis = 7,
  geopackage = 8,
  shapefile = 9,
  csv = 10,
  excel = 11,
  geojson = 12,
  txt = 13,
  postgis = 14,
  sde = 15,
  mssql = 16,
  XYZ = 17,

  carmentaserver = 18,
  mapserver = 19,
  BingMaps = 20,
  arcgisFeatrueLayer = 21,
  WMTS = 55,
  WMS = 56,
  WFS = 57,
  WFS_realtime = 59,
  Here = 58
}
export enum ItemTypesEnum {
  Maps = 1,
  App = 2, Files = 3, Layers = 4, Scenes = 5, Tools = 6, All = 0
}

export enum ShareTypeEnum {
  public = 7,
  private = 8,
  friends = 9,
  Group = 10
}
export enum JoinTypeEnum {
  Request = 11,
  Invite = 12,

}
export enum ContentTypeEnum {
  WebMap = 1,
  WebApp = 2,
  Files = 3,
  Layers = 4,
  Scenes = 5,
  Tools = 6

}

export enum BuildingFloor {
  FristFloor = 1,
  GroundFloor = 2
}
