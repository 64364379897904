import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'environments/environment';
 
import { MatSnackBar } from '@angular/material/snack-bar';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
 import { ChatMsg } from './message-chat.interface';
 
@Injectable({
  providedIn: 'root'
})
export class MessageChatService {
  // public hubConnection: HubConnection;
  public Messages = [];
  public dataMessages: ChatMsg;
  public Chathomeview: boolean=true;
   public notifCount = 0;
  constructor(
    private http: HttpClient,
    private snack: MatSnackBar,
    private jwtAuth: JwtAuthService
  ) {
 
  }
 

}
