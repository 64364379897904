import { Component, OnInit, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { JwtAuthService } from '../../services/auth/jwt-auth.service';
import { CookieService } from 'ngx-cookie-service';
import { NotificationService } from '../notifications/notification.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html'
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel;
  public availableLangs = [{
    name: 'EN',
    code: 'en',
    flag: 'flag-icon-us'
  },   {
    name: 'Ar',
    code: 'ar',
    flag: 'flag-icon-kw'
  }]
  public apiURL=environment.apiURL;
  currentLang = this.availableLangs[0];

  public mdaHubThemes;
  public layoutConf:any;
  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private renderer: Renderer2,
    public jwtAuth: JwtAuthService,
    private cookieService: CookieService,
    public notificationService: NotificationService,
  ) {}
  ngOnInit() {
    this.mdaHubThemes = this.themeService.mdaHubThemes;
    this.layoutConf = this.layout.layoutConf;
 

    
    if (this.cookieService.check("lang")) {
 
      this.availableLangs.forEach(lan => {
        if (this.cookieService.get("lang") == lan.code){
          this.currentLang = lan;
        
        }
      });
    }
 
    this.translate.use(this.currentLang.code);



    
  }
  setLang(lng) {
    this.currentLang = lng;
    this.translate.use(lng.code);
  }
  changeTheme(theme) {
    this.layout.publishLayoutChange(theme)
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if(this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  toggleCollapse() {
    // compact --> full
    if(this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full',
        sidebarCompactToggle: false
      }, {transitionClass: true})
    }

    // * --> compact
    this.layout.publishLayoutChange({
      sidebarStyle: 'compact',
      sidebarCompactToggle: true
    }, {transitionClass: true})

  }

  onSearch(e) {
    //   console.log(e)
  }
}