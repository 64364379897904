import OGCFeatureLayer from '@arcgis/core/layers/OGCFeatureLayer';
import OpenStreetMapLayer from '@arcgis/core/layers/OpenStreetMapLayer';
import WMSLayer from '@arcgis/core/layers/WMSLayer';
import WMTSLayer from '@arcgis/core/layers/WMTSLayer';
import { Layer } from "ol/layer";
import BaseLayer from "ol/layer/Base";
import LayerGroup from 'ol/layer/Group';
import {
  GisServerCategory, GISserverTypes,
  ItemTypesEnum, JoinTypeEnum, layerTypesEnum,
  ShareTypeEnum
} from '../GeoEnums';
 
 export interface Group{
   groupName:string,
  tags:string[],
  summary ? :string,
  itemsTypes: ItemTypesEnum,  //[App,Files,Maps,Layers,Scenes,Tools,All]
  sharedLevel: ShareTypeEnum, //[public,private,friends]
  joinedManar: JoinTypeEnum //[requested , invited]
 }

  export interface AppWFsSetting {
  featureNS: string;
  featureType: string;
  schemaLocation: string;
  WriteTransactionOptions: AppWriteTransactionOptions;
  fetchUrl: string;
}
export interface AppWriteTransactionOptions {
  featureNS: string;
  featureType: string;
  gmlOptions: GmlOptions;
  featurePrefix: string;
  nativeElements: string;
}
export interface GmlOptions {
  srsName: string;
} 

export class AppLayer extends BaseLayer {
  public Id: string;
  public name: string;
  public level?: number;
  public IsWidgetLayer?:boolean= false;
  public ServerType: GISserverTypes;
  public LayerType: layerTypesEnum;
  public serverCategory: GisServerCategory;
  public url: string;
  public legendurl?: string;
  public Opacity: number;
  public Style?: string;
  public layer?: Layer;
  public esrilayer?: OpenStreetMapLayer | OGCFeatureLayer |WMSLayer | WMTSLayer | any;
  public LAYERS: string[  ];
  public VisibleLAYERS: string[];
  public visible?: boolean;
  public IsGroup: boolean;
  public IsBaseMap?: boolean;
  public Description?: string;
  public Token?: string;
 

  constructor(options: AppLayerOptions) {
    super(options);
    this.Id = options.Id;
    this.ServerType = options.ServerType;
    this.serverCategory = options.serverCategory;
    this.url = options.url;
    this.Opacity = options.Opacity ? options.Opacity : 100;
    this.LAYERS = options.LAYERS;
    this.visible = options.visible;
    this.Style = options.Style;
    this.name = options.name;
    this.layer = options.layer;
    this.esrilayer = options.esrilayer;
    
    this.Description = options.Description;
    this.IsBaseMap = options.IsBaseMap;
    this.IsGroup = options.IsGroup;
    this.IsWidgetLayer=options.IsWidgetLayer;
    this.LayerType = options.LayerType;
    this.level = 0;


  }
}
export class AppLayerGroup extends LayerGroup {
  public Id: string;
  public name: string;
  public visible: boolean;
  public Description: string;
  public layers: (AppLayer | AppLayerGroup)[];

  constructor(options: AppLayerGroupOptions) {
    super(options);
    this.Id = options.Id;
    this.name = options.name;
    this.Description = options.Description;
    this.visible = options.visible;
    this.layers = options.layers;

  }

}

export interface AppLayerOptions {
    IsGroup?: boolean;
  queryColumns?: string[];
  Id: string;
  name: string;
  Style?: string;
  ServerType: GISserverTypes;
  serverCategory: GisServerCategory;
  url?: string;
  LayerType:layerTypesEnum,
  IsWidgetLayer?:boolean ;
  layer?: Layer;
  esrilayer?: any;
  esrilayertype?: any;
  Description?: string;
  visible?: boolean;
  Opacity?: number;
  LAYERS?: string[];
  VisibleLAYERS?: string[];
  IsBaseMap?: boolean;
  querySrs?: string;
  queryIdProperty?: string;
  searchField?: string[];
  LayerInputProperties?: LayerInputProperties[];
  WFsSetting?: AppWFsSetting;

}
export interface LayerInputProperties {

  Lable: string;
  Key: string;
  Value: string;
  Index?: number;
  Id?: string;
}
export interface AppLayerGroupOptions {
  Id: string;
  name: string;
  Description?: string;
  visible?: boolean;
  level?: number;
  layers: (AppLayer | AppLayerGroup)[];
}
