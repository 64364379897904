export class Utilites {
    constructor() { }

    groupBy<T, K>(list: T[], getKey: (item: T) => K) {
        const map = new Map<K, T[]>();
        list.forEach((item) => {
            const key = getKey(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        
        
        var arr= Array.from(map);
        var out=[];
        arr.forEach(element => {
    out.push({Name:element[0], Data:element[1]})
});
return out;
        
    }
}