export interface NotificationsMessage {
    Id: number;
    IsDeleted?: boolean;
    CreationDate?: Date | null;
    LastUpdate?: Date | null;
    Title?: string;
    SourceId?: string;
    Body: string;
    Type?: NotificationTypeEnum;
    NnotificationTypeName?: string;
     Lat?: number;
    Long?: number;
}
export enum NotificationTypeEnum {
    RoadClousre = 13,
    AddedIncident = 14,
    GroupInvite = 15,
    GroupRequest = 16,
    Announcement = 17,
    FireAlarm=18,
    UserJoinOnline=19,
    useroffline=20,
    messageChattext=21,
    messageChatGeo=22,
    OnlineUsers=23

}

 