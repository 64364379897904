import { Component, OnInit, AfterViewInit, ViewChild, HostListener, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { 
  Router, 
  NavigationEnd, 
  RouteConfigLoadStart, 
  RouteConfigLoadEnd, 
  ResolveStart, 
  ResolveEnd 
} from '@angular/router';
import { Subscription } from "rxjs";
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../../../services/theme.service';
import { LayoutService } from '../../../services/layout.service';
import { filter } from 'rxjs/operators';
import { JwtAuthService } from '../../../services/auth/jwt-auth.service';
import { CookieService } from 'ngx-cookie-service';
import { AppConfirmService } from 'app/shared/services/app-confirm/app-confirm.service';
import { globalConfigService } from 'environments/environment.service';

@Component({
  selector: 'app-empty-layout',
  templateUrl: './empty-layout.template.html',
  styleUrls: ["./empty-layout.template.scss"]

  // changeDetection: ChangeDetectionStrategy.OnPush
})    
export class EmptyLayoutComponent implements OnInit, AfterViewInit {
  configloaded: boolean;
 
  
  constructor(
    private router: Router,
     private layout: LayoutService,
     private glb: globalConfigService,
    private confsvc:AppConfirmService,
  ) {
  
  }

  ngOnInit() {
    this.glb.appConfigration$.subscribe(res=>{
                            if(res !=null)  
        this.configloaded=true;
          }, err=>{
      this.confsvc.showWarn("", "Unable to laod configration" +err);
            this.configloaded=false;
      console.log("Unable to laod configration" + err);
          });
  }
 
  ngAfterViewInit() {

  }
  
 
  ngOnDestroy() {
   
  }
 
 
 
  
}