import { Injectable, Inject, Renderer2, RendererFactory2, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { getQueryParam } from '../helpers/url.helper';
import { environment } from 'environments/environment';


export interface ITheme {
  name: string,
  baseColor?: string,
  isActive?: boolean,
  sidebarColor?: string,
  topbarColor?: string,
  footerColor?: string,
  textcolor?: string,
  matTheme: string

}

@Injectable()
export class ThemeService {
  public onThemeChange: EventEmitter<ITheme> = new EventEmitter();

  public mdaHubThemes: ITheme[] = [{
    "matTheme": "MdaHubMain",
    "name": "MdaHubMain",
    "baseColor": "#20d3d3",
    "isActive": false,
    'sidebarColor': "MdaHubMain",
    'topbarColor': "mdahubsecondery",
    'footerColor': "darkgrey",
    "textcolor": "#fff"

  },
  {
    "matTheme": "PetrolTheme",
    "name": "PetrolTheme",
    "baseColor": "#204d62",
    "isActive": false,
    'sidebarColor': "PetrolTheme",
    'topbarColor': "PetrolTheme",
    'footerColor': "PetrolTheme",
    "textcolor": "#fff"

  },
  {
    "matTheme": "dark-geryTheme",
    "name": "dark-geryTheme",
    "baseColor": "#1a1919ca",
    "isActive": false,
    'sidebarColor': "black",
    'topbarColor': "black",
    'footerColor': "black",
    "textcolor": "#fff"
  },
  {
    "matTheme": "dark-purpleTheme",
    "name": "dark-purpleTheme",
    "baseColor": "purple",
    "isActive": false,
    'sidebarColor': "purple",
    'topbarColor': "purple",
    'footerColor': "purple",
    "textcolor": "#fff"
  }, {
    "matTheme": "dark-pinkTheme",
    "name": "dark-pinkTheme",
    "baseColor": "#e91e63",
    "isActive": false,
    'sidebarColor': "pink",
    'topbarColor': "pink",
    'footerColor': "pink",
    "textcolor": "#fff"
  }, {
    "matTheme": "BlueTheme",
    "name": "BlueTheme",
    "baseColor": "#03a9f4",
    "isActive": false,
    'sidebarColor': "03a9f4",
    'topbarColor': "03a9f4",
    'footerColor': "03a9f4",
    "textcolor": "#fff"
  }, {
    "matTheme": "light-purpleTheme",
    "name": "light-purpleTheme",
    "baseColor": "#1a2038",
    "isActive": false,
    'sidebarColor': "light-purple",
    'topbarColor': "light-purple",
    'footerColor': "light-purple",
    "textcolor": "#fff"
  }, {
    "matTheme": "BlueNavyTheme",
    "name": "BlueNavyTheme",
    "baseColor": "navy",
    "isActive": false,
    'sidebarColor': "navy",
    'topbarColor': "navy",
    'footerColor': "navy",
    "textcolor": "#fff"
  }



  ];



  public activatedTheme: ITheme;
  private renderer: Renderer2;
  constructor(
    @Inject(DOCUMENT) private document: Document,

    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  // Invoked in AppComponent and apply 'activatedTheme' on startup
  applyMatTheme(themeName: string) {





    this.mdaHubThemes.forEach(element => {
      if (element.name === themeName)
        this.activatedTheme = element;
    });
    this.flipActiveFlag(themeName);


    // *********** ONLY FOR DEMO **********
    this.setThemeFromQuery();
    // ************************************

    // this.changeTheme(themeName);
    this.renderer.addClass(this.document.body, themeName);

  }

  changeTheme(prevTheme, themeName: string) {

    this.renderer.removeClass(this.document.body, prevTheme);
    this.renderer.addClass(this.document.body, themeName);
    this.flipActiveFlag(themeName);
    this.onThemeChange.emit(this.activatedTheme);
  }

  flipActiveFlag(themeName: string) {
    this.mdaHubThemes.forEach((t) => {
      t.isActive = false;
      if (t.name === themeName) {
        t.isActive = true;
        this.activatedTheme = t;
      }
    });
  }

  // *********** ONLY FOR DEMO **********
  setThemeFromQuery() {
    let themeStr = getQueryParam('theme');
    try {
      this.activatedTheme = JSON.parse(themeStr);
      console.log(this.activatedTheme);

      this.flipActiveFlag(this.activatedTheme.name);
    } catch (e) { }
  }
}
