import { Injectable } from "@angular/core";
import { LocalStoreService } from "../local-store.service";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { map, catchError, delay } from "rxjs/operators";
import { IUser } from "../../models/user.model";
import { of, BehaviorSubject, throwError } from "rxjs";
import { WebApiResult } from "app/shared/models/apiresult";
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from "environments/environment";
import { IUserGeoLocation } from "app/shared/models/UserGeoLocation.interface";
import { LoggingService } from "../logging.service";




// ================= you will get those data from server =======
@Injectable({
  providedIn: "root",
})
export class JwtAuthService {
  token;
  public isAuthenticated: Boolean;
  user: IUser = null;
  user$ = (new BehaviorSubject<IUser>(this.user));
  signingIn: Boolean;
  JWT_TOKEN = "JWT_TOKEN";
  MdaHub_APP_USER = "MdaHub_APP_USER";
  USER_GEO_INFO = "USER_GEO_INFO";
  constructor(
    public ls: LocalStoreService,
    private http: HttpClient,
    private router: Router,
    private snack: MatSnackBar,
    private loggingService: LoggingService
  ) {
  }




  public getusergeoip() {
    this.signingIn = true;

    if (!environment.DomainAuth)
      return this.http.get("https://api.ipgeolocation.io/ipgeo?apiKey=cf030da821e044f3ad4a97f623e29614")
        .pipe(
          map((res: IUserGeoLocation) => {
            if (res.latitude)
              this.setUserCountry(res);
            return res;
          }),
          catchError((error) => {
            this.loggingService.logerror(error);
            return throwError(error);
          })
        );
    else {

      var userGeoLocation: IUserGeoLocation = {
        ip: null,
        countryCode2: null,
        country_code2: null,
        district: null,
        city: null,
        zipcode: null,
        latitude: null,
        longitude: null,
        isp: null,
        portalUserId: this.getUser() ? this.getUser().Id : null,
        id: null,
        Logintimestamp: new Date()

      }
      return of(userGeoLocation).pipe(

        map((data) => {
          return userGeoLocation;
        }));
    }
  }


  public signin(username, password) {
    this.signingIn = true;



    return this.http.post(`${environment.apiURL}/api/login/login`, { username: username, password: password ,Platform:environment.Platform})
      .pipe(
        map((res: WebApiResult) => {
          if (res.IsSuccess) {
            environment.loggedin = true;


            this.setUserAndToken(res.data.Token, res.data, res.IsSuccess);
            this.signingIn = !res.IsSuccess;
            this.getUserGeoinfo();
            return res;


          }

          return res;
        }),
        catchError((error) => {
          this.snack.open("Unable To Sign In", null, { duration: 2000 });
          this.loggingService.logerror(error);
          return throwError(error);
        })
      );
  }

  public domainlogin() {
    this.signingIn = true;


    return this.http.get(`${environment.apiURL}/api/login/domainlogin`,)
      .pipe(
        map((res: WebApiResult) => {
          if (res.IsSuccess) {
            environment.loggedin = true;
            {
              this.setUserAndToken(res.data.Token, res.data, res.IsSuccess);
              this.signingIn = !res.IsSuccess;
            }
          }
          res.data = "Unable to connect"
          return res;
        }),
        catchError((error) => {
          this.snack.open("Unable To Sign In", null, { duration: 2000 });
          this.loggingService.logerror(error);
          return throwError(error);
        })
      );
  }


  public sendforgetemail(Email: string) {
    return this.http.post(`${environment.apiURL}/api/login/forgetpassword`, { Email: Email })
      .pipe(
        map((res: WebApiResult) => {
          return res;
        }),
        catchError((error) => {
          this.loggingService.logerror(error);
          return throwError(error);
        })
      );
  }
  /*
    checkTokenIsValid is called inside constructor of
    shared/components/layouts/admin-layout/admin-layout.component.ts
  */
  public checkTokenIsValid() {
    let id = this.ls.getItem(this.MdaHub_APP_USER).id;
    let token = this.ls.getItem(this.JWT_TOKEN);


    var url = "/api/user/signinbytoken";
    if (environment.DomainAuth)
      url = "/api/login/domainlogin";
    return this.http.get(environment.apiURL + url)
      .pipe(
        map((res: WebApiResult) => {
          if (res.IsSuccess) {
            environment.loggedin = true;
            this.setUserAndToken(this.getJwtToken(), res.data, true);
            return res.data;
          }
          else {

            if (this.router.url.toLowerCase().indexOf("home") == -1 && this.router.url.toLowerCase().indexOf("sign") == -1)
              this.router.navigateByUrl("home");
            if (!environment.DomainAuth)
              this.signout();
            else
              this.domainlogin();
            this.snack.open("Token Expired...", 'OK', { duration: 6000 });
            return;
          }
        }),
        catchError((error) => {
          this.snack.open("Token Expired....", 'OK', { duration: 6000 });
          if (!environment.DomainAuth) {
            this.signout();
          }

          else
            this.domainlogin();

 

          return of(error);
        })
      );
  }
  public AutoSigninByToken() {
    let token = this.ls.getItem(this.JWT_TOKEN);
    if (!token) {
      return of({ IsSuccess: false })


    }
    var url = "/api/user/signinbytoken";
    if (environment.DomainAuth)
      url = "/api/login/domainlogin";
    return this.http.get(environment.apiURL + url)
      .pipe(
        map((res: WebApiResult) => {
          if (res.IsSuccess) {

            this.setUserAndToken(this.getJwtToken(), res.data, true);
            return res;
          }
          else {
            if (this.router.url.toLowerCase().indexOf("home") == -1 && this.router.url.toLowerCase().indexOf("sign") == -1)
              this.router.navigateByUrl("home");

            this.signout();
            this.snack.open("Token Expired.", null, { duration: 1000 });
            return res;

          }
        }),
        catchError((error) => {


          if (this.router.url.toLowerCase().indexOf("home") == -1 && this.router.url.toLowerCase().indexOf("sign") == -1)
            this.router.navigateByUrl("home");

          this.snack.open("Token Expired..", null, { duration: 3000 });

          this.signout();
          return of(error);
        })
      );
  }
  public signout() {
    this.setUserAndToken(null, null, false);


    if (this.router.url.toLowerCase().indexOf("home") == -1 && this.router.url.toLowerCase().indexOf("sign") == -1)
      this.router.navigateByUrl("home");

  }
  isLoggedIn(): Boolean {
    return !!this.getJwtToken();
  }
  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }
  getUser() {
    return this.ls.getItem(this.MdaHub_APP_USER) as IUser;
  }
  setUserAndToken(token: String, user: IUser, isAuthenticated: Boolean) {

    var self = this;


    var MenuItems = [];
    if (user) {
      user.MenuItems.forEach(menu => {
        if (!menu.role || menu.role <= user.RoleId)
          MenuItems.push(menu);
      });
      user.MenuItems = MenuItems;
    }

    self.isAuthenticated = isAuthenticated;
    self.token = token;

    self.user = user;
    self.user$.next(user);
    self.ls.setItem(self.JWT_TOKEN, token);
    self.ls.setItem(self.MdaHub_APP_USER, user);


    environment.loggedin = true;


  }
  getDefaultMapCenter() {

    var USER_GEO_INFO = this.ls.getItem(this.USER_GEO_INFO);
    if (USER_GEO_INFO)
      return [USER_GEO_INFO.longitude, USER_GEO_INFO.latitude];
    else
      return null;

  }

  getUserGeoinfo() {
    var da = this.ls.getItem(this.USER_GEO_INFO);
    if (da && !da.Logintimestamp)
      da.Logintimestamp = new Date();
    if (da) {
      var ddddd = Math.abs(new Date().getTime() - new Date(da.Logintimestamp).getTime());
      if (ddddd > (60 * 60 * 12 * 1000)) {
        this.getusergeoip().subscribe(res => {
          return res;

        });

      }
      else {
        this.Updateusergeoloaction();
        return da;
      }
    } else {
      this.getusergeoip().subscribe(res => {
        return res;

      });

    }
  }
  setUserCountry(userGeoinfo) {
    userGeoinfo.Logintimestamp = new Date();
    this.ls.setItem(this.USER_GEO_INFO, userGeoinfo);

    this.Updateusergeoloaction();

  }

  Updateusergeoloaction() {

    var Usergeodatalocation: IUserGeoLocation = this.ls.getItem(this.USER_GEO_INFO);
    if (!Usergeodatalocation.countryCode2)
      Usergeodatalocation.countryCode2 = Usergeodatalocation.country_code2;

    if (environment.isFirstLogintime)
      this.http.post(environment.apiURL + "/api/login/geolud", Usergeodatalocation).subscribe(res => {
        environment.isFirstLogintime = false;
      }, error => {
        console.log(error);


      });

  }


}
