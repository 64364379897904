import { InMemoryDbService } from 'angular-in-memory-web-api';
import { CountryDB } from './countries';
 

export class InMemoryDataService implements InMemoryDbService {
  createDb() {
    return { 
      'countries':  (new CountryDB()).countries,
 
 
    }
  }
}