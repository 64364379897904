 import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
  import { AppConfirmService } from 'app/shared/services/app-confirm/app-confirm.service';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';

 
import { IncidentService } from 'app/BusinessWidgets/Incidents-Managment/Incidents.service';
import { IncidentAttributes } from 'app/BusinessWidgets/Incidents-Managment/Incident.Interface';
import { NotificationService } from '../../../shared/components/notifications/notification.service';
import { VMapService } from 'app/GeoHub/GIS_Component/map-componants/v-map-component/v-map.service';
  

@Component({
  selector: 'app-incident-notification',
  templateUrl: './incident-notification.component.html',
  styleUrls: ['./incident-notification.component.scss']
})
export class IncidentNotificationComponent implements OnInit , OnDestroy{
  public incidenticon: string = "";
  public Incdents: any[] = [];
  incidentAttributes: IncidentAttributes;
  incidentEnabled: boolean;
  constructor(private confirmmsg: AppConfirmService,

    private vMapService: VMapService,

    public incidentService: IncidentService,

    private notificationService: NotificationService,

  ) { }
  ngOnDestroy(): void {
    if (this.incidentService.incidentcount > 0)
    this.notificationService.notifCount -= this.incidentService.incidentcount;  }

  ngOnInit(): void {

    this.incidentService.load().subscribe(res => {
      this.incidentAttributes = res;
      this.incidenticon = this.incidentAttributes.incidentIcon;
      this.incidentService.getIncidents().then((res: any) => {
        this.Incdents = res.features;

        this.incidentService.incidentcount = this.Incdents.length;

        this.notificationService.notifCount += this.Incdents.length;
      });
    });

  }


  removeNotificationsGroup(group) {
    this.Incdents = [];
  }

  refresh() {
    var self = this;
    setTimeout(() => {
      self.incidentService.getIncidents().then((res: any) => {
        self.Incdents = res.features;


        if (this.incidentService.incidentcount > 0)
        this.notificationService.notifCount -= this.incidentService.incidentcount;  

        this.incidentService.incidentcount = this.Incdents.length;

        this.notificationService.notifCount += this.Incdents.length;



      });
    }, this.incidentAttributes.Setting.updateinterval);

  }
  zoomToAlert(item: any) {

    if (!this.vMapService.map)
      this.confirmmsg.showWarn(
        "Notification", "Open the map to zoom to location").subscribe(p => {
          this.refreshHeader();
        });
    else {
      const coords = item.geometry.coordinates;
      var feature = new Feature();
      feature.setGeometry(new Point(coords));
      this.vMapService.map.getView().fit(feature.getGeometry().getExtent());
      this.vMapService.map.getView().setZoom(12);
    }


  }
  refreshHeader() {

    document.getElementById("appheadertopDiv").style.position = "absolute";
    setTimeout(function () {
      try {
        document.getElementById("appheadertopDiv").style.position = "relative";
      } catch (err) { }
    }, 5);
  }
}
