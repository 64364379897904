import { GisAppSharedService } from '../../../GeoHub/GIS-Shared-Lib/services/gis-app-shared.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
 import { AppConfirmService } from 'app/shared/services/app-confirm/app-confirm.service';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { RoadClosureAttributes } from 'app/BusinessWidgets/RoadClosure/RoadClosure.interface';
import { RoadClosureService } from 'app/BusinessWidgets/RoadClosure/RoadCluser.service';
import { NotificationService } from '../../../shared/components/notifications/notification.service';
import { VMapService } from 'app/GeoHub/GIS_Component/map-componants/v-map-component/v-map.service';
 @Component({
  selector: 'app-road-closure-notification',
  templateUrl: './road-closure-notification.component.html',
  styleUrls: ['./road-closure-notification.component.scss']
})
export class RoadClosureNotificationComponent implements OnInit, OnDestroy {
  public Icon: string = "";
  public roadClousures: any[] = [];
  roadClosureAttributes: RoadClosureAttributes;
  constructor(private confirmmsg: AppConfirmService
    , private vMapService: VMapService,
    private service: RoadClosureService,
    private notificationService: NotificationService
  ) { }
  ngOnDestroy(): void {
    if (this.service.Closurecount > 0)
      this.notificationService.notifCount -= this.service.Closurecount;
  }
  ngOnInit(): void {
    this.service.loadRoadClousureConfig().subscribe(res => {
      this.roadClosureAttributes = res;
      this.Icon = this.roadClosureAttributes.Icon;
      this.service.getRoadclousure().then((res: any) => {
        if(res && res.features){
        this.roadClousures = res.features;
        this.service.Closurecount = this.roadClousures.length;
        this.notificationService.notifCount += this.roadClousures.length;}
        else{

          this.confirmmsg.showWarn("","Unable to load Road Closure data   " );
          console.log(res);

        }
      });
    });
  }
  removeNotificationsGroup() {
    this.roadClousures = [];
  }
  refresh() {
    var self = this;
    setTimeout(() => {
      self.service.getRoadclousure().then((res: any) => {
        self.roadClousures = res.features;
        if (this.service.Closurecount > 0)
          this.notificationService.notifCount -= this.service.Closurecount;
        this.service.Closurecount = this.roadClousures.length;
        this.notificationService.notifCount += this.roadClousures.length;
        self.service.Closurecount = self.roadClousures.length;
      });
    }, this.roadClosureAttributes.Setting.updateinterval);
  }
  zoomToAlert(item: any) {
    if (!this.vMapService.map)
      this.confirmmsg.showWarn(
        "Notification", "Open the map to zoom to location").subscribe(() => {
          this.refreshHeader();
        });
    else {
      const coords = item.geometry.coordinates;
      var feature = new Feature();
      feature.setGeometry(new Point(coords));
      this.vMapService.map.getView().fit(feature.getGeometry().getExtent());
      this.vMapService.map.getView().setZoom(12);
    }
  }
  refreshHeader() {
    document.getElementById("appheadertopDiv").style.position = "absolute";
    setTimeout(function () {
      try {
        document.getElementById("appheadertopDiv").style.position = "relative";
      } catch (err) { }
    }, 5);
  }
}
