import { MatGridListModule } from '@angular/material/grid-list';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseMapsComponent } from './base-maps.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SharedPipesModule } from 'app/shared/pipes/shared-pipes.module';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [BaseMapsComponent],
  imports: [
    TranslateModule,
    SharedPipesModule,
    CommonModule,
    MatGridListModule,
    PerfectScrollbarModule
  ],
  exports: [BaseMapsComponent],
})
export class BaseMapsModule {

}