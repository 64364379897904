//import { number } from 'ngx-custom-validators/src/app/number/validator';
import { OSM, TileWMS, XYZ, ImageArcGISRest, BingMaps, ImageWMS, TileArcGISRest } from 'ol/source';
import { Geometry } from "ol/geom";
import Feature from "ol/Feature";
import { map } from "rxjs/operators";
import { environment } from "environments/environment";
import Map from "ol/Map";
import { AppLayer } from "app/GeoHub/GIS-Shared-Lib/interface/Layers-Maps.interface";
import { Injectable } from "@angular/core";
import { GeoJSON, EsriJSON } from "ol/format";
import { View } from "ol";
import TileLayer from 'ol/layer/Tile';

import {
  GISserverTypes,
  GisServerCategory,
} from "app/GeoHub/GIS-Shared-Lib/GeoEnums";
import { Coordinate } from "ol/coordinate";
import { Subject } from "rxjs";
import { PopupinfoResult } from "./feature-result.interface";
 
import { Select } from "ol/interaction";
import { singleClick } from "ol/events/condition";
import { IPopupActionButton } from "./featureinfo.interface";
import { lstat } from "fs";
import { MapInteractions } from '../map-componants/v-map-component/event.model';

@Injectable({
  providedIn: "root",
})
export class FeatureinfoService {
  geojsonFormat = new GeoJSON();
  public mapInteractions: MapInteractions = new MapInteractions({ source: "" });
  public mapInteractionsObserver = new Subject<MapInteractions>();
  public mapInteractionsObserver$ = this.mapInteractionsObserver.asObservable();
  public featureInfoResults = new Subject<PopupinfoResult>();
  public featureInfoResults$ = this.featureInfoResults.asObservable();
  public BtnsactionList: any[] = [];
  private Selectlayers = [];
  /**
   *1000 Meter Tolerance
   *
   * @type {number}
   * @memberof FeatureinfoService
   */
  tolerance: number = 5;
  public mapSelectInteractions: Select;
  constructor() {
    this.mapInteractionsObserver$.subscribe(
      (action) => {
        this.mapInteractions = action;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  addActionButton(_title: string, _img: string, _action: any, _sender, _fieldcheck: any) {
    var btn: IPopupActionButton = { title: _title, img: _img, action: _action, sender: _sender, fieldCheck: _fieldcheck, };
    this.BtnsactionList.push(btn);

  }
  RemoveActionButton(_title: string, fieldCheck) {
    for (var i = 0; i < this.BtnsactionList.length; i++) {
      if (this.BtnsactionList[i].fieldCheck == fieldCheck && _title == this.BtnsactionList[i].title)
        this.BtnsactionList.splice(i, 1);
    }
  }
  ClickableLayers = [];
  Add_MapLayerActionSingleClick(map: Map, appLayer: AppLayer) {
    if (appLayer.IsBaseMap  )
      return;


    this.Selectlayers.push(appLayer.layer);
    this.ClickableLayers.push(appLayer.Id);
    let self = this;
    if (self.mapSelectInteractions)
      map.removeInteraction(self.mapSelectInteractions);

    self.mapSelectInteractions = new Select({
      condition: singleClick,
      multi: true,

      hitTolerance: 5,
      layers: this.Selectlayers,
    });


    var featureSelected = function (evt) {
      var features = [];
      evt.selected.forEach((f: Feature) => {
        features.push({
          id: f.getId() ? f.getId().toString().split("_")[0] : "",
          geometry: f.getGeometry(),
          properties: f.getProperties(),
          type: "Feature",
        });
      });

      var result: PopupinfoResult = {
        coordinate: evt.mapBrowserEvent.coordinate,
        features: features,
        appLayers: [appLayer],
        eventName: "features",
        map: map,
      };
      self.featureInfoResults.next(result);
    };

    self.mapInteractions.GraphiclayerSingleClickEnabled = true;
    self.mapSelectInteractions.on("select", function (evt) {
      if (self.mapInteractions.GraphiclayerSingleClickEnabled && evt.selected.length > 0 && !self.mapInteractions.
        DrawEnabled)
        featureSelected(evt);
      else {
        self.featureInfoResults.next({
          coordinate: null,
          features: null,
          appLayers: null,
          eventName: "close",
          map: map,
        });

      }
    });
    map.addInteraction(self.mapSelectInteractions);
  }

  showPopUpInfoWithContent(
    coordinate: Coordinate,
    title: string,
    content: string,
    map: Map
  ) {
    var result: PopupinfoResult = {
      coordinate: coordinate,
      title: title,
      content: content,
      eventName: "content",
      map: map,
    };
    this.featureInfoResults.next(result);
  }

  ShowLoading(coordinate: Coordinate, map: Map) {
    this.featureInfoResults.next({
      appLayers: [],
      features: [],
      coordinate: coordinate,
      map: map,
      eventName: "loading",
    });
  }

  ShowPopupInfo(result: PopupinfoResult) {
    this.featureInfoResults.next(result);
  }
  whatIsHere(coordinate: Coordinate, appLayers: AppLayer[], map: Map) {
    this.featureInfoResults.next({
      appLayers: [],
      features: [],
      coordinate: coordinate,
      map: map,
      eventName: "loading",
    });

    let _layerIMAGE: AppLayer[] = [];
    if (appLayers) {
      _layerIMAGE = appLayers.filter(
        (e) =>
          e.serverCategory == GisServerCategory.WMS ||
          e.serverCategory == GisServerCategory.ArcGIS
      );
    }

    if (coordinate == null) {
      return;
    }

    let layerIMAGE = [];

    _layerIMAGE.forEach((layer) => {

      if (layer.serverCategory == GisServerCategory.WMS)
        layerIMAGE.push(layer);
    });

    let promise = new Promise((resolve, reject) => {
      let allFeatureResult = [];
      if (layerIMAGE.length > 0) {
        let count: number = 0;
        layerIMAGE.forEach((e) => {
          var url = "";
          if (e.serverCategory == GisServerCategory.WMS) {
            let wmsSource = e.layer.getSource();


            if (e.layer.getSource().getFeatures && e.layer.getSource().getFeatures().length >= 0) {

              var data = e.layer.getSource().getClosestFeatureToCoordinate(coordinate)

              if (data && data.properties) {
                allFeatureResult.push(data);

                this.featureInfoResults.next({
                  features: allFeatureResult,
                  coordinate: coordinate,
                  map: map,
                  appLayers: appLayers,
                  eventName: "features",
                });
              }
              else   if (data && data.getProperties) {
            
                allFeatureResult.push( data);

                this.featureInfoResults.next({
                  features:  allFeatureResult,
                  coordinate: coordinate,
                  map: map,
                  appLayers: appLayers,
                  eventName: "features",
                });
              }
              else
              {
                this.featureInfoResults.next({
                  features: [allFeatureResult],
                  coordinate: coordinate,
                  map: map,
                  appLayers: appLayers,
                  eventName: "features",
                });
                }
              url = null;

            }
            else {

              url = wmsSource.getFeatureInfoUrl(
                coordinate,
                map.getView().getResolution(),
                environment.mapDefaultProjection,
                {
                  INFO_FORMAT: "application/json",
                  SRSNAME: environment.mapDefaultProjection,
                  CRS: environment.mapDefaultProjection,
                  SRS: environment.mapDefaultProjection,
                }
              );
            }

          } else if (e.serverCategory == GisServerCategory.ArcGIS) {
            ///// return GeoJSON

            url =
              e.url +
              "/query?where=1%3D1&objectIds=&time=&geometry=" +
              coordinate[0] +
              "," +
              coordinate[1] +
              "&geometryType=esriGeometryPoint&inSR=" +
              environment.mapDefaultProjection.split(":")[1] +
              "&spatialRel=esriSpatialRelIntersects&resultType=none&distance=" +
              this.tolerance +
              "&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnGeometry=true&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=" +
              environment.mapDefaultProjection.split(":")[1] +
              "&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pgeojson";
          }
          if (url) {
            fetch(url)
              .then((response) => {
                return response.json();
              })
              .catch((error) => {
                console.log(error);
              })
              .then((Featuresresponse) => {
                count++;

                var res= new GeoJSON().readFeatures(Featuresresponse);
                res.forEach((f:any) => {
                  

                    allFeatureResult.push(f);
                   f.layerName = e.name;
                    this.featureInfoResults.next({
                      features: allFeatureResult,
                      coordinate: coordinate,
                      map: map,
                      appLayers: appLayers,
                      eventName: "features",
                    });
               
                });
                if (count === layerIMAGE.length) {

                  this.featureInfoResults.next({
                    features: allFeatureResult,
                    coordinate: coordinate,
                    map: map,
                    appLayers: appLayers,
                    eventName: "features",
                  });
               
                }
                resolve(allFeatureResult);
              });
          }
        });
      } else {

        this.featureInfoResults.next({
          features: [],
          coordinate: coordinate,
          map: map,
          appLayers: appLayers,
          eventName: "features",
        });
        resolve(allFeatureResult);
      }
    }).catch((error) => {
      console.log(error);
      throw error;
    });
    return promise;
  }
}
