 
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
  import { SimpleMapComponent } from './simple-map.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseMapsModule } from '../../base-maps/base-maps.module';
import { MeasureWidgetModule } from '../../measure/measure.module';
import { DrawWidgetModule } from '../../Draw/Draw.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedPipesModule } from 'app/shared/pipes/shared-pipes.module';



@NgModule({
  declarations: [ SimpleMapComponent ]
  ,exports: [SimpleMapComponent],
  imports: [
    SharedPipesModule,
     MatIconModule,
MatToolbarModule,
MatProgressBarModule,
    CommonModule,
    MatTooltipModule,
    MatMenuModule,
   MatButtonModule,
   BaseMapsModule,
   MeasureWidgetModule,
   DrawWidgetModule,
   DragDropModule,
  ]
})
export class SimpleMapModule { }
